import React from "react";
import { Link } from "react-router-dom";
import "./OptFormStyles.css";

function optFormButton({ children, ...restProps }) {
  return (
    <div>
      <Link className="optform-button bg-blue-500" to="/signup" {...restProps}>
        {children}
        <img
          className="optform-button-image"
          src="../images/icons/chevron-right.png"
          alt="Try Now"
        />
      </Link>
    </div>
  );
}

export default optFormButton;
