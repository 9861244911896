import React, { useState } from "react";
import LoadingIcon from "../components/Common/LoadingIcon";
import SignFormButton from "../components/SignForm/SignFormButton";
import { checkEmail, checkEmailorPhone, mayaAlert } from "../constants";
import MayaDataService from "../services/MayaDataService";

function ContactCompound() {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const IsInvalid = fullName === "" || !checkEmail(email) || message === "";

  const sendMessage = () => {
    setLoading(true);
    MayaDataService.sendContactMessage(fullName, email, message).then(
      (result) => {
        if (result.responseStatus === 1) {
          mayaAlert.fire({
            title: "Thank You!",
            icon: "success",
            html: "Your message has been sent successfully",
          });
          setEmail("");
          setMessage("");
          setFullName("");
        }

        setLoading(false);
      }
    );
  };

  return (
    <div className="mx-auto mt-6 text-white   flex-1 px-1 md:px-10">
      <div className="mb-4">
        <h1 className="text-2xl md:text-4xl font-bold leading-tight my-4t">
          Contact Us
        </h1>
        <p className="mt-1 text-sm md:text-xl text-gray-400 text-semibold">
          We'd love to hear from you.
        </p>
      </div>

      <div>
        <div>
          <span className="text-sm font-bold text-gray-400 uppercase">
            Full Name
          </span>
          <input
            className="w-full p-3 mt-2 text-gray-900 bg-gray-300 rounded-lg focus:outline-none focus:shadow-outline focus:bg-white"
            type="text"
            placeholder=""
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
          />
        </div>
        <div className="mt-8">
          <span className="text-sm font-bold text-gray-400 uppercase">
            Email
          </span>
          <input
            className="w-full p-3 mt-2 text-gray-900 bg-gray-300 rounded-lg focus:outline-none focus:shadow-outline focus:bg-white"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="mt-8">
          <span className="text-sm font-bold text-gray-400 uppercase">
            Message
          </span>
          <textarea
            className="w-full h-32 p-3 mt-2 text-gray-900 bg-gray-300 rounded-lg focus:outline-none focus:shadow-outline focus:bg-white"
            onChange={(e) => setMessage(e.target.value)}
            value={message}
          >
            {message}
          </textarea>
        </div>
        <div className="mt-8">
          <SignFormButton disabled={IsInvalid} onClick={() => sendMessage()}>
            {loading && (
              <span className="inline-block align-middle">
                <LoadingIcon />
              </span>
            )}
            {!loading && (
              <span className="inline-block align-middle"> Send Message </span>
            )}
          </SignFormButton>
        </div>
      </div>
    </div>
  );
}

export default ContactCompound;
