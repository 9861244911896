import { config } from '../../constants';
import React, { useState } from "react";
import useScript from "../../hooks/useScript";
import { YENEPAY_ICON_URL } from '../../constants';
import LoadingIcon from '../Common/LoadingIcon';
import MayaDataService from '../../services/MayaDataService';
 
function YenePayForm({ selectedPackage, lookups, quantity, callback, path }) {
  const [loading, setLoading] = useState(false);
  // const status = useScript(
  //   'https://yenepay.com/bundles/sdk/js/v1/sdk.min.js'
  // );
  
    // const setting = {
    //   merchantId: '13778',
    //   forceWhenDuplicate: true,
    //   useSandbox: false
    // };

    // const paymentRequest = {
    //   "process": "Express",
    //   "ipnUrl": `${config.baseUrl}/Pwa/Ipn`, //(optional) Your IPN callback url
    //   "successUrl": `${config.baseUrl}/Pwa/SuccessCallback`, //(optional) Your Payment Success callback url
    //   "merchantOrderId": selectedPackage.id, 
    //   "itemId": selectedPackage.id, //(optional) Item id
    //   "itemName": lookups.subscriptionPackages.find((x) => x.id === selectedPackage.package)?.description, //Item Name
    //   "unitPrice": selectedPackage.price, //Item unit price
    //   "quantity": 1, //Item quantity
    //   "currency": "ETB"
    // };


    const pay = () => {
      if(!loading)
      {
        setLoading(true);

        MayaDataService.payWithYenePay({
          amount: selectedPackage.price,
          priceSettingId: selectedPackage.id,
        },path)
          .then((result) => {
            setLoading(false);
            window.location = result;
          })
          .catch((e) => { setLoading(false);});


        // setLoading(true);
        // new window.YenePay(setting).process(paymentRequest).then(function(data) {
        //   setLoading(false);
        //   callback();
        // }).catch(function(err) {
        //   setLoading(false);
        //   });
      }
    }



  return (
    <div className="mx-2 md:mx-20 pb-20">
        <h2 className="px-12 text-base font-bold text-center md:text-2xl text-white">
        {lookups.subscriptionPackages.find((x) => x.id === selectedPackage.package)?.description} Plan
      </h2>
      <img src={YENEPAY_ICON_URL} className="w-32 mx-auto ml-1 inline-block my-4" />
      <button
          onClick={() => pay()}
          className="text-white w-full mt-4  br-25 text-center bg-blue-500 py-2 px-4  items-center focus:outline-none"
        >
            {!loading && <span>Pay {selectedPackage.price * quantity} ETB</span>}
            {loading && <span className="inline-block align-middle"> <LoadingIcon /> </span> }
        </button>
     
    </div>
  );
}

export default YenePayForm;
