import React from "react";

function CardNoTitle({ children, ...restProps }) {
  return (
    <div className="my-6">
      <div className="shadow rounded-md p-4  w-full mx-auto">
        <div className="animate-pulse flex space-x-4">

          <div className="flex-1 space-y-4 py-1">
            <div className="h-10 bg-gray-300 rounded w-40" />

          </div>
        </div>
      </div>
    </div>
  );
}

export default CardNoTitle;
