/* eslint-disable react/button-has-type */
import React, { useContext, useEffect, useState } from "react";
import {
  FaChevronLeft, FaSync, FaTrashAlt, FaExclamationTriangle, FaCheckCircle, FaClipboard, FaQuestion, FaCcPaypal, FaStripe
} from "react-icons/fa";
import { useHistory, useParams } from 'react-router-dom';
import CurrencyFormat from 'react-currency-format';
import moment from 'moment';
import FooterCompound from "../compounds/FooterCompound";
import Logo from "../components/Header/Logo";
import NavBar from "../components/Header/NavBar";
import GiftCardForm from "../components/ProfileForm/GiftCardForm";
import ProfileCompound from "../compounds/ProfileCompound";
import { StoreContext } from '../context/Store';
import AllSlidesWrapper from "../components/Movies/AllSlidesWrapper";
import {CopyToClipboard} from 'react-copy-to-clipboard'
import LoadingIcon from "../components/Common/LoadingIcon";
import NoContent from "../components/Common/NoContent";
import TableCompounded from "../compounds/TableCompounded";
import ProfileFormButton from "../components/ProfileForm/ProfileFormButton";
import MayaDataService from "../services/MayaDataService";
import { mayaAlert } from "../constants";
import { SET_PLAYER } from "../context/ActionTypes";

function GiftCardPage() {
  const history = useHistory();
  const [state, dispatch] = useContext(StoreContext);
  const [selectedCode, setSelectedCode] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [giftCards, setGiftCards] = useState([]);
  const [loading, setLoading] = useState(false);
  const { session } = useParams();
  
  function useQueryParams() {
    const params = new URLSearchParams(
      window ? window.location.search : {}
    );

    return new Proxy(params, {
        get(target, prop) {
            return target.get(prop)
        },
    });
}

  const {type, payment, platform} = useQueryParams();

  const buyGiftCode= () =>{
    mayaAlert.fire({
      showConfirmButton: false,
      width: 1000,
      background: 'rgba(17, 24, 39, 1)',
      html: <GiftCardForm dispatch={dispatch} callBack={() => get()} validDate={ state.account?.subscriptions && state.account?.subscriptions.length > 0  ? 
        moment(state.account?.subscriptions[0].dateTo).format('MMM DD YYYY') : null} lookups={state.lookups} profileId={state.selectedProfile.id} account={state.account} history={history} />
   
       
    })
  
  }

  const get = () => {
    setLoading(true);
    MayaDataService.giftCodes({ currentPage }).then((result) => {
      setGiftCards(result.data);
      setLoading(false);
      });
  };

  const onPayment = (payment) => {
    if (payment.toLowerCase() === "paypal")  return <FaCcPaypal className="text-white text-2xl  md:text-5xl ml-6" />
    else if (payment.toLowerCase() === "stripe")  return <FaStripe className="text-white text-2xl  md:text-5xl ml-6" />
    else return <span>{payment}</span>
  }
  const status = (status) => {
    if (status) {
      return (
        <span>
          <FaCheckCircle className="text-green-500 inline-block cursor-pointer mr-1 -mt-1 " />
          Redeemed
        </span>
      );
    }
    return (
      <span>
        <FaQuestion className="text-gray-500 inline-block cursor-pointer mr-1 -mt-1 " />
        Not Redeemed
      </span>
    );
  };
  const code = (code) => (
    <span>
      <CopyToClipboard onCopy={() => setSelectedCode(code)} text={code}>
        <FaClipboard className="text-gray-500 inline-block mr-1 -mt-1 hover:text-white cursor-pointer" onClick={() => setSelectedCode(code)} />
      </CopyToClipboard>
      {code}
    </span>
  );
  useEffect(() => {
    get();
    dispatch({
      type: SET_PLAYER,
      payload: {
        playerOn: false,
      }});
    window.scrollTo(0,0);
  }, []);

  
  useEffect(() => {
    if(payment && state.account && state.account.subscriptions && state.lookups.subscriptionPackages.length > 0)
    {
      buyGiftCode();
    }
   }, [payment, state.account, state.lookups]);

   useEffect(() => {
   if(session)
    mayaAlert.fire({
      icon: "success",
      background: "rgba(17, 24, 39, 1)",
      html: '<div class="text-white text-4xl my-4">Payment Completed</div><div class="text-white tex-sm">Your gift card is purchased</div>',
    }).then(() =>  history.push("/account/giftcards"));

  }, [session]);

  return (
    <>
      {type !== 'mobile'  && 
      <NavBar className="navbar-browse w-full">
        <Logo history={history}  state={state} dispatch={dispatch} />
        <ProfileCompound history={history} />
      </NavBar>
      }
      <AllSlidesWrapper className={["mb-10 mx-4 md:mx-10 min-h-screen", type === 'mobile' ? '' : ' py-16'].join(' ')}>

        <div className="mx-auto container max-w-6xl md:w-full pt-10">
          <div className="-ml-2">
            <button
              onClick={() => history.push('/browse')}
              className="inline-flex items-center focus:outline-none mr-3 text-gray-500 mt-1 hover:text-gray-400  text-lg"
            >
              <FaChevronLeft className="text-center   text-xs mx-1" />
              Back
            </button>
          </div>

          <div className="text-2xl font-medium my-2 w-1/2 md:w-5/6 inline-block">

            Gift Cards
          </div>
         {platform !== 'iOS' && <div className="text-sm font-medium my-2 w-1/2  md:w-1/6 inline-block">
            <ProfileFormButton onClick={() =>  buyGiftCode()} id="giftCode">Buy</ProfileFormButton>
          </div>}
          <hr />
        </div>
        {loading && (

        <NoContent>
          <div className="flex justify-center">
            <LoadingIcon />
            Fetching
          </div>
        </NoContent>

        )}
        {!loading && (
        <TableCompounded
          // actionButtons
            // deleteCallback={(id) => onDelete(id, deleteUser)}
            // editCallback={(id) => onEdit(id)}
          ids={giftCards.map((x) => x.id)}
          headers={['Date', 'Code', 'Package', 'Payment method', 'Amount', 'Status']}
          data={giftCards.map((x) =>
            [
              moment(x.createdOn).format("MM/DD/YYYY"),
              code(x.code),
              state.lookups.subscriptionPackages.find((y) => y.id === x.payment.package)?.description,
              onPayment(x.payment.paymentMethodName),
              x.payment.amount + `${x.payment.currency === 1 ? ' ETB' : ' USD'}`,
              status(x.isRedeemed)

            ]
          )}
        />
        )}
      </AllSlidesWrapper>

      <FooterCompound />
    </>
  );
}

export default GiftCardPage;
