import React from "react";


function ProfileIconSmall({ letters, ...restProps }) {
  return (
    <div className="bg-blue-500 items-center text-center  justify-center rounded p-2 inline-block w-10 h-10 ">
      <span className="text-white text-base mx-auto uppercase">{letters}</span>
    </div>
  );
}

export default ProfileIconSmall;
