import React from "react";
import "./MoviesStyles.css";

function AllSlidesWrapper({ children, ...restProps }) {
  return (
    <div className="all-slides-wrapper mx-0 md:mx-4 mt-10" {...restProps}>
      {children}
    </div>
  );
}

export default AllSlidesWrapper;
