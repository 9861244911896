/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-cycle */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import LoadingIcon from "../components/Common/LoadingIcon";
import AddToListButton from "../components/Header/AddToListButton";
import PlayButton from "../components/Header/PlayButton";
import RemoveFromListButton from "../components/Header/RemoveFromListButton";
import TrailerPlayButton from "../components/Header/TrailerPlayButton";
import CardDescription from "../components/Movies/CardDescription";
import CardFeatureClose from "../components/Movies/CardFeatureClose";
import CardFeatureWrapper from "../components/Movies/CardFeatureWrapper";
import CardSeries from "../components/Movies/CardSeries";
import CardTitle from "../components/Movies/CardTitle";
import { addToMyList, removeFromList, showPlayer } from "../context/Actions";
import {  movieMapper } from "../lib/utils";
import MayaDataService from "../services/MayaDataService";
import CardTagCompound from "./CardTagCompound";

function MovieCardCompounded({ currentItem, state, dispatch, isSeries, onClose, showAddToList = true }) {
  const [season, setSeason] = useState();
  // const [currentItem, setCurrentItem] = useState(activeItem);
  const [seriesMovies, setSeriesMovies] = useState([]);
  const [seasonLoading,setSeasonLoading] = useState(false);
  const [currentPageSeason, setCurrentPageSeason] = useState(1);
  const [totalPageSeason, setTotalPageSeason] = useState(0);

  const onSeasonSelect = (seasonId) => {
    setSeason(seasonId);
    setSeasonLoading(true);
    MayaDataService.getAllMovies({ seasonId, pageSize: 10 }).then((result) => {
      setSeriesMovies(movieMapper(result.data,state).sort(function (a, b) {

        return a.episodeNumber - b.episodeNumber;
      }));
      setCurrentPageSeason(result.currentPage);
      setTotalPageSeason(result.to);
      setSeasonLoading(false);
    });
  };

  useEffect(() => {
    if(isSeries && currentItem)
    onSeasonSelect(currentItem.seasons.sort(function (a, b) {
      return a.order - b.order;
      })[0]?.id);
  }, [state.account,isSeries]);
  return (
    <>
    { (currentItem && !isSeries) || (isSeries && seriesMovies[0] && currentItem)  ? 
      <div className="">
      <CardFeatureWrapper style={{
        backgroundImage: `linear-gradient(to right, rgb(17, 24, 39) 30%, rgba(0,0,0,0)), url(${currentItem.mainPic === '/images/misc/default.png' ? currentItem.banner: currentItem.mainPic })`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundColor: 'rgba(0, 0, 0, 0.5)'

      }}
      >
        <CardDescription>
          <CardTitle>{currentItem.title}</CardTitle>
          {currentItem.description}
        </CardDescription>
      
        <CardTagCompound movie={currentItem} isSeries={isSeries} />
        <CardDescription className="card-action-bar text-white">
          <CardFeatureClose onClick={() => onClose()} />
          <PlayButton onClick={() => {
            if(isSeries)
              showPlayer(state, dispatch, seriesMovies[0], false);
            else
              showPlayer(state, dispatch,currentItem, false);
          }}
          >
            Play
          </PlayButton>
          <TrailerPlayButton onClick={() =>
            {
              if(isSeries)
              showPlayer(state, dispatch, seriesMovies[0], true);
            else
              showPlayer(state, dispatch, currentItem, true)
            }
            
          }>
            Watch Trailer
          </TrailerPlayButton>
         {showAddToList && <>
          {!(isSeries ? state.mySeriesList : state.myList).some((x) => x.id === currentItem.id) && (
          <AddToListButton
            onClick={() => 
              !state.isAddingToMyList  ?  addToMyList(currentItem, dispatch, state, isSeries) : {}
            }
          >
            My List
            {state.isAddingToMyList && <span className="ml-2"> <LoadingIcon /></span>}
          </AddToListButton>
          )}
          {(isSeries ? state.mySeriesList : state.myList).some((x) => x.id === currentItem.id)  && (
          <RemoveFromListButton
            onClick={() => 
              !state.isAddingToMyList  ?   removeFromList(currentItem, dispatch, state, isSeries) : {}
            }
          >
            My List 
           {state.isAddingToMyList && <span className="ml-2"> <LoadingIcon /></span>}
          </RemoveFromListButton>
          )}
          </> }

        </CardDescription>
      </CardFeatureWrapper>
     {isSeries && <div className="pb-10 z-50 relative bg-gray-800 text-white px-10 card-feature-wrapper">
        <div className="w-full pb-2 border-b border-gray-400">
          <div className="w-1/2 inline-block md:px-10 px-1 mb-4 text-left">
            <div className="text-sm md:text-xl font-semibold">
              Episodes
            </div>
          </div>
          <div className="w-1/2 inline-block align-top text-right  md:px-10 px-1 mb-4">

            <select
              className="season-selector bg-gray-900 py-2 px-8 border border-gray-100 rounded text-sm md:text-base  focus:outline-none font-semibold"
              onChange={(x) => onSeasonSelect(x.target.value)}
            >
              {currentItem.seasons.sort(function (a, b) {
                return a.order - b.order;
                }).map((x) => <option key={x.id} value={x.id}>{x.title}</option>)}
            </select>

          </div>

        </div>

        {!seasonLoading && seriesMovies.map((y, i) => (
            <CardSeries
              key={y.id}
              item={{
                season: y.episodeNumber,
                title: y.title,
                description: y.description,
                duration: y.duration,
                image: y.thumbnail,
              }}
              callback={() => showPlayer(state, dispatch, y, false)}
            />

          ))}

        {seasonLoading && <span className=" align-middle flex justify-center my-20"> <LoadingIcon /> Fetching ... </span> }

        {!seasonLoading && seriesMovies.length === 0 && <span className=" align-middle flex justify-center my-20"> No Episodes</span> }

              

        <div className="my-6 flex justify-center">
          {currentPageSeason > 1 && (
          <span className="inline-block md:mx-10 mx-2  cursor-pointer" onClick={() => setCurrentPageSeason(currentPageSeason - 1)}>
            <FaChevronLeft className="animate-pulse text-2xl inline-block" />
            {' '}
            Previous
          </span>
          )}
          {currentPageSeason + 1 < totalPageSeason && (
          <span className="inline-block  md:mx-10 mx-2  cursor-pointer" onClick={() => setCurrentPageSeason(currentPageSeason + 1)}>
            Next
            {' '}
            <FaChevronRight className="animate-pulse text-2xl inline-block" />
          </span>
          )}

        </div>

      </div> }

    </div> : <></>}
    </>

  );
}

export default MovieCardCompounded;
