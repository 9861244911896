/* eslint-disable import/named */
import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import HeaderWrapper from "../components/Header/HeaderWrapper";
import NavBar from "../components/Header/NavBar";
import Logo from "../components/Header/Logo";
import FooterCompound from "../compounds/FooterCompound";
import SignFormWrapper from "../components/SignForm/SignFormWrapper";
import SignFormBase from "../components/SignForm/SignFormBase";
import SignFormTitle from "../components/SignForm/SignFormTitle";
import SignFormInput from "../components/SignForm/SignFormInput";
import SignFormButton from "../components/SignForm/SignFormButton";
import SignFormText from "../components/SignForm/SignFormText";
import SignFormLink from "../components/SignForm/SignFormLink";
import SignFormError from "../components/SignForm/SignFormError";
import OverlayWrapper from "../components/Header/OverlayWrapper";
import { SET_PROFILE, SET_TOKEN } from "../context/ActionTypes";
import { StoreContext } from '../context/Store';
import MayaDataService from "../services/MayaDataService";
import LoadingIcon from "../components/Common/LoadingIcon";
import { setSession } from "../lib/utils";
import { getFeaturedMovie } from "../context/Actions";
import { checkEmailorPhone, checkPassword } from "../constants";
import PhoneNumberInput from "../components/SignForm/PhoneNumberInput";

function SigninPage() {
  const history = useHistory();
  const [state, dispatch] = useContext(StoreContext);
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const IsInvalid = password === "" || !checkEmailorPhone(username) || loading;
  const [isUserNameTypePhone, setUserNameType] = useState(true);

  function handleSubmit(event) {
    setLoading(true);
    event.preventDefault();
    let usrname = username;
    if (!username.includes("@") && !username.includes("+")) {
      usrname = `+${username}`;
    }

    // if (!checkPassword(password)) {
    //   setError('Password must be at least 8 characters long and contain a capital letter, a number and a special character.');
    //   return;
    // }

    MayaDataService.auth(usrname, password).then((result) => {
      localStorage.setItem('token', result.data.token);
      localStorage.setItem('user', JSON.stringify(result.data.user));
      dispatch({ type: SET_TOKEN, payload: result.data });
      dispatch({
        type: SET_PROFILE,
        payload: result.data.user.profiles
      });
      setSession(dispatch);
      getFeaturedMovie(state, dispatch);
      history.push("/browse");
    }).catch((e) => {
      setError('Username/Password Incorrect');
      setLoading(false);
    });
  }

  const handlePhoneChange = (value) => {
    ///TODO: This code is duplicated. Try to reuse it from one place
    let countryCode = value.substring(0, 3);
    ///Remove zero from Ethiopian phone number after country code +251[0]911...
    if (countryCode === '251') {
      if (value[4] !== undefined && value[3] === '0') {
        var temp = value.substring(4, value.length);
        value = '251' + temp;
      }
    }
    setUsername(`+${value}`);
  }

  const handleSignInMethod = (event) => {
    event.preventDefault();
    setUserNameType(!isUserNameTypePhone);
    setUsername("");
    setPassword("");
  }
  
  //Avoids to go back to country code selection after a user enters phone (tab index sequence)
  var elems = document.getElementsByClassName("selected-flag");
  for (var i = 0; i < elems.length; i++) {
    elems[i].tabIndex = 101;
  }

  return (
    <>
      <HeaderWrapper className="header-wrapper-home">
        <OverlayWrapper>
          <NavBar className="navbar-signin">
            <Logo history={history} state={state} dispatch={dispatch} />
          </NavBar>
          <SignFormWrapper>

            <SignFormBase onSubmit={handleSubmit} method="POST">
              <SignFormTitle>Sign In</SignFormTitle>
              {error ? <SignFormError>{error}</SignFormError> : null}
              <button type="button" className="userNameType" tabIndex={10} onClick={handleSignInMethod}> {isUserNameTypePhone ? <>Use Email</> : <>Use Phone</>} </button>
              {
                isUserNameTypePhone ?
                  <PhoneNumberInput
                    username={username}
                    onChange={handlePhoneChange}
                  />
                  :
                  <SignFormInput
                    type="email"
                    placeholder="Enter Email Address"
                    value={username}
                    onChange={({ target }) => setUsername(target.value)}
                  />
              }

              <SignFormInput
                type="password"
                placeholder="Password"
                autoComplete="off"
                value={password}
                onChange={({ target }) => setPassword(target.value)}
              />
              <SignFormButton disabled={IsInvalid}>
                {loading && (
                  <span className="inline-block align-middle">
                    <LoadingIcon />
                  </span>
                )}
                {!loading && <span className="inline-block align-middle"> Sign In </span>}
              </SignFormButton>
              <SignFormText>
                New to Mayaflicks?
                <SignFormLink to="/signup" >Sign up now.</SignFormLink>
              </SignFormText>

              <div className="text-center mt-10">

                <SignFormLink to="/forgot_password">  Forgot Password?</SignFormLink>
              </div>
            </SignFormBase>
          </SignFormWrapper>
        </OverlayWrapper>
      </HeaderWrapper>
      <FooterCompound />
    </>
  );
}

export default SigninPage;
