/* eslint-disable react/jsx-key */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable radix */
import React, {  useEffect, useState } from "react";
import CurrencyFormat from 'react-currency-format';
import { FaCcPaypal, FaChevronRight, FaRegTimesCircle } from "react-icons/fa";
import { paymentOptions, YENEPAY_ICON_URL,  TELEBIRR_ICON_URL, ETC_CREDIT_ICON_URL } from "../../constants";
import LoadingIcon from "../Common/LoadingIcon";
import moment from "moment";
import StripeForm from "./StripeForm";
import PayPalForm from "./PayPalForm";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from '@stripe/stripe-js';
import YenePayForm from "./YenePayForm";
import Switch from "react-switch";
import MayaDataService from "../../services/MayaDataService";
import { SET_TOKEN } from "../../context/ActionTypes";
import { setSession } from "../../lib/utils";
import TelebirrForm from "./TelebirrForm";
import ETCCreditForm from "./ETCCreditForm";
import PackageFormSection from "../ProfileForm/PackageFormSection";
import ProfileFormInput from "../ProfileForm/ProfileFormInput";
import VerifyButton from "../ProfileForm/VerifyButton";
import OtpFormCompund from "../../compounds/OtpFormCompund";

import SignFormError from "../SignForm/SignFormError";
import {
  FaSync,
  FaCheckCircle,
} from "react-icons/fa";

//import './PackageForm.css';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(`${process.env.REACT_APP_API_STRIPE_PUBLISHABLE_KEY}`);


function PackageForm({
  profileId, validDate, lookups, dispatch, state, account, 
  errorVerify,
  setAccount,
  loadingVerify,
  previousToken,
  verifying, setVerifying,
  verificationData,setVerificationData,
  originalPhone,
  isPhoneVerified,
  verifyAccount,
  verify,
  verifyPhoneAccount,
  close

}) {
  const [paymentOption, setPaymentOption] = useState('telebirr');
  const [selectedPackage, setSelectedPackage] = useState();
  const [selectedWizard, setSelectedWizard] = useState('verify');
  const [subscriptionDate, setSubscriptionDate] = useState(validDate);
  const [proceedPayment, setProceedPayment] = useState(false);
  const [isPriceUSD, setIsPriceUSD] = useState(false);

  const proceed = () => {
    setProceedPayment(true);
    setSelectedWizard('checkout');
  };

  const successCallback = () => {
    MayaDataService.renewToken(profileId).then((result) => {
      localStorage.setItem('token', result.data.token);
      localStorage.setItem('user', JSON.stringify(result.data.user));
      dispatch({ type: SET_TOKEN, payload: result.data });
      setSession(dispatch);
    })

    close()
  }
  useEffect(() => {
    if (selectedPackage) {
      var days = 30;
      if (selectedPackage.package === 1)
        days = 30;
      else if (selectedPackage.package === 2)
        days = 90;
      else if (selectedPackage.package === 3)
        days = 365;
      else if (selectedPackage.package === 4)
        days = 1;

      if (validDate)
        days = days + moment(validDate).diff(moment({ hours: 0 }), 'days');

      setSubscriptionDate(moment().add(days, 'days').format('MMM DD YYYY'));
    }


  }, [selectedPackage]);

  useEffect(() => {
    if (state.account?.isPhoneVerified || state.account?.isEmailVerified) {
      setSelectedWizard('package');
    }
  }, []);

  return (
    <div className="m-2 w-full">

      <div className="w-full">
        <FaRegTimesCircle onClick={() => close()} className="inline-block w-12 text-2xl float-right text-gray-200 hover:text-gray-300 cursor-pointer" />

        <div className="container flex flex-wrap pt-1 pb-2 m-auto mt-1">
          <div className="w-full px-0 lg:px-4">
            <div className="mb-10 items-center justify-center lg:flex font-medium text-xs flex text-gray-300 -mx-16 md:mx-0">
              <div className={`${selectedWizard === 'verify' ? 'text-sm  text-white' : ''} md:mx-2 mx-0 cursor-pointer`} onClick={() => setSelectedWizard('verify')}>Verify account</div>
              <div className="mx-1 md:mx-1">
                <FaChevronRight />
              </div>
              <div className={`${selectedWizard === 'package' ? 'text-sm  text-white' : ''} md:mx-2 mx-0 cursor-pointer`} onClick={() => (state.account?.isPhoneVerified || state.account?.isEmailVerified) ? setSelectedWizard('package') : {}}>Choose a package</div>
              <div className="mx-1 md:mx-1">
                <FaChevronRight />
              </div>
              <div className={`${selectedWizard === 'summary' ? 'text-sm  text-white' : ''} md:mx-2 mx-0 cursor-pointer`} onClick={() => selectedPackage ? setSelectedWizard('summary') : {}}>Payment method</div>
              <div className="mx-1 md:mx-1">
                <FaChevronRight />
              </div>
              <div className={`${selectedWizard === 'checkout' ? 'text-sm text-white' : ''} md:mx-2 mx-0 cursor-pointer`} onClick={() => selectedPackage && proceedPayment ? setSelectedWizard('checkout') : {}}>Checkout</div>
            </div>
            {selectedWizard != 'verify' &&
              <div className="my-2">
                <span className="text-white mx-2 text-lg inline-block align-top">ETB</span>
                <Switch
                  onColor="#86d3ff"
                  onHandleColor="#86d3ff"
                  handleDiameter={30}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                  height={20}
                  width={48}
                  onChange={(e) => {
                    setIsPriceUSD(e);
                    if (e)
                      setPaymentOption('paypal');
                    else
                      setPaymentOption('telebirr');
                  }}
                  checked={isPriceUSD}
                  className="react-switch"
                />
                <span className="text-white mx-2 text-lg inline-block align-top">USD</span>
              </div>
            }

            <div style={{ minHeight: '350px' }}>
              {selectedWizard === 'verify' && (
                <div>
                  <div className="mb-5 font-medium text-white text-sm">
                    {(state.account.isEmailVerified || state.account.isPhoneVerified)
                     ? 'Your account is verified!'
                     : 'We need to verify your account before you make a purchase. We will send you a verification code through email or SMS.'}
                  </div>
                  <PackageFormSection>
                    <div className="text-sm mt-2 w-48 text-left md:text-right">Username</div>
                    <div className="flex-1 ">
                      <div className="w-full inline-flex">
                        <ProfileFormInput
                          type="text"
                          value={account?.username || ''}
                          placeholder="email or phone number"
                          disabled
                        />
                      </div>

                      <div className="w-full">
                        {" "}
                        {errorVerify ? (
                          <SignFormError>{errorVerify}</SignFormError>
                        ) : null}
                      </div>
                      {((account?.username.includes("@") &&
                        account?.isEmailVerified) ||
                        (!account?.username.includes("@") &&
                          account?.isPhoneVerified)) && (
                          <div className="w-full text-center mt-4">
                            <FaCheckCircle className="inline-block text-green-500 mr-1 -mt-1" />
                            Verified
                          </div>
                        )}
                      {((account?.username.includes("@") &&
                        !account?.isEmailVerified) ||
                        (!account?.username.includes("@") &&
                          !account?.isPhoneVerified)) && (
                          <div className="w-full text-center mt-6">
                            <VerifyButton
                              onClick={() => verifyAccount()}
                              disabled={loadingVerify}
                            >
                              {!loadingVerify && (
                                <FaSync className="text-center  text-sm mx-2 inline-block" />
                              )}
                              {loadingVerify && (
                                <span className="inline-block align-middle">
                                  {" "}
                                  <LoadingIcon />{" "}
                                </span>
                              )}
                              {(account?.username.includes("@") ? 'Verify using Email' : 'Verify using SMS')}
                            </VerifyButton>
                          </div>
                        )}
                    </div>
                  </PackageFormSection>
                  {account?.username.includes("@") &&
                    <>
                      <div className="my-4 font-medium text-white">
                        —— OR ——
                      </div>
                      <PackageFormSection>
                        <div className="text-sm mt-2 w-48 text-left md:text-right">Phone Number</div>
                        <div className="flex-1">
                          <div className="w-full flex flex-col gap-2">
                            <ProfileFormInput
                              type="tel"
                              value={account?.address.telephoneMob || ''}
                              placeholder="Enter Phone number"
                              pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                              onChange={(e) => {
                                account.address.telephoneMob = e.target.value;
                                if (originalPhone !== e.target.value)
                                  account.isPhoneVerified = false;
                                else
                                  account.isPhoneVerified = isPhoneVerified;

                                setAccount({ ...account });
                              }}

                            />
                            <div className="text-xs text-gray-400">e.g +251911000000 - You need a verified phone number to make purchases with your Airtime.</div>
                          </div>
                          <div className="w-full">
                            {" "}
                            {errorVerify ? (
                              <SignFormError>{errorVerify}</SignFormError>
                            ) : null}
                          </div>
                          {account?.isPhoneVerified ? (
                            <div className="w-full text-center mt-4">
                              <FaCheckCircle className="inline-block text-green-500 mr-1 -mt-1" />
                              Verified
                            </div>
                          ) : (
                            <div className="w-full text-center mt-6">
                              <VerifyButton
                                onClick={() => verifyPhoneAccount()}
                                disabled={loadingVerify || !account?.address.telephoneMob || verifying}
                              >
                                {!loadingVerify && (
                                  <FaSync className="text-center  text-sm mx-2 inline-block" />
                                )}
                                {loadingVerify && (
                                  <span className="inline-block align-middle">
                                    {" "}
                                    <LoadingIcon />{" "}
                                  </span>
                                )}
                                Verify using SMS
                              </VerifyButton>
                            </div>
                          )}
                        </div>
                      </PackageFormSection>

                    </>
                  }
                  {verifying && (
                    <div className="mx-auto container max-w-3xl md:w-3/4 md:px-40">
                      <OtpFormCompund
                        verify={() => verify(verificationData, previousToken)}
                        username={account?.username.includes("@") ? account?.address.telephoneMob
                          : account?.username}
                        title=""
                      />
                      <div className="w-full my-4 text-center flex justify-center">
                        <button
                          onClick={() => {
                            setVerificationData();
                            setVerifying(false);
                          }}
                          className="inline-flex items-center focus:outline-none mr-4 hover:text-gray-400 float-left text-sm"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  )}
                  {
                    <div className="my-4 mt-10 text-sm w-60 mx-auto">
                      <button
                        onClick={() => setSelectedWizard('package')}
                        className="text-white w-full  rounded-md text-center bg-blue-500 py-2 px-4  items-center focus:outline-none disabled:opacity-50"
                        disabled={!(!verifying && (state.account.isEmailVerified || state.account.isPhoneVerified))}
                      >
                        Next: Choose package <FaChevronRight className="inline" />
                      </button>


                    </div>
                  }
                </div>
              )}
              {selectedWizard === 'package' && (
                <div>
                  <h2 className="px-1 md:px-12 text-sm md:text-base font-bold text-center md:text-2xl text-white">
                    Choose your package
                  </h2>
                  {validDate && <p className="py-1 text-xs md:text-sm text-center text-gray-200 mb-2">
                    Your account is paid until
                    {' '}
                    {validDate}
                  </p>
                  }

                  <div className="flex flex-wrap items-center justify-center py-4 pt-0">
                    {lookups.subscriptionPackages.filter((x) => lookups.priceSettings.some((y) => y.plan === 1 && y.package === x.id)).map((x, index) => (
                      <div
                        key={x.id}
                        onClick={() => {
                          setSelectedPackage(lookups.priceSettings.find((y) => y.plan === 1 && y.package === x.id));
                          setSelectedWizard('summary');
                        }}
                        className="w-full p-4 md:w-1/2 lg:w-1/4  transform hover:scale-105  duration-1000 ease-out">
                        <label className="flex flex-col rounded-lg shadow-lg group relative cursor-pointer hover:shadow-2xl border">
                          <div className="w-full px-4 py-6 rounded-t-lg card-section-1">
                            <h3 className="mx-auto text-base font-semibold text-center  text-white">
                              For {x.description}
                            </h3>
                            <p className="text-2xl font-bold text-center text-white my-4">
                              <CurrencyFormat
                                value={isPriceUSD ? lookups.priceSettings.find((y) => y.plan === 1 && y.package === x.id)?.usdPrice :
                                  lookups.priceSettings.find((y) => y.plan === 1 && y.package === x.id)?.price} decimalScale={2} fixedDecimalScale displayType="text" thousandSeparator
                                prefix={isPriceUSD ? ' $' : ''}
                                suffix={!isPriceUSD ? ' ETB' : ''} />
                            </p>
                          </div>
                          <div className="flex flex-col items-center justify-center w-full h-full py-6 rounded-b-lg bg-blue-500">
                            <button className="w-5/6 py-2 mt-2 font-semibold text-center  text-sm uppercase br-25 bg-white border border-transparent rounded text-blue-500">
                              Choose
                            </button>
                          </div>
                        </label>
                      </div>
                    )

                    )}


                  </div>
                </div>
              )}

              {selectedWizard === 'summary' && (
                <div>
                  <h2 className="px-1 md:px-12 text-sm font-bold text-center md:text-2xl text-white">
                    You chose    {lookups.subscriptionPackages.find((x) => x.id === selectedPackage.package)?.description} plan
                  </h2>
                  <p className="py-1 text-xs md:text-sm text-center text-gray-200 mb-1">
                    Your subscription will be active until
                    {' '}
                    {subscriptionDate}
                  </p>

                  <div className="py-1 text-sm text-center text-gray-300 mb-2">
                    Total Price
                    <div className="text-3xl mb-4">
                      <CurrencyFormat
                        value={!isPriceUSD ? selectedPackage.price : selectedPackage.usdPrice}
                        decimalScale={2}
                        fixedDecimalScale
                        displayType="text"
                        thousandSeparator
                        prefix={isPriceUSD ? ' $' : ''}
                        suffix={!isPriceUSD ? ' ETB' : ''}
                      />
                    </div>


                    <h2 className="px-12 text-base font-bold text-center md:text-2xl text-white">
                      Pay with
                    </h2>
                    <div className="p-1 m-4 flex flex-col md:flex md:flex-row justify-center border border-gray-700 rounded">
                      
                     {!isPriceUSD && <div className="my-2 items-center mx-6 p-2 md:border-none border-b border-gray-700">
                        <input
                          type="radio"
                          name="paymentOption"
                          value="yenepay"
                          checked={paymentOption === 'telebirr'}
                          onChange={() => setPaymentOption('telebirr')}
                          className=" h-5 w-5 cursor-pointer"
                        />
                        <img src={TELEBIRR_ICON_URL} className="w-32 mx-auto ml-1 inline-block" />

                      </div>
                      }
                      {!isPriceUSD && <div className="my-2 items-center mx-6 p-2 md:border-none border-b border-gray-700">
                        <input
                          type="radio"
                          name="paymentOption"
                          value="etcCredit"
                          checked={paymentOption === 'etcCredit'}
                          onChange={() => setPaymentOption('etcCredit')}
                          className=" h-5 w-5 cursor-pointer"
                        />
                        <img src={ETC_CREDIT_ICON_URL} className="w-32 mx-auto ml-1 inline-block" />
                        <div className="w-full block my-2 pb-4 text-xs ml-2">
                          Airtime
                        </div>
                      </div>
                      }
                      {!isPriceUSD && <div className="my-2 items-center mx-6 p-2 md:border-none border-b border-gray-700">
                        <input
                          type= "radio"
                          name="paymentOption"
                          value="yenepay"
                          checked={paymentOption === 'yenepay'}
                          onChange={() => setPaymentOption('yenepay')}
                          className=" h-5 w-5 cursor-pointer"
                        />
                        <img src={YENEPAY_ICON_URL} className="w-32 mx-auto ml-1 inline-block" />
                        <div className="w-full block my-4 pb-4">
                          {paymentOptions.map((x, i) => (
                            <img src={x.icon} className="w-8 rounded-full inline-block mx-1 ml-1" key={"pimg-" + i} />
                          ))}
                        </div>
                      </div>
                      }
                      {/* {isPriceUSD &&  <div className="items-center mx-6 p-2 md:border-none border-b border-gray-700">
                    <input
                      type="radio"
                      name="paymentOption"
                      value="stripe"
                      checked={paymentOption === 'stripe'}
                      onChange={() => setPaymentOption('stripe')}
                      className=" h-5 w-5 -mt-2"
                    />
                   <FaStripe className="text-white text-6xl my-2 inline-block -mt-1 mx-2"  />
                  </div>} */}
                      {isPriceUSD && <div className="items-center mx-6 p-2 md:border-none border-b border-gray-700">
                        <input
                          type="radio"
                          name="paymentOption"
                          value="paypal"
                          checked={paymentOption === 'paypal'}
                          onChange={() => setPaymentOption('paypal')}
                          className=" h-5 w-5 cursor-pointer"
                        />
                        <FaCcPaypal className="text-white text-5xl my-2 inline-block mx-2" />
                      </div>
                      }
                      {/* {!isPriceUSD &&  <div className="my-2 items-center mx-6 p-2 md:border-none">
                    <input
                      type="radio"
                      name="paymentOption"
                      value="tele"
                      checked={paymentOption === 'tele'}
                      onChange={() => setPaymentOption('tele')}
                      className=" h-5 w-5 mt-2"
                      disabled
                    />
                    <span className="ml-2 text-white text-xl -mt-1">MAYA Credit (0 ETB)</span>
                  </div>
                    } */}
                    </div>
                    <br />


                    <div className="my-4 mt-10 w-40 mx-auto">
                      <button
                        onClick={() => proceed()}
                        className="text-white w-full br-25 text-center bg-blue-500 py-2 px-4  items-center focus:outline-none"
                      >
                        Next: Checkout <FaChevronRight className="inline" />
                      </button>


                    </div>



                  </div>

                </div>

              )}


              {selectedWizard === 'checkout' && (
                <div>
                  {proceedPayment && paymentOption === 'stripe' &&

                    <Elements stripe={stripePromise}>
                      <StripeForm selectedPackage={selectedPackage} lookups={lookups} quantity={1} callback={() => successCallback()} />
                    </Elements>
                  }

                  {proceedPayment && paymentOption === 'paypal' &&
                    <PayPalForm selectedPackage={selectedPackage} lookups={lookups} profileId={profileId} quantity={1} callback={() => successCallback()} />
                  }
                  {proceedPayment && paymentOption === 'yenepay' &&
                    <YenePayForm selectedPackage={selectedPackage} lookups={lookups} quantity={1} callback={() => successCallback()} path={"/account/manage"} />
                  }
                  {proceedPayment && paymentOption === 'telebirr' &&
                    <TelebirrForm selectedPackage={selectedPackage} lookups={lookups} quantity={1} callback={() => successCallback()} path={"/account/manage"} />
                  }
                  {proceedPayment && paymentOption === 'etcCredit' &&
                    <ETCCreditForm selectedPackage={selectedPackage} lookups={lookups} quantity={1} callback={() => successCallback()} isPhoneVerified={account?.isPhoneVerified} verifyCallback={() => setSelectedWizard('verify')} />
                  }
                </div>
              )
              }

            </div>
          </div>

        </div>
      </div>
      <div className="w-full my-2" />

    </div>
  );
}

export default PackageForm;
