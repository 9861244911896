/* eslint-disable no-return-assign */
import React, { createContext, useReducer, useEffect } from "react";
import { getUUID, MY_MOVIES_LIST, MY_SERIES_LIST } from "../constants";
import { setSession } from "../lib/utils";
import MayaDataService from "../services/MayaDataService";
import {
  getFeaturedMovie,
  getMyList,
  getMySeriesList,
  getInprogressMovies,
} from "./Actions";
import {
  SET_MY_SERIES_LIST,
  LOADING_MORE_MY_SERIES_LIST_SUCCESS,
  SET_LOOKUPS,
  SET_FILTER,
  FETCH_MOVIES_REQUEST,
  FETCH_MOVIES_SUCCESS,
  FETCH_MOVIES_FAILURE,
  SET_PLAYER,
  SET_CURRENT_MOVIE,
  SET_TOKEN,
  SET_MY_LIST,
  ADD_TO_MY_LIST_REQUEST,
  ADD_TO_MY_LIST_SUCCESS,
  ADD_TO_MY_LIST_FAILURE,
  LOADING_MORE_MOVIES_SUCCESS,
  LOADING_MORE_MYLIST_SUCCESS,
  ADD_PROFILE,
  UPDATE_PROFILE,
  SELECT_PROFILE,
  FETCH_SERIES_REQUEST,
  FETCH_SERIES_SUCCESS,
  LOADING_MORE_SERIES_SUCCESS,
  FETCH_SERIES_FAILURE,
  SET_PROFILE,
  RESET,
  SET_SEASON,
  FETCH_FEATURED_REQUEST,
  FETCH_FEATURED_SUCCESS,
  FETCH_INPROGRESS_MOVIES_REQUEST,
  FETCH_INPROGRESS_MOVIES_SUCCESS,
  SET_SESSION,
  SET_HEADER_MOVIE_PLAY,
  SET_SERIES_HOME_GENRE,
  SET_MOVIES_HOME_GENRE,
} from "./ActionTypes";

export const StoreContext = createContext({});

const initialState = {
  account: null,
  myList: [],
  mySeriesList: [],
  currentPaging: {
    pageSize: 0,
    totalCount: 0,
    totalPages: 0,
  },
  isAddingToMyList: false,
  hasAddingMyListError: false,
  cached: {},
  movies: [],
  series: [],
  profiles: [],
  isAddingProfile: false,
  hasAddingProfileError: false,
  selectedProfile: null,
  isFetching: true,
  hasError: false,
  filter: {
    genre: null,
    category: null,
  },
  playerOn: false,
  isPlayerTrailer: false,
  currentMovie: null,
  headerMovie: null,
  headerSeries: null,
  isFetchingFeatured: true,
  hasFeaturedError: false,
  inProgressMovies: [],
  isFetchingInProgress: true,
  hasInprogressError: false,
  lookups: {
    ratings: [],
    artistRoles: [],
    picTypes: [],
    genres: [],
    isSet: false,
    subscriptionPackages: [],
    priceSettings: [],
    giftCodePriceSettings: [],
    allGenres: [],
  },
  token: null,
  listOfGenreOnBrowse: [],
  listOfGenreOnBrowseSeries: [],
  sessionId: null,
  seasons: [],
  playHeaderMovie: true,
};

function reducer(state, action) {
  switch (action.type) {
    case SET_HEADER_MOVIE_PLAY:
      return {
        ...state,
        playHeaderMovie: action.payload,
      };
    case FETCH_FEATURED_REQUEST:
      return {
        ...state,
        isFetchingFeatured: true,
        hasFeaturedError: false,
      };
    case FETCH_FEATURED_SUCCESS:
      return {
        ...state,
        isFetching: false,
        headerMovie: action.payload.some((x) => x.category === 1)
          ? action.payload.find((x) => x.category === 1)
          : null,
        headerSeries: action.payload.some((x) => x.category === 2)
          ? action.payload.find((x) => x.category === 2)
          : ( action.payload.some((x) => x.category === 1)
          ? action.payload.find((x) => x.category === 1)
          : null),
      };
    case RESET:
      return {
        ...state,
        movies: [],
        series: [],
        cached: {},
        myList: [],
        mySeriesList: [],
        currentPaging: {
          pageSize: 0,
          totalCount: 0,
          totalPages: 0,
        },
      };
    case FETCH_MOVIES_REQUEST:
      return {
        ...state,
        isFetching: true,
        hasError: false,
      };
    case FETCH_MOVIES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        movies: action.payload.movies,
        cached: {
          ...state.cached,
          [action.payload.type]: action.payload.movies,
          [`${action.payload.type}-paging`]: {
            currentPage: action.payload.currentPage,
            pageSize: action.payload.pageSize,
            totalCount: action.payload.totalCount,
            totalPages: action.payload.totalPages,
          },
        },
      };
    case LOADING_MORE_MOVIES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        movies: state.movies.concat(action.payload.movies),
        cached: {
          ...state.cached,
          [action.payload.type]: state.movies.concat(action.payload.movies),
          [`${action.payload.type}-paging`]: {
            currentPage: action.payload.currentPage,
            pageSize: action.payload.pageSize,
            totalCount: action.payload.totalCount,
            totalPages: action.payload.totalPages,
          },
        },
      };
    case FETCH_MOVIES_FAILURE:
      return {
        ...state,
        hasError: true,
        isFetching: false,
      };

    case FETCH_SERIES_REQUEST:
      return {
        ...state,
        isFetching: true,
        hasError: false,
      };
    case FETCH_SERIES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        series: action.payload.series,
        cached: {
          ...state.cached,
          [action.payload.type]: action.payload.series,
          [`${action.payload.type}-paging`]: {
            currentPage: action.payload.currentPage,
            pageSize: action.payload.pageSize,
            totalCount: action.payload.totalCount,
            totalPages: action.payload.totalPages,
          },
        },
      };
    case LOADING_MORE_SERIES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        series: state.series.concat(action.payload.series),
        cached: {
          ...state.cached,
          [action.payload.type]: state.series.concat(action.payload.series),
          [`${action.payload.type}-paging`]: {
            currentPage: action.payload.currentPage,
            pageSize: action.payload.pageSize,
            totalCount: action.payload.totalCount,
            totalPages: action.payload.totalPages,
          },
        },
      };
    case FETCH_SERIES_FAILURE:
      return {
        ...state,
        hasError: true,
        isFetching: false,
      };
    case SET_FILTER:
      return {
        ...state,
        filter: action.payload,
      };
    case SET_PLAYER:
      return {
        ...state,
        playerOn: action.payload.playerOn,
        isPlayerTrailer: action.payload.isPlayerTrailer,
      };
    case SET_CURRENT_MOVIE:
      return {
        ...state,
        currentMovie: action.payload,
      };
    case SET_LOOKUPS:
      return {
        ...state,
        lookups: action.payload,
      };
    case SET_TOKEN:
      return {
        ...state,
        token: action.payload.token,
        account: action.payload.user,
      };
    case ADD_TO_MY_LIST_REQUEST:
      return {
        ...state,
        isAddingToMyList: true,
      };
    case ADD_TO_MY_LIST_SUCCESS:
      return {
        ...state,
        myList: [...state.myList, action.payload],
        isAddingToMyList: false,
      };
    case ADD_TO_MY_LIST_FAILURE:
      return {
        ...state,
        hasAddingMyListError: true,
        isAddingToMyList: false,
      };
    case SET_MY_LIST:
      return {
        ...state,
        isAddingToMyList: false,
        myList: action.payload.movies,
        cached: {
          ...state.cached,
          [action.payload.type]: action.payload.movies,
          [`${action.payload.type}-paging`]: {
            currentPage: action.payload.currentPage,
            pageSize: action.payload.pageSize,
            totalCount: action.payload.totalCount,
            totalPages: action.payload.totalPages,
          },
        },
      };
    case LOADING_MORE_MYLIST_SUCCESS:
      return {
        ...state,
        isAddingToMyList: false,
        myList: state.myList.concat(action.payload.movies),
        cached: {
          ...state.cached,
          [action.payload.type]: state.myList.concat(action.payload.movies),
          [`${action.payload.type}-paging`]: {
            currentPage: action.payload.currentPage,
            pageSize: action.payload.pageSize,
            totalCount: action.payload.totalCount,
            totalPages: action.payload.totalPages,
          },
        },
      };
    case SET_MY_SERIES_LIST:
      return {
        ...state,
        isAddingToMyList: false,
        mySeriesList: action.payload.movies,
        cached: {
          ...state.cached,
          [action.payload.type]: action.payload.movies,
          [`${action.payload.type}-paging`]: {
            currentPage: action.payload.currentPage,
            pageSize: action.payload.pageSize,
            totalCount: action.payload.totalCount,
            totalPages: action.payload.totalPages,
          },
        },
      };
    case LOADING_MORE_MY_SERIES_LIST_SUCCESS:
      return {
        ...state,
        isAddingToMyList: false,
        mySeriesList: state.mySeriesList.concat(action.payload.movies),
        cached: {
          ...state.cached,
          [action.payload.type]: state.mySeriesList.concat(
            action.payload.movies
          ),
          [`${action.payload.type}-paging`]: {
            currentPage: action.payload.currentPage,
            pageSize: action.payload.pageSize,
            totalCount: action.payload.totalCount,
            totalPages: action.payload.totalPages,
          },
        },
      };
    case SET_PROFILE:
      return {
        ...state,
        profiles: action.payload,
        isAddingProfile: false,
      };
    case ADD_PROFILE:
      return {
        ...state,
        profiles: [...state.profiles, action.payload],
        isAddingProfile: false,
      };
    case UPDATE_PROFILE:
      return {
        ...state,
        profiles: action.payload,
        isAddingProfile: false,
      };
    case SELECT_PROFILE:
      return {
        ...state,
        selectedProfile: action.payload,
      };
    case SET_SEASON: {
      return {
        ...state,
        seasons: action.payload,
      };
    }
    case FETCH_INPROGRESS_MOVIES_REQUEST:
      return {
        ...state,
        isFetchingInProgress: true,
        inProgressMovies: [],
      };
    case FETCH_INPROGRESS_MOVIES_SUCCESS:
      return {
        ...state,
        isFetchingInProgress: true,
        inProgressMovies: action.payload,
      };

    case SET_SESSION: {
      return {
        ...state,
        sessionId: action.payload,
      };
    }
    case SET_MOVIES_HOME_GENRE: {
      return {
        ...state,
        listOfGenreOnBrowse: action.payload,
      };
    }
    case SET_SERIES_HOME_GENRE: {
      return {
        ...state,
        listOfGenreOnBrowseSeries: action.payload,
      };
    }

    default:
      throw new Error("Action type must be defined");
  }
}

const Store = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  useEffect(() => {
    MayaDataService.getLookups().then((result) => {

      const data = {
        ratings: result.data.ratings,
        genres: result.data.genres
          .filter((x) => x.isActive && !x.isCustom)
          .map((x) => {
            return {
              name: x.name,
              order: x.order,
              id: x.id,
            };
          })
          .sort(function (a, b) {
            return a.order - b.order;
          }),
        picTypes: result.data.picTypes.map((x) => x.name),
        artistRoles: result.data.artistRoles.map((x) => x.name),
        priceSettings: result.data.priceSettings,
        giftCodePriceSettings: result.data.giftCodePriceSettings,
        subscriptionPackages: result.data.subscriptionPackages,
        allGenres: result.data.genres,
        isSet: true,
      };
      dispatch({ type: SET_LOOKUPS, payload: data });

      dispatch({
        type: SET_MOVIES_HOME_GENRE,
        payload: result.data.genres
          .filter((x) => x.isActive && x.isCustom && x.forMovie)
          .map((x) => {
            return {
              name: x.name,
              order: x.order,
              id: x.id,
            };
          })
          .sort(function (a, b) {
            return a.order - b.order;
          }),
      });

      dispatch({
        type: SET_SERIES_HOME_GENRE,
        payload: result.data.genres
          .filter((x) => x.isActive && x.isCustom && x.forSeries)
          .map((x) => {
            return {
              name: x.name,
              order: x.order,
              id: x.id,
            };
          })
          .sort(function (a, b) {
            return a.order - b.order;
          }),
      });
    });

    if (localStorage.getItem("token")) {
      dispatch({
        type: SET_TOKEN,
        payload: {
          token: localStorage.getItem("token"),
          user: JSON.parse(localStorage.getItem("user")),
        },
      });


      dispatch({
        type: SET_PROFILE,
        payload: JSON.parse(localStorage.getItem("user"))?.profiles,
      });

      if (localStorage.getItem("profile")) {
        dispatch({
          type: SELECT_PROFILE,
          payload: JSON.parse(localStorage.getItem("profile")),
        });
        getMyList(state, dispatch, MY_MOVIES_LIST, {}, true);
        getMySeriesList(state, dispatch, MY_SERIES_LIST, {}, true);
        getInprogressMovies(state, dispatch);
      }

      setSession(dispatch);

      getFeaturedMovie(state, dispatch);
    }
  }, []);

  useEffect(() => {
    if (state.lookups.isSet && state.account) {
      getMyList(state, dispatch, MY_MOVIES_LIST, {}, true);
      getMySeriesList(state, dispatch, MY_SERIES_LIST, {}, true);
      getInprogressMovies(state, dispatch);
    }
  }, [state.lookups, state.account]);

  return (
    <StoreContext.Provider value={[state, dispatch]}>
      {children}
    </StoreContext.Provider>
  );
};

export default Store;
