import React from "react";
import "./HeaderStyles.css";

function HeaderLink({ children, ...restProps }) {
  return (
    <div className="text-sm md:text-base  md:mr-4 md:mt-2 cursor-pointer">
      <a {...restProps}>{children}</a>
    </div>
  );
}

export default HeaderLink;
