import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

export const MOVIES = "MOVIES";
export const SERIES = "SERIES";
export const MY_MOVIES_LIST = "MyMoviesList";
export const MY_SERIES_LIST = "MySeriesList";
export const MY_LIST = "My List";
export const CONTINUE_WATCHING = "Continue Watching";

export const MOVIES_CACHE_KEY = "all-movies-";
export const SERIES_CACHE_KEY = "all-series-";

export const config = {
  baseUrl: `${process.env.REACT_APP_API_BASE_URL}/api/v1`,
  webBaseUrl: process.env.REACT_APP_BASE_URL
};
export const YENEPAY_ICON_URL = "https://www.yenepay.com/images/logo.png";
export const TELEBIRR_ICON_URL = "/images/telebirr.png";
export const ETC_CREDIT_ICON_URL = "/images/ethio_telecom.jpg";
export const CBE_ICON_URL =
  "https://yenepayprod.blob.core.windows.net/images/CBE.png";
export const HELLOCASH_ICON_URL =
  "https://yenepayprod.blob.core.windows.net/images/hellocash.jpg";
export const DASHEN_ICON_URL =
  "https://yenepayprod.blob.core.windows.net/images/Dashen.jpg";
export const MBIRR_ICON_URL =
  "https://yenepayprod.blob.core.windows.net/images/mbirr.jpg";
export const WEGAGEN_ICON_URL =
  "https://yenepayprod.blob.core.windows.net/images/hellocash-wegagen.jpg";

export const STRIPE_ICON_URL = "/images/icons/stripe.png";
export const PAYPAL_ICON_URL =
  "https://icon-library.com/images/stripe-icon/stripe-icon-23.jpg";

export const paymentOptions = [
  {
    icon: CBE_ICON_URL,
  },
  {
    icon: HELLOCASH_ICON_URL,
  },
  {
    icon: DASHEN_ICON_URL,
  },
  {
    icon: MBIRR_ICON_URL,
  },
  {
    icon: WEGAGEN_ICON_URL,
  },
];

export const checkEmailorPhone = (data) => {
  if (data.includes("@")) {
    const re = /^[ ]*([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})[ ]*$/i;
    return re.test(data);
  }

  const re =
    /^[+]?(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*(\d{1,2})$/i;
  return re.test(data);
};

export const checkEmail = (data) => {
  const re = /^[ ]*([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})[ ]*$/i;
  return re.test(data);
};

export function getUUID() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    const piece = (Math.random() * 16) | 0;
    const elem = c === "x" ? piece : (piece & 0x3) | 0x8;
    return elem.toString(16);
  });
}

export const checkPassword = (password) => {
  const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#-_$%\^&\*])(?=.{8,})/;
  return re.test(password);
};

export const mayaAlert = withReactContent(Swal);

export const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    // marginRight: '-50%',
    alignItems: 'center',
    // transform: 'translate(-50%, -50%)',
    zIndex: '1000',
    padding: '0px',
    border: 'none',
    background: 'transparent',
    // height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    inset: 'auto'

  },
  overlay: {
    position: 'fixed',
    zIndex: 1020,
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    background: 'rgba(0, 0, 0, 0.75)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

export const customPaymentStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    // marginRight: '-50%',
    alignItems: 'center',
    // transform: 'translate(-50%, -50%)',
    zIndex: '1000',
    padding: '0px',
    border: 'none',
    width: 1000,
    background: "rgba(41, 47, 59, 1)",

    // height: '100%',
    overflow: 'auto',
    inset: 'auto'

  },
  overlay: {
    position: 'fixed',
    zIndex: 1020,
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    background: 'rgba(0, 0, 0, 0.75)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

export const customFullStyles = {
  content: {
    top: '0%',
    left: '0%',
    right: 'auto',
    bottom: 'auto',
    // marginRight: '-50%',
    alignItems: 'center',
    // transform: 'translate(-50%, -50%)',
    zIndex: '1000',
    padding: '0px',
    border: 'none',
    background: 'transparent',
    // height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    inset: 'auto'

  },
  overlay: {
    position: 'fixed',
    zIndex: 1020,
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    background: 'rgba(0, 0, 0, 0.75)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

};
