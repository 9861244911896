import React from 'react';
import { FaTrash, FaEye, FaEdit } from 'react-icons/fa';

const TableActionButtonGroup = ({
  detailAction, editAction, deleteAction, id,
}) => (
  <div className="text-xs inline-block">
    {detailAction != null
    && (
    <button
      data-toggle="tooltip"
      title="Detail"
      type="button"
      className="text-teal-600 hover:bg-teal-100 p-2 rounded-lg"
      onClick={() => { detailAction(id); }}
    >
      <span className="inline-block text-xs">
        <FaEye />
      </span>
    </button>
    )}
    {editAction != null
    && (
    <button
      data-toggle="tooltip"
      title="Edit"
      type="button"
      className="text-yellow-600  hover:bg-yellow-200 p-2 rounded-lg"
      onClick={() => { editAction(id); }}
    >
      <span className="inline-block text-xs">
        <FaEdit />
      </span>

    </button>
    )}
    {deleteAction != null
    && (
    <button
      data-toggle="tooltip"
      title="Delete"
      type="button"
      className="text-red-600 hover:bg-red-200 p-2 rounded-lg "
      onClick={() => { deleteAction(id); }}
    >
      <span className="inline-block text-xs">
        <FaTrash />
      </span>
    </button>
    )}
  </div>
);


export default TableActionButtonGroup;
