/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable array-callback-return */
/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/jsx-key */
import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { FiChevronRight } from "react-icons/fi";
import {
  FaRegTimesCircle,
  FaSearch,
  FaChevronRight,
} from "react-icons/fa";
import useDynamicRefs from "use-dynamic-refs";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import HeaderWrapper from "../components/Header/HeaderWrapper";
import NavBar from "../components/Header/NavBar";
import Logo from "../components/Header/Logo";
import PlayButton from "../components/Header/PlayButton";
import HeaderLink from "../components/Header/HeaderLink";
import AllSlidesWrapper from "../components/Movies/AllSlidesWrapper";
import SlideWrapper from "../components/Movies/SlideWrapper";
import SlideTitle from "../components/Movies/SlideTitle";
import AllCardsWrapper from "../components/Movies/AllCardsWrapper";
import CardWrapper from "../components/Movies/CardWrapper";
import CardImage from "../components/Movies/CardImage";
import FooterCompound from "../compounds/FooterCompound";
import GenreWrapper from "../components/Header/GenreWrapper";
import { StoreContext } from "../context/Store";
import PlayerCompound from "../compounds/PlayerCompound";
import CardNoTitle from "../components/Common/CardNoTitle";
import CardNoMovie from "../components/Common/CardNoMovie";
import Modal from 'react-modal';
import {
  chooseProfile,
  getSeries,
  showPlayer,
} from "../context/Actions";
import SearchButton from "../components/Header/SearchButton";

import ProfileCompound from "../compounds/ProfileCompound";
import CardBottom from "../components/Movies/CardBottom";
import {
  CONTINUE_WATCHING,

  customStyles,

  MY_LIST,
  SERIES,
  SERIES_CACHE_KEY,
} from "../constants";

import MayaDataService from "../services/MayaDataService";
import { movieMapper } from "../lib/utils";
import MovieCardCompounded from "../compounds/MovieCardCompounded";


const category = SERIES;

const noSeriesLoading = [...Array(1)].map((x, index) => (
  <SlideWrapper key={`${index}-no-series`}>
    <SlideTitle>
      <CardNoTitle />
    </SlideTitle>
    <AllCardsWrapper>
      {[...Array(5)].map((y, index) => (
        <CardNoMovie width="w-full" key={`${index}-no-series-loading`} />
      ))}
    </AllCardsWrapper>
  </SlideWrapper>
));

function SeriesPage() {
  const history = useHistory();
  const [state, dispatch] = useContext(StoreContext);
  const [viewGenre, setViewGenre] = useState(false);
  const [viewSearch, setViewSearch] = useState(false);
  const [genres, setGenres] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [browseList, setBrowseList] = useState([]);
  const [getRef, setRef] = useDynamicRefs();
  const [season, setSeason] = useState();
  const [scrollable, setScrollable] = useState(false);


  const [showModal, setShowModal] = useState(false);
  const [activeItem, setActiveItem] = useState();
  const [activeGenre, setActiveGenre] = useState();
  const [seriesMovies, setSeriesMovies] = useState([]);

  const [currentPageSeason, setCurrentPageSeason] = useState(1);
  const [totalPageSeason, setTotalPageSeason] = useState(0);
  const [series, setSeries] = useState([]);
  const [seasonLoading, setSeasonLoading] = useState(false);

  const loadMovies = (genre) => {
      getSeries(
        state,
        dispatch,
        `${SERIES_CACHE_KEY}${genre}`,
        { genreId: genre,IncludeEpisodes : true, pageSize: 5 },
        false,
        false,
        () => {
          setIsLoading(false);
        }
      );
  };
 
  useBottomScrollListener(() => {
    if (!isLoading && browseList.some((x) => !x.loaded)) {
      setIsLoading(true);
      loadMovies(browseList.find((x) => !x.loaded).id);
    }
  });

  const loadBrowsing = () => {

      const list = [];

      if (state.inProgressMovies.filter(x=> x.isSeries).length > 0) {
        list.push({
          title: CONTINUE_WATCHING,
          genre: CONTINUE_WATCHING,
          data: state.inProgressMovies.filter(x=> x.isSeries).slice(-5),
          filterKey: CONTINUE_WATCHING,
        });
      }

      if (state.mySeriesList.length > 0) {
        list.push({
          title: "My List",
          genre: "My List",
          filterKey: "My List",
          data: state.mySeriesList.slice(-5),
        });
      }
      const browsingList = state.listOfGenreOnBrowseSeries.map((x, index) => ({
        id: x.id,
        name: x.name.toLowerCase(),
        loaded: index === 0,
      }));

      Object.keys(state.cached)
        .filter((x) => x.includes(SERIES_CACHE_KEY) && !x.includes("-paging"))
        .map((key) => {
          list.push({
            title: state.listOfGenreOnBrowseSeries.find(
              (x) => x.id === key.replace(SERIES_CACHE_KEY, "")
            ).name,
            genre: key.replace(SERIES_CACHE_KEY, "").toLowerCase(),
            data: state.cached[key],
            id: key.replace(SERIES_CACHE_KEY, ""),
            filterKey: state.listOfGenreOnBrowseSeries.find(
              (x) => x.id === key.replace(SERIES_CACHE_KEY, "")
            ).name,
          });
          browsingList.find(
            (x) => `series-${x.id}` === key.replace("all-", "").toLowerCase()
          ).loaded = true;
        });
      setSeries(list);
      setBrowseList(browsingList);
    
  };

  const onSeasonSelect = (seasonId, withPlayBack) => {
    setSeason(seasonId);
    setSeasonLoading(true);
    MayaDataService.getAllMovies({ seasonId, pageSize: 10 }).then((result) => {
      var data =  movieMapper(result.data, state).sort(function (a, b) {
        return a.episodeNumber - b.episodeNumber;
      });
      setSeriesMovies(data
      );
      setCurrentPageSeason(result.currentPage);
      setTotalPageSeason(result.to);
      setSeasonLoading(false);
      if(withPlayBack)
        showPlayer(state, dispatch, data[0]);
    });
  };

  const goToSection = (filterKey, id) => {
    if(filterKey === MY_LIST)
    {
      history.push('mylist');
    }
    else if(filterKey !== CONTINUE_WATCHING)
    {
      history.push(`${category.toLowerCase()}/${id}`);
    }
  
  }

  useEffect(() => {
    if (!state.selectedProfile) {
      history.push('/browse')
    }
  }, [state.selectedProfile]);
 
  useEffect(() => {
    if (state.selectedProfile) {
      loadBrowsing();
    }
  }, [state.cached, state.selectedProfile, state.inProgressMovies, state.lookups]);
  useEffect(() => {
    setGenres(state.lookups.allGenres.filter((x) => x.isActive && !x.isCustom));
    if (state.selectedProfile) {
      window.scrollTo(0, 0);
    }
  }, [state.lookups, state.selectedProfile]);


  useEffect(() => {
    if (state.listOfGenreOnBrowseSeries.length > 0) {
      loadMovies(state.listOfGenreOnBrowseSeries[0].id);
      loadBrowsing();
    }
  }, [state.listOfGenreOnBrowseSeries, state.selectedProfile,]);

  useEffect(() => {
    loadBrowsing();
  }, []);

  return (
    <>
      {viewGenre && (
        <GenreWrapper>
          <FaRegTimesCircle
            onClick={() => setViewGenre(false)}
            className="inline-block w-12 text-2xl float-right text-white hover:text-gray-300 cursor-pointer"
          />
          <div
            className="text-center w-full mt-10 overflow-y-auto  overflow-x-hidden"
            style={{ height: "80vh" }}
          >
            {genres
              .filter((x) => x.forSeries)
              .map((x) => (
                <div className="text-2xl text-gray-300 my-6 hover:text-white cursor-pointer capitalize">
                  <p
                    className="   transform hover:scale-150  duration-1000 ease-out capitalize inline-block"
                    onClick={() =>
                      history.push(`${category.toLowerCase()}/${x.id}`)
                    }
                  >
                    {x.name}
                  </p>
                </div>
              ))}
          </div>
        </GenreWrapper>
      )}

      {viewSearch && (
        <GenreWrapper>
          <FaRegTimesCircle
            onClick={() => setViewSearch(false)}
            className="inline-block w-12 text-2xl float-right text-white hover:text-gray-300 cursor-pointer"
          />
          <div className="text-center w-full mt-10 overflow-hidden">
            <div className="text-3xl text-gray-300 my-6 hover:text-white cursor-pointer capitalize md:mx-40 mx-2">
              <SearchButton
                term=""
                callBack={(e) => {
                  if (e !== "") history.push(`search/${e}`);
                }}
              />
            </div>
          </div>
        </GenreWrapper>
      )}
      <NavBar className="navbar-browse w-full justify-center">
        <Logo history={history} state={state} dispatch={dispatch} />
        <div className="p-4 flex">
          <HeaderLink
            className="header-link text-gray-300 transform hover:text-white delay-200 duration-200"
            onClick={() => history.push('browse')}
          >
            Movies
          </HeaderLink>
          <HeaderLink
            className="header-link-bold"
            onClick={() => history.push('series')}
          >
            Series
          </HeaderLink>
          <HeaderLink
            className="header-link text-gray-300 transform hover:text-white delay-200 duration-200"
            onClick={() => history.push("mylist")}
          >
            My List
          </HeaderLink>

          <HeaderLink
            className="header-link text-gray-300 transform hover:text-white delay-200 duration-200"
            onClick={() => setViewSearch(true)}
          >
            <FaSearch className="inline-block -mt-1 md:ml-3  transform hover:scale-110 font-semibold  duration-1000 ease-out" />
          </HeaderLink>

          <ProfileCompound history={history} />
        </div>
      </NavBar>
      <HeaderWrapper
        video
        className="header-wrapper-browse relative"
        state={state}
        dispatch={dispatch}
        category={category}
      />

      <>
        {series.length > 0 && (
            <div className="main-container -mt-52 md:mt-0">
            <AllSlidesWrapper>
              <div className="slide-wrapper mb-10">
                <SlideTitle>
                  <div
                    className=" group z-40 text-2xl md:text-3xl capitalize ml-1 flex items-center"
                    onClick={() => setViewGenre(!viewGenre)}
                  >
                    <span>Series</span>
                    <span className="text-blue-500 font-medium">
                      <button className="ml-2 bg-blue-900 text-white opacity-75 p-2 text-sm focus:outline-none z-50 group-hover:bg-blue-500 delay-200 duration-200" type="button">
                        Select Genre
                        <FaChevronRight className="inline-block w-4" />
                      </button>
                    </span>
                  </div>
                </SlideTitle>
              </div>
              {series.map((slideItem, index) => (
                <SlideWrapper key={index}>
                  <SlideTitle>
                    <span
                      className="z-40 text-lg md:text-xl capitalize ml-1"
                      onClick={() => goToSection(slideItem.filterKey, slideItem.id)}
                    >
                      {slideItem.title}{" "}
                      {slideItem.filterKey !== CONTINUE_WATCHING && (
                        <span className="text-blue-500 font-medium transform group-hover:text-blue-400 delay-200 duration-200 ">
                          {" "}
                          <span className="text-xs ml-1">
                            Explore All
                          </span>{" "}
                          <FiChevronRight className="inline-block text-sm mt-1" />{" "}
                        </span>
                      )}
                    </span>
                  </SlideTitle>
                  <AllCardsWrapper>
                    {slideItem.data.map((cardItem, i) => (
                      <CardWrapper key={i}>
                        <CardImage
                          onClick={() => {
                            if(cardItem.category === 2)
                              showPlayer(state, dispatch, cardItem);
                           
                            setActiveGenre(
                              slideItem.filterKey
                                ? slideItem.filterKey
                                : cardItem.genre
                            );
                            if (cardItem.seasons.length > 0)
                              onSeasonSelect(cardItem.seasons[0]?.id, true);
                            setScrollable(true);
                          }}
                          src={cardItem.thumbnail}
                        />
                        <CardBottom
                          title={cardItem.title}
                          year={cardItem.year}
                          genre={cardItem.genres.filter(gen => !gen.isCustom).slice(0, 3).map(gen => gen.name).join(', ')}
                          rating={cardItem.rating}
                          onClick={() => {
                            setActiveItem(cardItem);
                            setShowModal(true);
                            setActiveGenre(
                              slideItem.filterKey
                                ? slideItem.filterKey
                                : cardItem.genre
                            );
                            if (cardItem.seasons.length > 0)
                              onSeasonSelect(cardItem.seasons[0]?.id);
                            setScrollable(true);
                          }}
                        />
                      </CardWrapper>
                    ))}
                  </AllCardsWrapper>
                
                </SlideWrapper>
              ))}
            </AllSlidesWrapper>
          </div>
        )}
        {series.length === 0 && (
          <div>
            <AllSlidesWrapper>
              {noSeriesLoading}
            </AllSlidesWrapper>
          </div>
        )}

        {isLoading && (
          <div className="px-10 -mt-10">
            {noSeriesLoading}
          </div>
        )}
      </>
      <Modal
        isOpen={showModal}
        style={customStyles}
        contentLabel="Movie Modal"
        className={activeGenre === CONTINUE_WATCHING ? "flex" : "h-full"}
        ariaHideApp={false}
        onRequestClose={() => setShowModal(false)}
        shouldCloseOnOverlayClick
      >
        <MovieCardCompounded
          currentItem={activeItem}
          state={state}
          dispatch={dispatch}
          onClose={() => setShowModal(false)}
          isSeries={activeGenre !== CONTINUE_WATCHING}
          showAddToList={activeGenre !== CONTINUE_WATCHING}
        />

      </Modal>

  
      <PlayerCompound history={history} />
      <FooterCompound />
    </>
  );
}

export default SeriesPage;
