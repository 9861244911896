import React, { useContext, useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import { FaChevronLeft, FaVideoSlash } from "react-icons/fa";
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import NavBar from "../components/Header/NavBar";
import Logo from "../components/Header/Logo";
import AllSlidesWrapper from "../components/Movies/AllSlidesWrapper";
import FooterCompound from "../compounds/FooterCompound";
import NoContent from "../components/Common/NoContent";
import { StoreContext } from '../context/Store';
import SlideWrapper from "../components/Movies/SlideWrapper";
import AllCardsWrapper from "../components/Movies/AllCardsWrapper";
import CardImage from "../components/Movies/CardImage";
import CardWrapper from "../components/Movies/CardWrapper";
import PlayerCompound from "../compounds/PlayerCompound";
import { getMyList, getMySeriesList, showPlayer } from "../context/Actions";
import LoadingIcon from "../components/Common/LoadingIcon";
import ProfileCompound from "../compounds/ProfileCompound";
import CardBottom from "../components/Movies/CardBottom";
import { customStyles, MOVIES, MY_LIST, MY_MOVIES_LIST, MY_SERIES_LIST, SERIES } from "../constants";
import { SET_PLAYER } from "../context/ActionTypes";
import Modal from 'react-modal';
import MovieCardCompounded from "../compounds/MovieCardCompounded";
import MayaDataService from "../services/MayaDataService";
import { movieMapper } from "../lib/utils";

function MyList() {
  const history = useHistory();
  const [state, dispatch] = useContext(StoreContext);
  const [selectedMovie,setSelectedMovie] = useState();
  const [loadingMode, setLoadingMode] = useState(false);
  const [forceLoad, setForceLoad] = useState(false);

  const [loadingModeSeries, setLoadingModeSeries] = useState(false);
  const [forceLoadSeries, setForceLoadSeries] = useState(false);

  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageSeries, setCurrentPageSeries] = useState(1);
  const [isLoadingSeries, setIsLoadingSeries] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [category, setCategory] = useState(MOVIES);
  const myMovies = MY_MOVIES_LIST;
  const mySeries = MY_SERIES_LIST;
  useBottomScrollListener(() => {
    if(category === MOVIES)
      if (!isLoading && currentPage <= state.cached[`${myMovies}-paging`]?.totalPages) {
        setLoadingMode(true);
        setIsLoading(true);
        setCurrentPage(currentPage + 1);
      }
   else if(category === SERIES)
    if (!isLoadingSeries && currentPageSeries <= state.cached[`${mySeries}-paging`]?.totalPages) {
      setLoadingMode(true);
      setIsLoadingSeries(true);
      setCurrentPageSeries(currentPage + 1);
    }
  });

  const onSeasonSelect = (seasonId, withPlayBack) => {
    MayaDataService.getAllMovies({ seasonId, pageSize: 10 }).then((result) => {
      var data =  movieMapper(result.data, state).sort(function (a, b) {
        return a.episodeNumber - b.episodeNumber;
      });
      if(withPlayBack)
        showPlayer(state, dispatch, data[0]);
    });
  };
  
  useEffect(() => {
    if (!state.cached[`${myMovies}-paging`] || currentPage <= state.cached[`${myMovies}-paging`].totalPages) {
      getMyList(state, dispatch, myMovies, { currentPage, pageSize }, forceLoad, loadingMode, () => {
      setIsLoading(false);
      setLoadingMode(true);
      setForceLoad(false);
    });
    } 

else setIsLoading(false);
  }, [currentPage]);


  useEffect(() => {
    if (!state.cached[`${mySeries}-paging`] || currentPageSeries <= state.cached[`${mySeries}-paging`].totalPages) {
      getMySeriesList(state, dispatch, mySeries, { currentPage: currentPageSeries, pageSize }, forceLoadSeries, loadingModeSeries, () => {
      setIsLoadingSeries(false);
      setLoadingModeSeries(true);
      setForceLoadSeries(false);
    });
} 
else setIsLoadingSeries(false);
  }, [currentPageSeries]);


  useEffect(() => {
    dispatch({
      type: SET_PLAYER,
      payload: {
        playerOn: false,
      }});
      if(category === MOVIES)
      {
        setCurrentPage(state.cached[`${myMovies}-paging`] ? state.cached[`${myMovies}-paging`].currentPage + 1 : 1);
          if (state.cached[`${myMovies}-paging`] && state.cached[`${myMovies}-paging`].currentPage > 1) {
            setLoadingMode(true);
          }
      }
      else if(category === SERIES)
      {
          setCurrentPageSeries(state.cached[`${mySeries}-paging`] ? state.cached[`${mySeries}-paging`].currentPage + 1 : 1);
          if (state.cached[`${mySeries}-paging`] && state.cached[`${mySeries}-paging`].currentPage > 1) {
            setIsLoadingSeries(true);
          }
      }
    window.scrollTo(0, 0);
  }, [category]);
  
  useEffect(() => {
    if (!state.selectedProfile) {
      history.push('/browse')
    }
  }, [state.selectedProfile]);
  return (
    <>
      <NavBar className="navbar-browse w-full">
      <Logo history={history}  state={state} dispatch={dispatch} />
        <ProfileCompound history={history} />
      </NavBar>
      <AllSlidesWrapper className="mt-0 mx-10 pt-16 min-h-screen">
   <div>

   <div className="mt-4">
        <span
          className="text-gray-500 text-lg capitalize cursor-pointer hover:text-white"
          onClick={() => history.goBack()}
        >
          <FaChevronLeft className="inline-block -mt-1" />
          {' '}
          Back
        </span>
        
      <div className="mt-2">
        <span className={ `${category === MOVIES ? "text-white border-b border-white": "text-gray-400 hover:text-white hover:border-white border-b border-transparent transition ease-in-out duration-700"} capitalize font-semibold text-lg cursor-pointer pb-1`} onClick={() => setCategory(MOVIES)}>
            My Movies
          </span>
          <span className={ `${category === SERIES ? "text-white border-b border-white": "text-gray-400 hover:text-white hover:border-white border-b border-transparent transition ease-in-out duration-700"} capitalize font-semibold text-lg cursor-pointer ml-4  pb-1`} onClick={() => setCategory(SERIES)}>
            My Series
          </span>
     </div>
   </div>

      {state.isAddingToMyList && (
   
        <NoContent>
          <div className="flex justify-center">
            <LoadingIcon />
            Fetching
          </div>
        </NoContent>
 
        )}
      {(category === MOVIES ? state.myList.length === 0  : state.mySeriesList.length === 0) && !state.isAddingToMyList  && (
     
       <NoContent>
          <div> <span className="text-4xl font-semibold">No items found</span></div>
          <div> <span className="text-sm text-gray-500">You haven't added anything to your list</span></div>
          <br />

          <div
            className="text-gray-500 text-lg capitalize cursor-pointer hover:text-white mt-4"
            onClick={() => history.goBack()}
          >
            <FaChevronLeft className="-mt-1 inline-block mx-auto" />
            Back to Home
          </div>
        </NoContent>
     
        )}
         
      {state.myList.length > 0 && category === MOVIES && (
          <SlideWrapper>         
            <AllCardsWrapper>
              {state.myList.map((item) => (
                <CardWrapper key={item.id}>
                  <CardImage
                    onClick={() => {
                      showPlayer(state, dispatch, item);
                }}
                    src={item.thumbnail}
                  />
                  <CardBottom 
                    title={item.title} 
                    year={item.year} 
                    genre={item.genres.filter(gen => !gen.isCustom).slice(0, 3).map(gen => gen.name).join(', ')}
                    rating={item.rating} 
                    onClick={() => {
                      setSelectedMovie(item)
                    }}/>
                </CardWrapper>
              ))}
                  {isLoading && (
                <div className="flex justify-center my-6">
                  <LoadingIcon />
                  Loading
                </div>
                )}
            </AllCardsWrapper>
          </SlideWrapper>
      
      )}

       {state.mySeriesList.length > 0 && category === SERIES  && (
          <SlideWrapper>         
            <AllCardsWrapper>
              {state.mySeriesList.map((item) => (
                <CardWrapper key={item.id}>
                  <CardImage
                    onClick={() => {
                      if(item.category === 2)
                       showPlayer(state, dispatch, item);
                      if (item.seasons.length > 0)
                       onSeasonSelect(item.seasons[0]?.id, true);
                    
                }}
                    src={item.thumbnail}
                  />
                  <CardBottom 
                    title={item.title} 
                    year={item.year} 
                    genre={item.genres.filter(gen => !gen.isCustom).slice(0, 3).map(gen => gen.name).join(', ')}
                    rating={item.rating} 
                    onClick={() => {
                      setSelectedMovie(item);
                    }}/>
                </CardWrapper>
              ))}
                  {isLoading && (
                <div className="flex justify-center my-6">
                  <LoadingIcon />
                  Loading
                </div>
                )}
            </AllCardsWrapper>
          </SlideWrapper>       
      )}
      </div>
      </AllSlidesWrapper>
    
        <Modal
          isOpen={selectedMovie !== undefined}
          style={customStyles}
          contentLabel="Movie Modal"
          className={category === MOVIES ? "flex" : "h-full"}
          ariaHideApp={false}
          onRequestClose={() => setSelectedMovie()}
          shouldCloseOnOverlayClick
        >
          <MovieCardCompounded
            currentItem={selectedMovie}
            state={state}
            dispatch={dispatch}
            onClose={() => setSelectedMovie()}
            isSeries={category === SERIES}
          />

        </Modal>
   
      <PlayerCompound history={history} />
      <FooterCompound />
    </>
  );
}

export default MyList;
