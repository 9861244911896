/* eslint-disable no-underscore-dangle */
/* eslint-disable react/button-has-type */
import React, {
  useContext, useEffect, useState, useRef
} from "react";

import { useParams } from 'react-router-dom';
import moment from 'moment';

import Vimeo from '@u-wave/react-vimeo';
import { StoreContext } from '../context/Store';
import LoadingIcon from "../components/Common/LoadingIcon";
import MayaDataService from "../services/MayaDataService";
import { mayaAlert } from "../constants";


function PlayerPage() {
  const { id, token, session, type } = useParams();
  const [state, dispatch] = useContext(StoreContext);
  const [loading, setLoading] = useState(false);
  const [start, setStart] = useState(0);
  const [isReady, setIsReady] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [movie, setMovie] = useState();


  const updateIntervalInMillSeconds = 120000;
  const [visible, setVisible] = useState(false);
  const [playerTime, setPlayerTime] = useState(0);
  const [lastSent, setLastSent] = useState();


  const player = useRef();

  const onUpdateTime = () => {
    if(playerTime)
    {
      updateTime(playerTime);
      setLastSent(moment().valueOf());
    }
  };

  const showControls = () => {
    if (!visible) {
      setVisible(true);
      setTimeout(() => {
        setVisible(false);
      }, 2500);
    }
  };
  

  const getMovie = () => {
    setLoading(true);
    MayaDataService.getMovieExternal(id, session, token).then((result) => {
      if (result.responseStatus === 1) {
        setMovie(result.data);
        if (result.data.currentTime) {
          setStart(moment.duration(result.data.currentTime)._milliseconds / 1000);
        }
        setIsReady(true);
        setLoading(false);
        showControls();

      } else {
        mayaAlert.fire({
          showConfirmButton: false,
          html:
  <div className="bg-black text-white text-center py-16 -mx-20 -my-10">

    <div className="text-lg mb-4">{result.error.message}</div>
    <div className="text-center my-2" />
  </div>
        });
      }
    }
    );
  };

  const updateTime = (time) => {
    MayaDataService.updateMovieStatusExternal({
      movieId: movie.id,
      sessionId: movie.sessionId ? movie?.sessionId : session,
      finished: time.percent >= 0.95,
      currentTime: moment().startOf('day')
        .seconds(time.seconds)
        .format('H:mm:ss')
    }, token).then((result) => {
      if (result.responseStatus !== 1) {
        mayaAlert.fire({
          showConfirmButton: false,
          html:
  <div className="bg-black text-white text-center py-16 -mx-20 -my-10">

    <div className="text-lg mb-4">{result.error.message}</div>
    <div className="text-center my-2" />
  </div>
        });

        // dispatch({
        //   type: SET_PLAYER,
        //   payload: {
        //     playerOn: false,
        //   }
        // });
        // dispatch({
        //   type: SET_CURRENT_MOVIE,
        //   payload: null
        // });
      }
    }

    );
  };

  useEffect(() => {
    getMovie();
  }, []);

  useEffect(() => {
    if (playerTime && (!lastSent || ((lastSent + updateIntervalInMillSeconds) < moment().valueOf())) && type !== 'Trailer') 
    onUpdateTime();
  }, [playerTime]);
  

  return (
    <>

      <div className="h-screen w-full bg-black">
        {loading && (

        <div className="flex justify-center">
          <LoadingIcon />
          Fetching

        </div>

        )}
        {!loading && isReady && (
            <div className=" ">
            <Vimeo
            ref={player}
            video={ type === 'Trailer' ? movie.trailerUrl : movie.movieUrl}
            responsive
            autoplay
            onTimeUpdate={(e) => setPlayerTime(e)}
            onEnd={() => onUpdateTime()}
            onSeeked={() => onUpdateTime()}
            onPause={() => onUpdateTime()}
            onPlay={() => onUpdateTime()}
            start={start}
            showByline
            controls
          />
         </div>
        )}
      </div>

    </>
  );
}

export default PlayerPage;
