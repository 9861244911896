import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { FaChevronLeft } from "react-icons/fa";
import HeaderWrapper from "../components/Header/HeaderWrapper";
import NavBar from "../components/Header/NavBar";
import Logo from "../components/Header/Logo";
import FooterCompound from "../compounds/FooterCompound";
import SignFormWrapper from "../components/SignForm/SignFormWrapper";
import SignFormBase from "../components/SignForm/SignFormBase";
import SignFormTitle from "../components/SignForm/SignFormTitle";
import SignFormInput from "../components/SignForm/SignFormInput";
import SignFormButton from "../components/SignForm/SignFormButton";
import SignFormText from "../components/SignForm/SignFormText";
import SignFormLink from "../components/SignForm/SignFormLink";
import SignFormError from "../components/SignForm/SignFormError";
import OverlayWrapper from "../components/Header/OverlayWrapper";
import { checkEmailorPhone, mayaAlert } from "../constants";
import OtpForm from "../components/SignForm/OtpForm";
import { firebase } from "../lib/firebase.prod";
import Firebase from "firebase";
import LoadingIcon from "../components/Common/LoadingIcon";
import MayaDataService from "../services/MayaDataService";
import PhoneNumberInput from "../components/SignForm/PhoneNumberInput";

function ForgotPasswordPage() {
  const history = useHistory();
  const { code, email } = useParams();
  const [username, setUsername] = useState(email ? email : '');
  const [otp, setOtp] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [verified, setVerified] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const IsInvalid = username === "" || !checkEmailorPhone(username);
  const IsInvalidChangePassword = password === "" || password !== confirmPassword;
  const IsInvalidOTP = otp === "" || otp?.length !== 6;
  const [error, setError] = useState("");
  const [userCode, setUserCode] = useState(code);
  const [loading, setLoading] = useState(false);
  const [isUserNameTypePhone, setUserNameType] = useState(true);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (username.includes("@")) submitEmailAuth();
    else checkAccount();
    // history.push("/browse");
  };

  const handlePhoneChange = (value) => {
    let countryCode = value.substring(0, 3);
    ///Remove zero from Ethiopian phone number after country code +251[0]911...
    if (countryCode === '251') {
      if (value[4] !== undefined && value[3] === '0') {
        var temp = value.substring(4, value.length);
        value = '251' + temp;
      }
    }
    setUsername(`+${value}`);
  }

  const changePassword = () => {
    if (password !== confirmPassword) {
      setError("Password doesn't match");
      return;
    }

    setLoading(true);
    let usrname = username;
    if (!username.includes("@") && !username.includes("+")) {
      usrname = `+${username}`;
    }
    MayaDataService.resetPassword(usrname, password, userCode).then((result) => {
      if (result.responseStatus === 1) {
        let timerInterval;
        mayaAlert.fire({
          title: 'Password reset completed',
          icon: 'success',
          html: 'You will be redirected to login in a second',
          timer: 2500,
          timerProgressBar: true,
          didOpen: () => {
            mayaAlert.showLoading();
          },
          willClose: () => {
            clearInterval(timerInterval);
            history.push("/signin");
          }
        });
      }
      else {
        setError(result.error.message);
        setLoading(false);
      }

    });
  };

  //STEP : 1
  const checkAccount = () => {
    //Checks if username exists
    setLoading(true);
    MayaDataService.forgotPassword(!username.includes("+") ? `+${username}` : username).then((result) => {
      if (result.responseStatus === 1) {
        submitPhoneNumberAuth();
      }
      else {
        setError(result.error.message);
        setLoading(false);
      }

    });
  }

  //STEP : 2
  const submitPhoneNumberAuth = () => {
    let phoneNo = username;
    if (username.indexOf("+") === -1) {
      phoneNo = `+${phoneNo}`;
    }
    configureFirebaseAuth();

    setLoading(true);
    const appVerifier = window.recaptchaVerifier;
    firebase
      .auth()
      .signInWithPhoneNumber(phoneNo, appVerifier)
      .then((confirmResult) => {
        window.confirmationResult = confirmResult;
        setOtpSent(true);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  //STEP : 3
  const verify = () => {
    setLoading(true);
    window.confirmationResult.confirm(otp).then(async (result) => {
      //const { user } = result;
      const token = await firebase.auth().currentUser.getIdToken(true);
      localStorage.setItem('token', token);
      getUserCode();
    }).catch((e) => {
      console.log(e);
      setLoading(false);
    });
  };

  //STEP : 4

  const getUserCode = () => {
    setLoading(true);
    MayaDataService.getUserCode(encodeURIComponent(username)).then((result) => {
      if (result.responseStatus === 1) {
        setUserCode(result.data);
        setVerified(true);
        localStorage.removeItem('token');
        setLoading(false);
      }
      else {
        setError(result.error.message);
        setLoading(false);
      }

    });
  }

  useEffect(() => {
    if (code) {
      setVerified(true);
    }

  }, []);

  const submitEmailAuth = () => {
    setLoading(true);
    MayaDataService.forgotPassword(username).then((result) => {
      if (result.responseStatus === 1) {
        mayaAlert.fire({
          icon: 'success',
          background: 'rgba(17, 24, 39, 1)',
          html: '<div class="text-white text-4xl my-4">Email Sent</div><div class="text-white tex-sm">Check your email</div>'
        });
        setLoading(false);
      }
      else {
        setError(result.error.message);
        setLoading(false);
      }

    });
  };

  const configureFirebaseAuth = () => {
    window.recaptchaVerifier = new Firebase.auth.RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback() { },
      }
    );
  };

  const handleSignInMethod = (event) => {
    event.preventDefault();
    setUserNameType(!isUserNameTypePhone);
    setUsername("");
    setPassword("");
  }
  //Avoids to go back to country code selection after a user enters phone (tab index sequence)
  var elems = document.getElementsByClassName("selected-flag");
  for (var i = 0; i < elems.length; i++) {
    elems[i].tabIndex = 10;
  }

  return (
    <>
      <HeaderWrapper className="header-wrapper-home">
        <OverlayWrapper>
          <NavBar className="navbar-signin">
            <Logo />
          </NavBar>
          <SignFormWrapper>
            {!otpSent && !verified && (
              <SignFormBase onSubmit={handleSubmit} method="POST">
                <SignFormTitle>Forgot Password</SignFormTitle>
                {error ? <SignFormError>{error}</SignFormError> : null}
                <div className="text-sm text-gray-500 mb-4 text-center">
                  Enter your email or phone number and we'll send you a link to
                  reset your password
                </div>
                <button type="button" className="userNameType" onClick={handleSignInMethod}> {isUserNameTypePhone ? <>Use Email</> : <>Use Phone</>} </button>

                {
                  isUserNameTypePhone ?
                    <PhoneNumberInput
                      username={username}
                      onChange={handlePhoneChange}
                    />
                    :
                    <SignFormInput
                      type="email"
                      placeholder="Enter Email Address"
                      value={username}
                      onChange={({ target }) => setUsername(target.value)}
                    />
                }
                {loading && (
                  <div className="flex justify-center my-4">
                    <LoadingIcon />
                  </div>
                )}

                {!loading && (
                  <SignFormButton disabled={IsInvalid} type="submit">
                    Submit
                  </SignFormButton>
                )}
              </SignFormBase>
            )}
            {otpSent && !verified && (
              <div>
                <SignFormTitle>Forgot Password</SignFormTitle>
                {error ? <SignFormError>{error}</SignFormError> : null}
                <div className="text-sm text-gray-500 mb-4 text-center">
                  Enter code sent to your phone number
                </div>
                <OtpForm setCode={setOtp} />

                {loading && (
                  <div className="flex justify-center my-4">
                    <LoadingIcon />
                  </div>
                )}

                {!loading && (
                  <SignFormButton
                    disabled={IsInvalidOTP}
                    onClick={() => verify()}
                  >
                    Submit
                  </SignFormButton>)}
              </div>
            )}

            {verified && (
              <SignFormBase onSubmit={handleSubmit} method="POST">
                <SignFormTitle>Enter New Password</SignFormTitle>
                <div className="text-sm text-gray-500 mb-4 text-center">
                  Enter new password for {username}
                </div>
                {error ? <SignFormError>{error}</SignFormError> : null}
                <SignFormInput
                  type="password"
                  placeholder="New Password"
                  value={password}
                  onChange={({ target }) => setPassword(target.value)}
                />
                <SignFormInput
                  type="password"
                  placeholder="Confirm Password"
                  value={confirmPassword}
                  onChange={({ target }) => setConfirmPassword(target.value)}
                />
                {loading && (
                  <div className="flex justify-center my-4">
                    <LoadingIcon />
                  </div>
                )}

                {!loading && (<SignFormButton
                  disabled={IsInvalidChangePassword}
                  onClick={() => changePassword()}
                  type="button"
                >
                  Submit
                </SignFormButton>)}
              </SignFormBase>
            )}

            <SignFormText>
              <SignFormLink to="/signin">
                <FaChevronLeft className="inline-block -mt-1" /> Back to login
              </SignFormLink>
            </SignFormText>
          </SignFormWrapper>
        </OverlayWrapper>
        <div id="recaptcha-container" />
      </HeaderWrapper>

      <FooterCompound />
    </>
  );
}

export default ForgotPasswordPage;
