/* eslint-disable prefer-promise-reject-errors */
import { config } from "../constants";
import { logout } from "../lib/utils";

const getHeaders = () => ({
  Accept: "application/json, text/plain",
  "Content-Type": "application/json;charset=UTF-8",
  Authorization: `Bearer ${
    localStorage.getItem("token") ? localStorage.getItem("token") : ""
  }`,
});

const auth = (username, password) =>
  fetch(`${config.baseUrl}/user/authenticate`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({ username, password }),
  })
    .then((response) => {
      if (!response.ok) {
        return catchError(response);
      }
      return response.json();
    })
    .catch((error) => ({
      error: {
        message: "Unknown error",
      },
    }));

const catchError = (error) => {
  console.log(error);
  if (error.status === 401) {
    logout();
  } else if (error.status === 400) {
    return error.json();
  }
  return {
    error: {
      message: "Unknown error",
    },
  };
};

const register = (firstName, lastName, username, password) =>
  fetch(`${config.baseUrl}/user/register`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({
      firstName,
      lastName,
      username,
      password,
      address: {
        email: username.includes("@") ? username : null,
        telephoneMob: !username.includes("@") ? username : null,
      },
    }),
  })
    .then((response) => {
      if (!response.ok) {
        return catchError(response);
      }
      return response.json();
    })
    .catch((error) => ({
      error: {
        message: "Unknown error",
      },
    }));

const changePassword = (oldPassword, newPassword) =>
  fetch(`${config.baseUrl}/user/changePassword`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({ oldPassword, newPassword }),
  })
    .then((response) => {
      if (!response.ok) {
        return catchError(response);
      }
      return response.json();
    })
    .catch((error) => ({
      error: {
        message: "Unknown error",
      },
    }));

const updateProfile = (data) =>
  fetch(`${config.baseUrl}/user/updateProfile`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(data),
  })
    .then((response) => {
      if (!response.ok) {
        return catchError(response);
      }
      return response.json();
    })
    .catch((error) => ({
      error: {
        message: "Unknown error",
      },
    }));

const sendEmailVerification = () =>
  fetch(`${config.baseUrl}/user/resendEmailVerification`, {
    method: "GET",
    headers: getHeaders(),
  })
    .then((response) => {
      if (!response.ok) {
        return catchError(response);
      }
      return response.json();
    })
    .catch((error) => ({
      error: {
        message: "Unknown error",
      },
    }));

const sendPhoneVerification = () =>
  fetch(`${config.baseUrl}/user/resendPhoneVerification`, {
    method: "GET",
    headers: getHeaders(),
  })
    .then((response) => {
      if (!response.ok) {
        return catchError(response);
      }
      return response.json();
    })
    .catch((error) => ({
      error: {
        message: "Unknown error",
      },
    }));

const payWithYenePay = (data, path = "/account/manage") =>
  fetch(`${config.baseUrl}/pwa/pay/yenepay?returnUrl=${config.webBaseUrl}${path}`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(data),
  })
    .then((response) => {
      if (!response.ok) {
        return catchError(response);
      }
      return response.json();
    })
    .catch((error) => ({
      error: {
        message: "Unknown error",
      },
    }));

const stripeCheckout = (data) =>
  fetch(`${config.baseUrl}/pwa/stripeCheckout`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(data),
  })
    .then((response) => {
      if (!response.ok) {
        return catchError(response);
      }
      return response.json();
    })
    .catch((error) => ({
      error: {
        message: "Unknown error",
      },
    }));

const telebirrCheckout = (data, path = "/account/manage") =>
  fetch(`${config.baseUrl}/pwa/pay/telebirr?returnUrl=${config.webBaseUrl}${path}`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(data),
  })
    .then((response) => {
      if (!response.ok) {
        return catchError(response);
      }
      return response.json();
    })
    .catch((error) => ({
      error: {
        message: "Unknown error",
      },
    }));

const etcCreditCheckout = (data) =>
  fetch(`${config.baseUrl}/pwa/pay/airtime`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(data),
  })
    .then((response) => {
      if (!response.ok) {
        return catchError(response);
      }
      return response.json();
    })
    .catch((error) => ({
      error: {
        message: "Unknown error",
      },
    }));

const paypalCheckout = (data) =>
  fetch(`${config.baseUrl}/pwa/paypalCheckout`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(data),
  }).catch((error) => ({
    error: {
      message: "Unknown error",
    },
  }));

const paypalCheckoutComplete = (orderId) =>
  fetch(`${config.baseUrl}/pwa/paypalCheckoutComplete/${orderId}`, {
    method: "POST",
    headers: getHeaders(),
  }).catch((error) => ({
    error: {
      message: "Unknown error",
    },
  }));

const getAllMovies = (data) =>
  fetch(`${config.baseUrl}/pwa/movies?${new URLSearchParams({ ...data })}`, {
    headers: getHeaders(),
  })
    .then((response) => {
      if (!response.ok) {
        return catchError(response);
      }
      return response.json();
    })
    .catch((error) => ({
      error: {
        message: "Unknown error",
      },
    }));

const getAllSeries = (data) =>
  fetch(`${config.baseUrl}/pwa/series?${new URLSearchParams({ ...data })}`, {
    headers: getHeaders(),
  })
    .then((response) => {
      if (!response.ok) {
        return catchError(response);
      }
      return response.json();
    })
    .catch((error) => ({
      error: {
        message: "Unknown error",
      },
    }));

const getMovie = (id, sessionId) =>
  fetch(`${config.baseUrl}/pwa/movies/${id}/${sessionId}`, {
    headers: getHeaders(),
  })
    .then((response) => {
      if (!response.ok) {
        return catchError(response);
      }
      return response.json();
    })
    .catch((error) => ({
      error: {
        message: "Unknown error",
      },
    }));

const getInprogressMovie = (id) =>
  fetch(`${config.baseUrl}/pwa/inprogressmovies`, {
    headers: getHeaders(),
  })
    .then((response) => {
      if (!response.ok) {
        return catchError(response);
      }
      return response.json();
    })
    .catch((error) => ({
      error: {
        message: "Unknown error",
      },
    }));

const getMyList = (data) =>
  fetch(`${config.baseUrl}/pwa/wishlists/movie?${new URLSearchParams(data)}`, {
    headers: getHeaders(),
  })
    .then((response) => {
      if (!response.ok) {
        return catchError(response);
      }
      return response.json();
    })
    .catch((error) => ({
      error: {
        message: "Unknown error",
      },
    }));

const getMySeriesList = (data) =>
  fetch(`${config.baseUrl}/pwa/wishlists/series?${new URLSearchParams(data)}`, {
    headers: getHeaders(),
  })
    .then((response) => {
      if (!response.ok) {
        return catchError(response);
      }
      return response.json();
    })
    .catch((error) => ({
      error: {
        message: "Unknown error",
      },
    }));

const addToMyList = (id) =>
  fetch(`${config.baseUrl}/pwa/wishlist/movie/${id}`, {
    method: "POST",
    headers: getHeaders(),
  })
    .then((response) => {
      if (!response.ok) {
        return catchError(response);
      }
      return response.json();
    })
    .catch((error) => ({
      error: {
        message: "Unknown error",
      },
    }));

const removeFromMyList = (id) =>
  fetch(`${config.baseUrl}/pwa/wishlist/movie/${id}`, {
    method: "DELETE",
    headers: getHeaders(),
  }).catch((error) => ({
    error: {
      message: "Unknown error",
    },
  }));

const addToMySeriesList = (id) =>
  fetch(`${config.baseUrl}/pwa/wishlist/series/${id}`, {
    method: "POST",
    headers: getHeaders(),
  })
    .then((response) => {
      if (!response.ok) {
        return catchError(response);
      }
      return response.json();
    })
    .catch((error) => ({
      error: {
        message: "Unknown error",
      },
    }));

const removeFromMySeriesList = (id) =>
  fetch(`${config.baseUrl}/pwa/wishlist/series/${id}`, {
    method: "DELETE",
    headers: getHeaders(),
  }).catch((error) => ({
    error: {
      message: "Unknown error",
    },
  }));

const movieFinished = (id) =>
  fetch(`${config.baseUrl}/pwa/finishmovie?${id}`, {
    method: "POST",
    headers: getHeaders(),
  }).catch((error) => ({
    error: {
      message: "Unknown error",
    },
  }));

const updateMovieStatus = (data) =>
  fetch(`${config.baseUrl}/pwa/updateMovieStatus/${data.sessionId}`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(data),
  })
    .then((response) => {
      if (!response.ok) {
        return catchError(response);
      }
      return response.json();
    })
    .catch((error) => ({
      error: {
        message: "Unknown error",
      },
    }));

const renewToken = (profileId) =>
  fetch(`${config.baseUrl}/user/renewToken`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({ profileId }),
  })
    .then((response) => {
      if (!response.ok) {
        return catchError(response);
      }
      return response.json();
    })
    .catch((error) => ({
      error: {
        message: "Unknown error",
      },
    }));

const getLookups = () =>
  fetch(`${config.baseUrl}/common/lookup`)
    .then((response) => {
      if (!response.ok) {
        return catchError(response);
      }
      return response.json();
    })
    .catch((error) => ({
      error: {
        message: "Unknown error",
      },
    }));

const giftCodes = (data) =>
  fetch(`${config.baseUrl}/pwa/giftcodes?${new URLSearchParams(data)}`, {
    method: "GET",
    headers: getHeaders(),
  })
    .then((response) => {
      if (!response.ok) {
        return catchError(response);
      }
      return response.json();
    })
    .catch((error) => ({
      error: {
        message: "Unknown error",
      },
    }));

const redeem = (code) =>
  fetch(`${config.baseUrl}/pwa/redeem/${code}`, {
    method: "POST",
    headers: getHeaders(),
  })
    .then((response) => {
      if (!response.ok) {
        return catchError(response);
      }
      return response.json();
    })
    .catch((error) => ({
      error: {
        message: "Unknown error",
      },
    }));

const getMovieExternal = (id, sessionId, token) =>
  fetch(`${config.baseUrl}/pwa/movies/${id}/${sessionId}`, {
    headers: {
      Accept: "application/json, text/plain",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      if (!response.ok) {
        return catchError(response);
      }
      return response.json();
    })
    .catch((error) => ({
      error: {
        message: "Unknown error",
      },
    }));

const updateMovieStatusExternal = (data, token) =>
  fetch(`${config.baseUrl}/pwa/updateMovieStatus/${data.sessionId}`, {
    method: "POST",
    headers: {
      Accept: "application/json, text/plain",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      if (!response.ok) {
        return catchError(response);
      }
      return response.json();
    })
    .catch((error) => ({
      error: {
        message: "Unknown error",
      },
    }));

const subscriptions = (data) =>
  fetch(`${config.baseUrl}/pwa/mysubscriptions`, {
    method: "GET",
    headers: getHeaders(),
  })
    .then((response) => {
      if (!response.ok) {
        return catchError(response);
      }
      return response.json();
    })
    .catch((error) => ({
      error: {
        message: "Unknown error",
      },
    }));
const deleteAccount = (id) =>
  fetch(`${config.baseUrl}/user/deleteAccount`, {
    method: "DELETE",
    headers: getHeaders(),
  }).catch((error) => ({
    error: {
      message: "Unknown error",
    },
  }));

const forgotPassword = (username) =>
  fetch(`${config.baseUrl}/user/forgotPassword`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({ username }),
  })
    .then((response) => {
      if (!response.ok) {
        return catchError(response);
      }
      return response.json();
    })
    .catch((error) => ({
      error: {
        message: "Unknown error",
      },
    }));

const getUserCode = (username) =>
  fetch(`${config.baseUrl}/common/getCode?username=${username}`, {
    method: "GET",
    headers: getHeaders(),
  })
    .then((response) => {
      if (!response.ok) {
        return catchError(response);
      }
      return response.json();
    })
    .catch((error) => ({
      error: {
        message: "Unknown error",
      },
    }));

const verifyPhone = (username, code) =>
  fetch(
    `${config.baseUrl}/common/verifyPhone?username=${username}&code=${code}`,
    {
      method: "GET",
      headers: getHeaders(),
    }
  )
    .then((response) => {
      if (!response.ok) {
        return catchError(response);
      }
      return response.json();
    })
    .catch((error) => ({
      error: {
        message: "Unknown error",
      },
    }));

const verifyEmail = (username, code) =>
  fetch(
    `${config.baseUrl}/user/verifiyEmail?username=${username}&code=${code}`,
    {
      method: "GET",
      headers: getHeaders(),
    }
  )
    .then((response) => {
      if (!response.ok) {
        return catchError(response);
      }
      return response.json();
    })
    .catch((error) => ({
      error: {
        message: "Unknown error",
      },
    }));

const resetPassword = (username, password, code) =>
  fetch(`${config.baseUrl}/user/resetPassword`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({ username, password, resetPasswordCode: code }),
  })
    .then((response) => {
      if (!response.ok) {
        return catchError(response);
      }
      return response.json();
    })
    .catch((error) => ({
      error: {
        message: "Unknown error",
      },
    }));

const sendContactMessage = (fullName, email, message) =>
  fetch(`${config.baseUrl}/user/contactMessage`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({ fullName, email, message }),
  })
    .then((response) => {
      if (!response.ok) {
        return catchError(response);
      }
      return response.json();
    })
    .catch((error) => ({
      error: {
        message: "Unknown error",
      },
    }));

const removeProfile = (id) =>
  fetch(`${config.baseUrl}/User/DeleteProfile/${id}`, {
    method: "DELETE",
    headers: getHeaders(),
  }).catch((error) => ({
    error: {
      message: "Unknown error",
    },
  }));

export default {
  auth,
  register,
  getAllMovies,
  getAllSeries,
  getMovie,
  getMyList,
  getMySeriesList,
  addToMyList,
  addToMySeriesList,
  removeFromMyList,
  removeFromMySeriesList,
  movieFinished,
  updateMovieStatus,
  getLookups,
  changePassword,
  payWithYenePay,
  stripeCheckout,
  updateProfile,
  renewToken,
  paypalCheckout,
  paypalCheckoutComplete,
  getInprogressMovie,
  giftCodes,
  redeem,
  getMovieExternal,
  updateMovieStatusExternal,
  subscriptions,
  deleteAccount,
  forgotPassword,
  getUserCode,
  resetPassword,
  sendEmailVerification,
  sendPhoneVerification,
  verifyPhone,
  verifyEmail,
  sendContactMessage,
  removeProfile,
  telebirrCheckout,
  etcCreditCheckout
};
