import React from "react";
import "./HeaderStyles.css";
import { FaMinus } from 'react-icons/fa';

function RemoveFromListButton({ children, ...restProps }) {
  return (
    <button className="hover:text-red-400 px-2 focus:outline-none text-red-500 border border-1 py-3 px-4 rounded border-red-500" type="button" {...restProps}>
      <FaMinus className="inline-block mr-1 text-xl -mt-1" />
      {children}
    </button>
  );
}

export default RemoveFromListButton;
