/* eslint-disable react/jsx-key */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable radix */
import React, { useEffect, useState } from "react";
import CurrencyFormat from 'react-currency-format';
import { FaCcPaypal, FaChevronRight, FaRegTimesCircle } from "react-icons/fa";
import { mayaAlert, paymentOptions, YENEPAY_ICON_URL, ETC_CREDIT_ICON_URL, TELEBIRR_ICON_URL } from "../../constants";
import StripeForm from "./StripeForm";
import PayPalForm from "./PayPalForm";
import { Elements } from "@stripe/react-stripe-js";
import {loadStripe} from '@stripe/stripe-js';
import YenePayForm from "./YenePayForm";
import Switch from "react-switch";
import ProfileFormInput from "./ProfileFormInput";
import ProfileFormLabel from "./ProfileFormLabel";
import MayaDataService from "../../services/MayaDataService";
import { SET_TOKEN } from "../../context/ActionTypes";
import { setSession } from "../../lib/utils";
import TelebirrForm from "./TelebirrForm";
import ETCCreditForm from "./ETCCreditForm";
 // Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(`${process.env.REACT_APP_API_STRIPE_PUBLISHABLE_KEY}`);


function GiftCardForm({
  profileId, dispatch, lookups, callBack, account,history
}) {

  const [paymentOption, setPaymentOption] = useState('telebirr');
  const [quantity, setQuantity] = useState(1);
  const [selectedPackage, setSelectedPackage] = useState();
  const [selectedWizard, setSelectedWizard] = useState('package');
  const [proceedPayment, setProceedPayment] = useState(false);
  const [isPriceUSD, setIsPriceUSD] = useState(false);

  const successCallback = () => { 
    MayaDataService.renewToken(profileId).then((result) => {
      localStorage.setItem('token', result.data.token);
      localStorage.setItem('user', JSON.stringify(result.data.user));
      dispatch({ type: SET_TOKEN, payload: result.data });
      setSession(dispatch);
      callBack();
    })
   }

   const proceed = () => {
      setProceedPayment(true);
      setSelectedWizard('checkout');
   };
 
   useEffect(() => {
    

   }, []);
  return (
    <div className="m-2 w-full">

      <div className="w-full">
      <FaRegTimesCircle onClick={() => mayaAlert.close()} className="inline-block w-12 text-2xl float-right text-gray-200 hover:text-gray-300 cursor-pointer" />

        <div className="container flex flex-wrap pt-1 pb-2 m-auto mt-1">
          <div className="w-full px-0 lg:px-4">
            <div className="mb-10 items-center justify-center lg:flex font-medium text-xs flex text-gray-300 my-2">
              <div className={`${selectedWizard === 'package' ? 'text-sm  text-white' : ''} mx-2 cursor-pointer`} onClick={() => setSelectedWizard('package')}>Choose a gift card</div>
              <div className="mx-2">
                <FaChevronRight />
              </div>
              <div className={`${selectedWizard === 'summary' ? 'text-sm  text-white' : ''} mx-2 cursor-pointer`} onClick={() =>  selectedPackage ? setSelectedWizard('summary'): {}}>Summary</div>
              <div className="mx-2">
                <FaChevronRight />
              </div>
              <div className={`${selectedWizard === 'checkout' ? 'text-sm text-white' : ''} mx-2 cursor-pointer`} onClick={() =>selectedPackage && proceedPayment ? setSelectedWizard('checkout') : {}}>Checkout</div>
            </div>
            <div className="my-2">
            <span className="text-white mx-2 text-lg inline-block align-top">ETB</span>
              <Switch
                   onColor="#86d3ff"
                   onHandleColor="#86d3ff"
                   handleDiameter={30}
                   uncheckedIcon={false}
                   checkedIcon={false}
                   boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                   activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                   height={20}
                   width={48}
                    onChange={(e) =>{
                      setIsPriceUSD(e);
                      if(e)
                       setPaymentOption('stripe');
                      else
                        setPaymentOption('telebirr');
                    } }
                    checked={isPriceUSD}
                    className="react-switch"
                />
                  <span className="text-white mx-2 text-lg inline-block align-top">USD</span>
            </div>
            <div style={{ minHeight: '350px' }}>
              {selectedWizard === 'package' && (
              <div>
                <h2 className="px-12 text-base font-bold text-center md:text-2xl text-white">
                  Choose your gift cards
                </h2>
            
                <div className="flex flex-wrap items-center justify-center py-4 pt-0">
                {lookups.subscriptionPackages.filter((x) => lookups.priceSettings.some((y) => y.plan === 1 && y.package === x.id)).map((x, index) => (
                    <div
                     key={index + "-sub"}
                      onClick={() => {
                      setSelectedPackage(lookups.giftCodePriceSettings.find((y) => y.plan === 2 && y.package === x.id));
                      setSelectedWizard('summary');
                    }}
                      className="w-full p-4 md:w-1/2 lg:w-1/4  transform hover:scale-105  duration-1000 ease-out">
                      <label className="flex flex-col rounded-lg shadow-lg group relative cursor-pointer hover:shadow-2xl border">
                        <div className="w-full px-4 py-6 rounded-t-lg card-section-1">
                          <h3 className="mx-auto text-base font-semibold text-center  text-white">
                            For {x.description}
                          </h3>
                          <p className="text-2xl font-bold text-center text-white my-4">
                            <CurrencyFormat
                             value={isPriceUSD ? lookups.giftCodePriceSettings.find((y) => y.plan === 2 && y.package === x.id)?.usdPrice : 
                              lookups.giftCodePriceSettings.find((y) => y.plan === 2 && y.package === x.id)?.price} decimalScale={2} fixedDecimalScale displayType="text" thousandSeparator   
                            prefix={isPriceUSD ? ' $' :  ''}
                            suffix={!isPriceUSD ? ' ETB' :  ''} />
                          </p>
                        </div>
                        <div className="flex flex-col items-center justify-center w-full h-full py-6 rounded-b-lg bg-blue-500">      
                          <button className="w-5/6 py-2 mt-2 font-semibold text-center  text-sm uppercase bg-white border border-transparent rounded text-blue-500">
                            Choose
                          </button>
                        </div>
                      </label>
                    </div>
              )

                )}


                </div>
              </div>
            )}

              {selectedWizard === 'summary' && (
              <div>
                <h2 className="px-12 text-base font-bold text-center md:text-2xl text-white">
                  You chose    {lookups.subscriptionPackages.find((x) => x.id === selectedPackage.package)?.description} gift card
                </h2>

                <div className="py-2 px-1 inline-block">
                  <ProfileFormLabel>Quantity</ProfileFormLabel>
                  <div className="w-40 my-2">

                    <ProfileFormInput
                      type="number"
                      min="1"
                      value={quantity}                 
                      onChange={(e) => {
                        setQuantity(parseInt(e.target.value));
        
                      }}
                    />
                  </div>
                  </div>

                <div className="py-2 text-sm text-center text-gray-300 mb-2">
                    Total Price
                  <div className="text-3xl mb-4">
                    <CurrencyFormat
                      value={(!isPriceUSD ? selectedPackage.price : selectedPackage.usdPrice) * quantity}
                      decimalScale={2}
                      fixedDecimalScale
                      displayType="text"
                      thousandSeparator
                      prefix={isPriceUSD ? ' $' :  ''}
                      suffix={!isPriceUSD ? ' ETB' :  ''}
                    />
                  </div>


                  <h2 className="px-12 text-base font-bold text-center md:text-2xl text-white">
                  Pay with
                </h2>
                <div className="p-1 m-4 flex flex-col md:flex md:flex-row justify-center border border-gray-700 rounded">
                {!isPriceUSD && <div className="my-2 items-center mx-6 p-2 md:border-none border-b border-gray-700">
                        <input
                          type="radio"
                          name="paymentOption"
                          value="yenepay"
                          checked={paymentOption === 'telebirr'}
                          onChange={() => setPaymentOption('telebirr')}
                          className=" h-5 w-5 cursor-pointer"
                        />
                        <img src={TELEBIRR_ICON_URL} className="w-32 mx-auto ml-1 inline-block" />

                      </div>
                      }
                      {!isPriceUSD && <div className="my-2 items-center mx-6 p-2 md:border-none border-b border-gray-700">
                        <input
                          type="radio"
                          name="paymentOption"
                          value="etcCredit"
                          checked={paymentOption === 'etcCredit'}
                          onChange={() => setPaymentOption('etcCredit')}
                          className=" h-5 w-5 cursor-pointer"
                        />
                        <img src={ETC_CREDIT_ICON_URL} className="w-32 mx-auto ml-1 inline-block" />
                        <div className="w-full block my-2 pb-4 text-xs ml-2">
                          Airtime
                        </div>
                      </div>
                      }
                      {!isPriceUSD && <div className="my-2 items-center mx-6 p-2 md:border-none border-b border-gray-700">
                        <input
                          type= "radio"
                          name="paymentOption"
                          value="yenepay"
                          checked={paymentOption === 'yenepay'}
                          onChange={() => setPaymentOption('yenepay')}
                          className=" h-5 w-5 cursor-pointer"
                        />
                        <img src={YENEPAY_ICON_URL} className="w-32 mx-auto ml-1 inline-block" />
                        <div className="w-full block my-4 pb-4">
                          {paymentOptions.map((x, i) => (
                            <img src={x.icon} className="w-8 rounded-full inline-block mx-1 ml-1" key={"pimg-" + i} />
                          ))}
                        </div>
                      </div>
                      }
                 {/* {isPriceUSD &&  <div className="items-center mx-6 p-2 md:border-none border-b border-gray-700">
                    <input
                      type="radio"
                      name="paymentOption"
                      value="stripe"
                      checked={paymentOption === 'stripe'}
                      onChange={() => setPaymentOption('stripe')}
                      className=" h-5 w-5 -mt-2"
                    />
                   <FaStripe className="text-white text-6xl my-2 inline-block -mt-1 mx-2"  />
                  </div>} */}
                  {isPriceUSD &&    <div className="items-center mx-6 p-2 md:border-none border-b border-gray-700">
                    <input
                      type="radio"
                      name="paymentOption"
                      value="paypal"
                      checked={paymentOption === 'paypal'}
                      onChange={() => setPaymentOption('paypal')}
                      className=" h-5 w-5 cursor-pointer"
                    />
                    <FaCcPaypal className="text-white text-5xl my-2 inline-block mx-2"  />
                  </div>
                  }
                
                </div>
                <br />
               

                  <div className="my-4 mt-10 w-40 mx-auto">
                    <button
                      onClick={() => proceed()}
                      className="text-white w-full  rounded-md text-center bg-blue-500 py-2 px-4  items-center focus:outline-none"
                    >
                      Proceed
                    </button>


                  </div>



                </div>

              </div>

            )}


            {selectedWizard === 'checkout' && (
              <div>
                {proceedPayment && paymentOption === 'stripe' && 
                  
                  <Elements stripe={stripePromise}>
                     <StripeForm  selectedPackage={selectedPackage} lookups={lookups} quantity={quantity} callback={() => successCallback()} />
                  </Elements>
                  }

                  {proceedPayment && paymentOption === 'paypal' && 
                      <PayPalForm  selectedPackage={selectedPackage} lookups={lookups} profileId={profileId} quantity={quantity}  callback={() => successCallback()} />
                  }
                    {proceedPayment && paymentOption === 'yenepay' && 
                      <YenePayForm  selectedPackage={selectedPackage} lookups={lookups}  quantity={quantity} callback={() => successCallback()} path={"/account/giftcards"}  />
                  }
                   {proceedPayment && paymentOption === 'telebirr' && 
                      <TelebirrForm  selectedPackage={selectedPackage} lookups={lookups} quantity={quantity}  callback={() => successCallback()} path={"/account/giftcards"}/>
                  }
                    {proceedPayment && paymentOption === 'etcCredit' &&
                    <ETCCreditForm selectedPackage={selectedPackage} lookups={lookups} quantity={1} callback={() => successCallback()} isPhoneVerified={account?.isPhoneVerified} verifyCallback={() => {
                      mayaAlert.close();
                      history.push('/account/manage');
                    }} />
                  }
                </div>
              )
            }
            
            </div>
          </div>

        </div>
      </div>
      <div className="w-full my-2" />

    </div>
  );
}

export default GiftCardForm;
