/* eslint-disable react/jsx-key */
import React from "react";
import { Link } from "react-router-dom";
import JumboWrapper from "../components/Jumbotron/JumboWrapper";
import JumboTitle from "../components/Jumbotron/JumboTitle";
import JumboData from "../data/jumbo.json";

const ReactRotatingText = require("react-rotating-text");

const data = ["Action", "Drama", "Romance", "Comedy", "Thriller", "Tragedy"];

function JumboCompound() {
  return (
    <JumboWrapper className="h-full mt-20 flex flex-col items-center justify-center">
      <JumboTitle>
        <span className="align-top p-2 md:p-4  "> All the </span>
        <ReactRotatingText
          className="p-2 md:p-4 bg-blue-500 text-white rounded uppercase cutout-text3"
          items={data}
          pause={2500}
        />
        <span className="align-top p-2 md:p-4  "> you love!</span>
      </JumboTitle>
      <span className="md:text-xl text text-gray-400 mt-6 mx-4 text-center">
        Enjoy our selection of Ethiopian movies, series and much more.
        <br /> Subscribe once and stream as much content as you like.
      </span>
      <div className="flex flex-col md:flex-row md:m-10 m-4 md:mx-32 justify-center">
        {JumboData.map((item) => (
          <Link
            className="relative text-left m-4 cursor-pointer rounded-xl"
            to="/signup"
            key={item.genre}
          >
            <div
              className="absolute top-0 bottom-0 right-0 left-0"
              style={{
                background:
                  "linear-gradient(156.82deg, rgba(0,0,0,0.6) 4.58%, rgba(0,0,0,0) 69.61%),linear-gradient(24.5deg, rgba(0,0,0,0.2) 4.71%, rgba(0,0,0,0) 71.49%)",
              }}
            />
            <img
              className="w-full h-full object-cover align-middle rounded-xl"
              src={item.image}
              alt=""
            />
            <div className="p-4 absolute top-0 left-0">
              <div className="inline-flex items-center bg-blue-500 text-white rounded-full px-3 py-1 text-sm font-black">
                {item.desc}
              </div>
              <div className="text-lg font-black">{item.genre}</div>
            </div>
          </Link>
        ))}
      </div>
    </JumboWrapper>
  );
}

export default JumboCompound;
