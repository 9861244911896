import React from "react";
import "./MoviesStyles.css";

function CardFeatureWrapper({ children, pic, ...restProps }) {
  return (
    <div
      className="card-feature-wrapper br-25 bg-black p-4 mt-0 md:mx-0 text-left"
      style={{
        backgroundImage: `linear-gradient(to top right, rgb(17, 24, 39) 45%, rgba(0,0,0,0)), url(${pic})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundColor: 'rgba(0, 0, 0, 0.5)'

      }}
      {...restProps}
    >
      {children}
    </div>
  );
}

export default CardFeatureWrapper;
