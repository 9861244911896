import React from "react";
import { FaEdit } from "react-icons/fa";
import ProfileIcon from "./ProfileIcon";

function ProfileFormSection({
  id,
  callBack, name, state, setProfile, profile,edit, ...restProps
}) {
  return (
    <div data-id={id} className="profile-card">

      <div
        className={`${(state?.selectedProfile && state?.selectedProfile?.id === profile.id) ? "border-4 border-white rounded" : "border-4 border-transparent hover:border-white rounded"}  text-gray-100 text-lg md:text-2xl mx-10 md:mx-4 transform hover:scale-105  duration-1000 ease-out cursor-pointer my-4 md:my-0 block`}
        onClick={() => setProfile(profile)}
      >

        <ProfileIcon letters={name.substring(0, 2)} />
      </div>
      <div className="mt-4 -ml-4">
        {edit && (
        <div className="inline-block">
          {profile.name !== "Default" &&
          <FaEdit onClick={() => callBack()} className="text-gray-500   text-xl hover:text-yellow-500 cursor-pointer  mr-2 ml-4" /> }

        </div>
        ) }
        {name}
        <span className="text-lg md:text-2xl text-gray-100 "></span>
      </div>

    </div>
  );
}

export default ProfileFormSection;
