import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import HeaderWrapper from "../components/Header/HeaderWrapper";
import NavBar from "../components/Header/NavBar";
import Logo from "../components/Header/Logo";
import FooterCompound from "../compounds/FooterCompound";
import OverlayWrapper from "../components/Header/OverlayWrapper";
import { StoreContext } from "../context/Store";
import TermsCompunded from "../compounds/TermsCompounded";

function Privacy() {
  const history = useHistory();

  return (
    <>
      <HeaderWrapper className="header-wrapper-home">
        <OverlayWrapper>
          <NavBar className="navbar-signin">
            <Logo history={history} />
          </NavBar>
          <div className="bg-maya-blue mb-20 md:mx-80 mx-2 my-auto  flex items-center">
            <TermsCompunded />
          </div>
        </OverlayWrapper>
      </HeaderWrapper>

      {/* <FooterCompound /> */}
    </>
  );
}

export default Privacy;
