/* eslint-disable react/jsx-key */
/* eslint-disable react/button-has-type */
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  FaCheckCircle,
  FaChevronLeft,
  FaHome,
  FaPlus,
  FaPlusCircle,
  FaSquare,
} from "react-icons/fa";
import FooterCompound from "../compounds/FooterCompound";
import Logo from "../components/Header/Logo";
import AllSlidesWrapper from "../components/Movies/AllSlidesWrapper";
import ProfileCompound from "../compounds/ProfileCompound";
import NavBar from "../components/Header/NavBar";
import ProfileFormInput from "../components/ProfileForm/ProfileFormInput";
import AccountProfile from "../components/ProfileForm/AccountProfile";
import { StoreContext } from "../context/Store";
import {
  ADD_PROFILE,
  RESET,
  SET_PLAYER,
  SET_PROFILE,
  SET_TOKEN,
  UPDATE_PROFILE,
} from "../context/ActionTypes";
import {
  getUUID,
  mayaAlert,
  MY_MOVIES_LIST,
  MY_SERIES_LIST,
} from "../constants";
import MayaDataService from "../services/MayaDataService";
import {
  getInprogressMovies,
  getMyList,
  getMySeriesList,
  selectCurrentProfile,
  setCurrentProfile,
} from "../context/Actions";
import LoadingIcon from "../components/Common/LoadingIcon";
import SignFormError from "../components/SignForm/SignFormError";

function ProfilePage() {
  const history = useHistory();
  const [addProfile, setAddProfile] = useState(false);
  const [state, dispatch] = useContext(StoreContext);
  const [loading, setLoading] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [profileName, setProfileName] = useState("");
  const [profile, setProfile] = useState();
  const [error, setError] = useState("");

  function useQueryParams() {
    const params = new URLSearchParams(
      window ? window.location.search : {}
    );

    return new Proxy(params, {
        get(target, prop) {
            return target.get(prop)
        },
    });
}

  const {type, selected_profile} = useQueryParams();

  const createProfile = () => {
    setError("");
    if (profileName === "" || profileName.length < 3) {
      setError("Profile name should be at least 3 characters");
      return;
    }
    if (state.profiles.some((x) => x.name === profileName)) {
      setError("Profile name already exists");
      return;
    }

    var data = {};
    if (isUpdate) {
      state.account.profiles.find((x) => x.id === profile.id).name =
        profileName;
      data = { ...state.account, profiles: state.account.profiles };
    } else {
      data = {
        ...state.account,
        profiles: [
          ...state.account.profiles,
          {
            name: profileName,
            isDefault: false,
            userId: state.account.Id,
          },
        ],
      };
    }
    MayaDataService.updateProfile(data)
      .then((result) => {
        localStorage.setItem("user", JSON.stringify(result.data));
        dispatch({ type: UPDATE_PROFILE, payload: result.data.profiles });
      })
      .catch((e) => {
        setLoading(false);
      });

    setAddProfile(false);
    setProfileName();
    setIsUpdate(false);
  };

  const removeProfile = () => {
    setError("");

    MayaDataService.removeProfile(profile.id)
      .then((result) => {
        if (state.selectedProfile.id == profile.id) {
          renew(
            state.profiles.find((x) => x.isDefault),
            true
          );
        } else {
          renew(state.selectedProfile, false);
        }
      })
      .catch((e) => {
        setLoading(false);
      });

    setAddProfile(false);
    setProfileName();
    setIsUpdate(false);
  };

  const renew = (_profile, reset = false) => {
    MayaDataService.renewToken(_profile.id).then((result) => {
      localStorage.setItem("token", result.data.token);
      localStorage.setItem("user", JSON.stringify(result.data.user));
      dispatch({ type: SET_TOKEN, payload: result.data });
      dispatch({
        type: SET_PROFILE,
        payload: result.data.user.profiles,
      });
      setCurrentProfile(_profile, dispatch);

      if (reset) {
        dispatch({ type: RESET, payload: {} });
        getMyList(state, dispatch, MY_MOVIES_LIST, {}, true);
        getMySeriesList(state, dispatch, MY_SERIES_LIST, {}, true);
        getInprogressMovies(state, dispatch);
      }
    });
  };
  const selectProfile = (profileData) => {
    setIsUpdate(true);
    setAddProfile(true);
    setProfile(profileData);
    setProfileName(profileData.name);
  };

  const newProfile = () => {
    setIsUpdate(false);
    setAddProfile(true);
    setProfile();
    setProfileName("");
  };

  const onSelectProfile = (p) => {
    if (!loading) 
    {
      if(type !== 'mobile')
      {
        selectCurrentProfile(p, state, dispatch, setLoading, history);
      }    
   
    }
  };

  const onProfileRemove = () => {
    mayaAlert
      .fire({
        title: `Are you sure ?`,
        text: `You are removing ${profileName} profile?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#ececece",
        confirmButtonText: "Yes, remove it!",
      })
      .then((result) => {
        if (result.isConfirmed) {
          removeProfile();
        }
      });
  };
  useEffect(() => {
    dispatch({
      type: SET_PLAYER,
      payload: {
        playerOn: false,
      },
    });
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if(selected_profile && state.profiles.length > 0)
    {
      
      setCurrentProfile(state.profiles.find(x=> x.id === selected_profile), dispatch)
    }
   
   
  }, [selected_profile,state.profiles]);

  return (
    <>
      {type !== 'mobile'  && 
      <NavBar className="navbar-browse w-full">
        <Logo history={history} state={state} dispatch={dispatch} />
        <ProfileCompound history={history} />
      </NavBar>
      }
      <AllSlidesWrapper className={["mb-10 mx-4 md:mx-10 min-h-screen", type === 'mobile' ? '' : ' py-16'].join(' ')}>
        {!addProfile && (
          <div className="mx-auto container text-center w-full md:w-full py-20">
           {type !== 'mobile'  &&  <div className="">
              <span
                className="text-gray-500 text-lg capitalize cursor-pointer hover:text-white mb-1"
                onClick={() => history.goBack()}
              >
                <FaChevronLeft className="inline-block -mt-1" /> Back
              </span>
            </div> }
            <div className="text-2xl md:text-5xl mb-4 md:mb-20">
              Manage Profiles
            </div>

            <div className="my-6 md:flex justify-center">
              {state.profiles.map((x) => (
                <AccountProfile
                  callBack={() =>
                    x.name !== "Default" ? selectProfile(x) : {}
                  }
                  key={x.id}
                  name={x.name}
                  state={state}
                  profile={x}
                  setProfile={(p) => onSelectProfile(p)}
                  edit
                  id={x.id}
                />
              ))}

              <div
                className="text-gray-100 text-lg md:text-2xl mx-10 md:mx-4 transform hover:scale-105  duration-1000 ease-out cursor-pointer"
                onClick={() => newProfile()}
              >
                <div className="bg-gray-400 items-center  justify-center rounded  p-8 md:p-16 ">
                  <span className="text-white text-3xl md:text-6xl">
                    {" "}
                    <FaPlusCircle className=" mx-auto" />
                  </span>
                </div>
                <div className="mt-4 text-gray-400">Add Profile</div>
              </div>
            </div>

            {loading && (
              <span className="inline-block align-middle flex justify-center">
                {" "}
                <LoadingIcon /> Changing profile ...{" "}
              </span>
            )}
          </div>
        )}
        {addProfile && (
          <div className="mx-auto container text-left max-w-3xl md:w-full py-20">
            <div className="text-5xl">
              {isUpdate ? "Update Profile" : "Add Profile"}
            </div>
            <div className="text-lg text-gray-400">
              Add a profile for another person watching
            </div>
            {error ? <SignFormError>{error}</SignFormError> : null}
            <div className="my-6 ">
              <div className="w-full inline-flex">
                <ProfileFormInput
                  type="text"
                  value={profileName}
                  onChange={(e) => setProfileName(e.target.value)}
                  placeholder="Name"
                />
              </div>
              <div className="w-full my-4 text-left">
                <button
                  className="px-4 py-2 bg-blue-500 mr-4"
                  onClick={() => createProfile()}
                >
                  {isUpdate ? "Update" : "Add"}
                </button>
                {profile?.name !== "Default" && isUpdate && (
                  <button
                    className="px-4 py-2 bg-red-500  mr-4"
                    onClick={() => onProfileRemove()}
                  >
                    Remove
                  </button>
                )}
                <button
                  className="px-4 py-2 bg-gray-500"
                  onClick={() => {
                    setError("");
                    setAddProfile(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
      </AllSlidesWrapper>

      <FooterCompound />
    </>
  );
}

export default ProfilePage;
