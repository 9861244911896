import React from "react";
import "./MoviesStyles.css";

function AllCardsWrapper({ children, ...restProps }) {
  return (
    <div className="w-full z-50 grid grid-cols-2 md:grid-cols-5 gap-4 sm:grid-cols-3 justify-items-center sm:justify-items-start" {...restProps}>
      {children}
    </div>
  );
}

export default AllCardsWrapper;
