import React from "react";
import "./HeaderStyles.css";
import { FaChevronRight, FaCircle } from 'react-icons/fa';

function GenreWrapper({ children, ...restProps }) {
  return (
    <div className="fixed text-white  w-full h-full  z-30 right-0 top-0" style={{ zIndex: '1001', backgroundColor: 'rgb(238, 238, 238)' }} type="button" {...restProps}>

      <div className="bg-black p-4 md:p-12  w-full h-full">
        {children}
      </div>

    </div>
  );
}

export default GenreWrapper;
