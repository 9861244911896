import React from "react";
import ReactDom from "react-dom";
import App from "./App";
import './styles/index.css';

// if (process.env.REACT_APP_ENV === 'production') {
//   console.log = () => {};
//   console.error = () => {};
//   console.debug = () => {};
//   console.warn = () => {};
// }

ReactDom.render(
  <>
    <App />
  </>,
  document.getElementById("root")
);
