/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect } from "react";
import OtpInput from 'react-otp-input';
import useScript from "../../hooks/useScript";
import "./SignFormStyles.css";

function OtpForm({ setCode, ...restProps }) {
  const [otp, setOtp] = useState();

  useEffect(() => {
    setCode(otp);
  }, [otp]);

  return (
    <OtpInput
      value={otp}
      {...restProps}
      onChange={setOtp}
      className="text-white w-full"
      inputStyle="sign-form-input-otp text-white text-lg w-full"
      numInputs={6}
      isInputNum
      separator={<span className="mx-1"> </span>}
    />
  );
}

export default OtpForm;
