/* eslint-disable react/no-children-prop */
import React from "react";
import { useHistory } from "react-router-dom";
import HeaderCompound from "../compounds/HeaderCompound";
import OptFormCompound from "../compounds/OptFormCompound";
import JumboCompound from "../compounds/JumboCompound";
import Separator from "../components/Common/Separator";
import AccordionCompound from "../compounds/AccordionCompound";
import FooterCompound from "../compounds/FooterCompound";
import ContactCompound from "../compounds/ContactCompound";
import StoreCompound from "../compounds/StoreCompound";

function HomePage() {
  const history = useHistory();
  return (
    <>
      <HeaderCompound history={history} />
      <Separator />
      <JumboCompound />
      <Separator />
      <StoreCompound />
      <Separator />
      <div className="flex flex-col md:flex-row px-2 md:px-32 my-10">
        <AccordionCompound />
        <ContactCompound />
      </div>

      <FooterCompound />
    </>
  );
}

export default HomePage;
