import React from "react";
import "./HeaderStyles.css";

function OverlayWrapper({ children, ...restProps }) {
  return (
    <div className="overlay-wrapper min-h-screen" {...restProps}>
      {children}
    </div>
  );
}

export default OverlayWrapper;
