import React from "react";
import "./AccordionStyles.css";

function AccordionTitle({ children, ...restProps }) {
  return (
    <h1
      className="text-2xl md:text-4xl font-bold leading-tight my-4"
      {...restProps}
    >
      {children}
    </h1>
  );
}

export default AccordionTitle;
