import React, { useState, useEffect } from "react";
import {useStripe } from '@stripe/react-stripe-js';
import { FaStripe } from "react-icons/fa";
import MayaDataService from "../../services/MayaDataService";
import LoadingIcon from "../Common/LoadingIcon";



function StripeForm({ selectedPackage, lookups, quantity, callback }) {
  const [sessionId, setSessionId] = useState();
  const [loading, setLoading] = useState(false);
  const stripe = useStripe();
  const checkout = () => {

    MayaDataService.stripeCheckout({
       amount:  selectedPackage.price,
       priceSettingId: selectedPackage.id,
       quantity: quantity
    }).then((result) => {
      var prefix = "Subscription_";
      if(selectedPackage.plan === 2)
        prefix = "Gift_";
      
      localStorage.setItem('stripeSessionId', prefix + result.sessionId);
        
      setSessionId(result.sessionId);
      callback();

    }).catch((e) => {

    });
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    await stripe.redirectToCheckout({sessionId: sessionId});
  };

  
  useEffect(() => {
    checkout()
  
  }, []);

  return (

    <form onSubmit={handleSubmit} className="mx-2 md:mx-20 pb-20">

        <h2 className="px-12 text-base font-bold text-center md:text-2xl text-white">
          {lookups.subscriptionPackages.find((x) => x.id === selectedPackage.package)?.description} Plan
        </h2>
        <FaStripe className="text-white  my-2 mx-auto" style={{fontSize: '120px'}} />
       {sessionId &&  <button
          type="submit" disabled={!stripe || loading}
          className="text-white font-semibold w-full  mt-4 rounded-md text-center bg-blue-500 py-2 px-4  items-center focus:outline-none"
        >
          {!loading && <span> Pay ${selectedPackage.usdPrice * quantity}</span>}
          {loading && <span className="inline-block align-middle"> <LoadingIcon /> </span> }
      
       </button> }
       {!sessionId && <div><span className="inline-block align-middle"> 
       <LoadingIcon className="inline-block" /> </span>
        <span className="inline-block">Processing...</span> </div>}
      
     
    </form>

  );
}

export default StripeForm;
