import React from "react";
import { FaPlay } from "react-icons/fa";
import "./MoviesStyles.css";

function CardImage({ src, onClick, ...restProps }) {
  return (
    <div className="relative card-image h-60 w-full shadow-lg rounded-2xl" {...restProps}>
      <div className="absolute h-full w-full flex items-center justify-center opacity-0 group-hover:opacity-100 transition delay-200 duration-200">
        <span className="flex items-center justify-center w-10 h-10 bg-blue-500 bg-opacity-80 hover:bg-opacity-100 rounded-full border-2 border-white">
          <FaPlay className="text-white" onClick={onClick} />
        </span>
      </div>
      <img src={src} className="card-image rounded-2xl group-hover:rounded-sm delay-200 duration-200 object-cover w-full h-full" alt="" />
    </div>
  );
}

export default CardImage;
