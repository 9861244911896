export const FETCH_MOVIES_REQUEST = 'FETCH_MOVIES_REQUEST';
export const FETCH_MOVIES_SUCCESS = 'FETCH_MOVIES_SUCCESS';
export const FETCH_MOVIES_FAILURE = 'FETCH_MOVIES_FAILURE';

export const FETCH_SERIES_REQUEST = 'FETCH_SERIES_REQUEST';
export const FETCH_SERIES_SUCCESS = 'FETCH_SERIES_SUCCESS';
export const FETCH_SERIES_FAILURE = 'FETCH_SERIES_FAILURE';

export const GET_MOVIES = 'GET_MOVIES';
export const GET_SERIES = 'GET_SERIES';
export const ADD_TO_MY_LIST = 'ADD_TO_MY_LIST';
export const REMOVE_FROM_MY_LIST = 'REMOVE_FROM_MY_LIST';
export const SET_FILTER = 'SET_FILTER';
export const SET_PLAYER = 'SET_PLAYER';
export const SET_CURRENT_MOVIE = 'SET_CURRENT_MOVIE';
export const SET_LOOKUPS = 'SET_LOOKUPS';
export const SET_TOKEN = 'SET_TOKEN';
export const SET_MY_LIST = 'SET_MY_LIST';
export const ADD_TO_MY_LIST_REQUEST = 'ADD_TO_MY_LIST_REQUEST';
export const ADD_TO_MY_LIST_SUCCESS = 'ADD_TO_MY_LIST_SUCCESS';
export const ADD_TO_MY_LIST_FAILURE = 'ADD_TO_MY_LIST_FAILURE';

export const LOADING_MORE_MOVIES_SUCCESS = 'LOADING_MORE_MOVIES_SUCCESS';
export const LOADING_MORE_SERIES_SUCCESS = 'LOADING_MORE_SERIES_SUCCESS';

export const LOADING_MORE_MYLIST_SUCCESS = 'LOADING_MORE_MYLIST_SUCCESS';
export const SET_PROFILE = 'SET_PROFILE';
export const ADD_PROFILE = 'ADD_PROFILE';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const SELECT_PROFILE = 'SELECT_PROFILE';

export const RESET = 'RESET';
export const LOADING_MORE_MY_SERIES_LIST_SUCCESS = 'LOADING_MORE_MY_SERIES_LIST_SUCCESS';
export const SET_MY_SERIES_LIST = 'SET_MY_SERIES_LIST';
export const SET_SEASON = 'SET_SEASON';

export const FETCH_FEATURED_REQUEST = 'FETCH_FEATURED_REQUEST';
export const FETCH_FEATURED_SUCCESS = 'FETCH_FEATURED_SUCCESS';
export const FETCH_FEATURED_FAILURE = 'FETCH_FEATURED_FAILURE';

export const FETCH_INPROGRESS_MOVIES_REQUEST = 'FETCH_INPROGRESS_MOVIES_REQUEST';
export const FETCH_INPROGRESS_MOVIES_SUCCESS = 'FETCH_INPROGRESS_MOVIES_SUCCESS';
export const FETCH_INPROGRESS_MOVIES_FAILURE = 'FETCH_INPROGRESS_MOVIES_FAILURE';

export const SET_SESSION = 'SET_SESSION';

export const SET_HEADER_MOVIE_PLAY = 'SET_HEADER_MOVIE_PLAY';

export const SET_MOVIES_HOME_GENRE = 'SET_MOVIES_HOME_GENRE';
export const SET_SERIES_HOME_GENRE = 'SET_SERIES_HOME_GENRE';

