import React, { useEffect, useState } from "react";
import LoadingIcon from "../components/Common/LoadingIcon";
import OtpForm from "../components/SignForm/OtpForm";
import SignFormButton from "../components/SignForm/SignFormButton";
import { firebase } from "../lib/firebase.prod";
import Firebase from "firebase";

function OtpFormCompund({ verify, username, title='Account Verification' }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpSent, setOtpSent] = useState(false);

  const IsInvalidOTP = otp === "" || otp?.length !== 6;

  const submitPhoneNumberAuth = () => {
    setError(false);
    let phoneNo = username;
    if (username.indexOf("+") === -1) {
      phoneNo = `+${phoneNo}`;
    }
    configureFirebaseAuth();

    const appVerifier = window.recaptchaVerifier;
    firebase
      .auth()
      .signInWithPhoneNumber(phoneNo, appVerifier)
      .then((confirmResult) => {
        window.confirmationResult = confirmResult;
        setOtpSent(true);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
        setError(true);
      });
  };
  const verifyPhone = () => {
    setLoading(true);
    window.confirmationResult
      .confirm(otp)
      .then(async (result) => {
        //const { user } = result;
        const token = await firebase.auth().currentUser.getIdToken(true);
        localStorage.setItem("token", token);
        verify();
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };

  const configureFirebaseAuth = () => {
    window.recaptchaVerifier = new Firebase.auth.RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback() {},
      }
    );
  };
  useEffect(() => {
    submitPhoneNumberAuth();
  }, []);
  return (
    <div className="p-0">
      <span className="text-3xl font-medium text-white">
        { title }
      </span>
      {/* {error ? <SignFormError>{error}</SignFormError> : null} */}

      {!otpSent && !error && (
        <div className="flex justify-center my-20">
          <LoadingIcon /> Sending Code
        </div>
      )}

      {error && (
        <div className="flex justify-center my-20">
          We were unable to send a verification code. Please try again in a few minutes.
        </div>
      )}

      {otpSent && (
        <>
          <div className="text-sm text-gray-500 mb-4 text-center mt-5">
            Enter code sent to your phone number
          </div>
          <OtpForm setCode={setOtp} />
          {loading && (
            <div className="flex justify-center my-4">
              <LoadingIcon />
            </div>
          )}
          {!loading && (
            <SignFormButton
              disabled={IsInvalidOTP}
              onClick={() => verifyPhone()}
            >
              Verify
            </SignFormButton>
          )}
        </>
      )}

      <div id="recaptcha-container" />
    </div>
  );
}

export default OtpFormCompund;
