/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/button-has-type */
/* eslint-disable import/named */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable array-callback-return */
/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/jsx-key */
import React, {
  useContext, useEffect, useState
} from "react";
import { useHistory } from "react-router-dom";
import { FiChevronRight } from "react-icons/fi";
import {
  FaChevronRight,
  FaRegTimesCircle,
  FaSearch,
} from "react-icons/fa";
import useDynamicRefs from "use-dynamic-refs";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import moment from "moment";
import Modal from 'react-modal';
import HeaderWrapper from "../components/Header/HeaderWrapper";
import NavBar from "../components/Header/NavBar";
import Logo from "../components/Header/Logo";
import HeaderLink from "../components/Header/HeaderLink";
import AllSlidesWrapper from "../components/Movies/AllSlidesWrapper";
import SlideWrapper from "../components/Movies/SlideWrapper";
import SlideTitle from "../components/Movies/SlideTitle";
import AllCardsWrapper from "../components/Movies/AllCardsWrapper";
import CardWrapper from "../components/Movies/CardWrapper";
import CardImage from "../components/Movies/CardImage";
import FooterCompound from "../compounds/FooterCompound";
import GenreWrapper from "../components/Header/GenreWrapper";
import { StoreContext } from "../context/Store";
import PlayerCompound from "../compounds/PlayerCompound";
import CardNoTitle from "../components/Common/CardNoTitle";
import CardNoMovie from "../components/Common/CardNoMovie";
import AccountProfile from "../components/ProfileForm/AccountProfile";
import {
  chooseProfile,
  getMovies,
  selectCurrentProfile,
  showPlayer,
} from "../context/Actions";
import SearchButton from "../components/Header/SearchButton";
import ProfileCompound from "../compounds/ProfileCompound";
import CardBottom from "../components/Movies/CardBottom";
import MovieProgress from "../components/Movies/MovieProgress";
import {
  CONTINUE_WATCHING,
  customStyles,
  MOVIES,
  MOVIES_CACHE_KEY,
  MY_LIST,
  mayaAlert,
  customFullStyles
} from "../constants";
import MovieCardCompounded from "../compounds/MovieCardCompounded";
import LogoLarge from "../components/Header/LogoLarge";

const category = MOVIES;

const noMovieLoading = [...Array(1)].map((x, index) => (
  <SlideWrapper key={`${index}-no-movie`}>
    <SlideTitle>
      <CardNoTitle />
    </SlideTitle>
    <AllCardsWrapper>
      {[...Array(5)].map((y, index) => (
        <CardNoMovie width="w-full" key={`${index}-no-movie-loading`} />
      ))}
    </AllCardsWrapper>
  </SlideWrapper>
));

function BrowsePage() {
  const history = useHistory();
  const [state, dispatch] = useContext(StoreContext);
  const [viewGenre, setViewGenre] = useState(false);
  const [viewSearch, setViewSearch] = useState(false);
  const [genres, setGenres] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [browseList, setBrowseList] = useState([]);

  const [scrollable, setScrollable] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [showSubscribeModal, setShowSubscribeModal] = useState(false);
  const [showProfileChoiceModal, setShowProfileChoiceModal] = useState(false);
  const [activeItem, setActiveItem] = useState();
  const [activeGenre, setActiveGenre] = useState();
  const [movies, setMovies] = useState([]);

  const loadMovies = (genre) => {
    getMovies(
      state,
      dispatch,
      `${MOVIES_CACHE_KEY}${genre}`,
      { genreId: genre, pageSize: 5 },
      false,
      false,
      () => {
        setIsLoading(false);
      }
    );
  };

  useBottomScrollListener(() => {
    if (!isLoading && browseList.some((x) => !x.loaded)) {
      setIsLoading(true);
      loadMovies(browseList.find((x) => !x.loaded).id);
    }
  });

  const loadBrowsing = () => {
    const list = [];

    if (state.inProgressMovies.filter(x => !x.isSeries).length > 0) {
      list.push({
        title: CONTINUE_WATCHING,
        genre: CONTINUE_WATCHING,
        data: state.inProgressMovies.filter(x => !x.isSeries).slice(-5),
        filterKey: CONTINUE_WATCHING,
      });
    }

    if (state.myList.length > 0) {
      list.push({
        title: MY_LIST,
        genre: MY_LIST,
        data: state.myList.slice(-5),
        filterKey: MY_LIST,
      });
    }

    const browsingList = state.listOfGenreOnBrowse.map((x, index) => ({
      id: x.id,
      name: x.name.toLowerCase(),
      loaded: index === 0,
    }));

    Object.keys(state.cached)
      .filter((x) => x.includes(MOVIES_CACHE_KEY) && !x.includes("-paging"))
      .map((key) => {
        list.push({
          title: state.listOfGenreOnBrowse.find(
            (x) => x.id === key.replace(MOVIES_CACHE_KEY, "")
          ).name,
          genre: key.replace(MOVIES_CACHE_KEY, "").toLowerCase(),
          data: state.cached[key],
          id: key.replace(MOVIES_CACHE_KEY, ""),
          filterKey: state.listOfGenreOnBrowse.find(
            (x) => x.id === key.replace(MOVIES_CACHE_KEY, "")
          ).name,
        });
        browsingList.find(
          (x) => `movies-${x.id}` === key.replace("all-", "").toLowerCase()
        ).loaded = true;
      });
    setMovies(list);
    setBrowseList(browsingList);
  };

  const goToSection = (filterKey, id) => {
    if (filterKey === MY_LIST) {
      history.push('mylist');
    } else if (filterKey !== CONTINUE_WATCHING) {
      history.push(`${category.toLowerCase()}/${id}`);
    }
  };

  useEffect(() => {
    if (!state.selectedProfile && state.profiles.length > 0) {
      setShowProfileChoiceModal(true)
    }
    if(state.selectedProfile)
    {
      setShowProfileChoiceModal(false)
    }
  }, [state.selectedProfile, state.profiles]);

  useEffect(() => {
    if (state.selectedProfile) {
      loadBrowsing();
    }
  }, [state.cached, state.selectedProfile, state.inProgressMovies, state.lookups]);
  useEffect(() => {
    setGenres(state.lookups.allGenres.filter((x) => x.isActive && !x.isCustom));
    if (state.selectedProfile) {
      window.scrollTo(0, 0);
    }
  }, [state.lookups, state.selectedProfile]);

  useEffect(() => {
    if (state.listOfGenreOnBrowse.length > 0) { loadMovies(state.listOfGenreOnBrowse[0].id); }
  }, [state.listOfGenreOnBrowse, state.selectedProfile]);

  useEffect(() => {
    loadBrowsing();
  }, []);

  useEffect(() => {
   if(state.account)
    {
      if (
        state.account?.subscriptions.length === 0 ||
        state.account?.subscriptions[0].status !== 1 ||
        moment(state.account?.subscriptions[0].dateTo) < moment()
      ) {
        setTimeout(() => {
          setShowSubscribeModal(true)
        }, 4000)
      } 
    }
  }, [ state.account]);

  return (
    <>
      {viewGenre && (
        <GenreWrapper>
          <FaRegTimesCircle
            onClick={() => setViewGenre(false)}
            className="inline-block w-12 text-2xl float-right text-white hover:text-gray-300 cursor-pointer"
          />
          <div
            className="text-center w-full mt-10 overflow-y-auto  overflow-x-hidden"
            style={{ height: "80vh" }}
          >
            {genres
              .filter((x) => x.forMovie)
              .map((x) => (
                <div key={x.id} className="text-2xl text-gray-300 my-6 hover:text-white cursor-pointer capitalize">
                  <p
                    className="   transform hover:scale-150  duration-1000 ease-out capitalize inline-block"
                    onClick={() =>
                      history.push(`${category.toLowerCase()}/${x.id}`)}
                  >
                    {x.name}
                  </p>
                </div>
              ))}
          </div>
        </GenreWrapper>
      )}

      {viewSearch && (
        <GenreWrapper>
          <FaRegTimesCircle
            onClick={() => setViewSearch(false)}
            className="inline-block w-12 text-2xl float-right text-white hover:text-gray-300 cursor-pointer"
          />
          <div className="text-center w-full mt-10 overflow-hidden">
            <div className="text-3xl text-gray-300 my-6 hover:text-white cursor-pointer capitalize md:mx-40 mx-2">
              <SearchButton
                term=""
                callBack={(e) => {
                  if (e !== "") history.push(`search/${e}`);
                }}
              />
            </div>
          </div>
        </GenreWrapper>
      )}

      <NavBar className="navbar-browse w-full justify-center">
        <Logo history={history} state={state} dispatch={dispatch} />
        <div className="p-4 flex">
          <HeaderLink
            className="header-link-bold"
            onClick={() => history.push('browse')}
          >
            Movies
          </HeaderLink>
          <HeaderLink
            className="header-link text-gray-300 transform hover:text-white delay-200 duration-200"
            onClick={() => history.push('series')}
          >
            Series
          </HeaderLink>
          <HeaderLink
            className="header-link text-gray-300 transform hover:text-white delay-200 duration-200"
            onClick={() => history.push("mylist")}
          >
            My List
          </HeaderLink>

          <HeaderLink
            className="header-link text-gray-300 transform hover:text-white delay-200 duration-200"
            onClick={() => setViewSearch(true)}
          >
            <FaSearch className="inline-block -mt-1 md:ml-3  transform hover:scale-110 font-semibold  duration-1000 ease-out" />
          </HeaderLink>

          <ProfileCompound history={history} />
        </div>
      </NavBar>

      <HeaderWrapper
        video
        className="header-wrapper-browse relative landscape:hidden"
        state={state}
        dispatch={dispatch}
        category={category}
      />

      {movies.length > 0 && (
        <div className="main-container -mt-52 md:mt-0">
          <AllSlidesWrapper>
            <div className="slide-wrapper mb-12">
              <SlideTitle>
                <div
                  className=" group z-40 text-2xl md:text-3xl capitalize ml-1 flex items-center"
                  onClick={() => setViewGenre(!viewGenre)}
                >
                  <span>Movies</span>
                  <span className="text-blue-500 font-medium">
                    <button className="ml-2 bg-blue-900 text-white opacity-75 p-2 text-sm focus:outline-none z-50 group-hover:bg-blue-500 delay-200 duration-200" type="button">
                      Select Genre
                      <FaChevronRight className="inline-block w-4" />
                    </button>
                  </span>
                </div>
              </SlideTitle>
            </div>
            {movies.map((slideItem) => (
              <SlideWrapper
                key={`${category.toLowerCase()}-${slideItem.title.toLowerCase()}`}
              >
                <SlideTitle>
                  <span
                    className="z-40 text-xl md:text-xl capitalize ml-1"
                    onClick={() => goToSection(slideItem.filterKey, slideItem.id)}
                  >
                    {slideItem.title}
                    {" "}
                    {slideItem.filterKey !== CONTINUE_WATCHING && (
                    <span className="text-blue-500 font-medium transform group-hover:text-blue-400 delay-200 duration-200 ">
                      {" "}
                      <span className="text-xs ml-1">
                        Explore All
                      </span>
                      {" "}
                      <FiChevronRight className="inline-block  text-sm mt-1" />
                      {" "}
                    </span>
                    )}
                  </span>
                </SlideTitle>
                <AllCardsWrapper>
                  {slideItem.data.map((cardItem) => (
                    <CardWrapper key={cardItem.id}>
                      <CardImage
                        onClick={() => {
                          showPlayer(state, dispatch, cardItem);
                          // setActiveItem(cardItem);
                          setActiveGenre(
                            slideItem.filterKey
                              ? slideItem.filterKey
                              : cardItem.genre
                          );
                          setScrollable(true);
                        }}
                        src={cardItem.thumbnail}
                      />
                      {slideItem.filterKey === CONTINUE_WATCHING && (
                      <MovieProgress
                        total={moment.duration(cardItem.totalTime)._milliseconds}
                        current={moment.duration(cardItem.currentTime)._milliseconds}
                      />
                      )}
                      <CardBottom
                        title={cardItem.title}
                        year={cardItem.year}
                        genre={cardItem.genres.filter(gen => !gen.isCustom).slice(0, 3).map(gen => gen.name).join(', ')}
                        rating={cardItem.rating}
                        onClick={() => {
                          setActiveItem(cardItem);
                          // selectMovie(state, dispatch, cardItem);
                          setShowModal(true);
                        }}
                      />
                    </CardWrapper>
                  ))}
                </AllCardsWrapper>

              </SlideWrapper>
            ))}
          </AllSlidesWrapper>
        </div>
      )}
      {movies.length === 0 && (
        <div>
          <AllSlidesWrapper>
            {noMovieLoading}
          </AllSlidesWrapper>
        </div>
      )}

      {isLoading && (
        <div className="px-10 -mt-10">
            {noMovieLoading}
        </div>
      )}

      <Modal
        isOpen={showModal}
        style={customStyles}
        contentLabel="Movie Modal"
        className="flex"
        ariaHideApp={false}
        onRequestClose={() => setShowModal(false)}
        shouldCloseOnOverlayClick
      >
        <MovieCardCompounded
          currentItem={activeItem}
          state={state}
          dispatch={dispatch}
          onClose={() => setShowModal(false)}
        />

      </Modal>

      <Modal 
        isOpen={showSubscribeModal}
        style={customStyles}
        contentLabel="Subscribe Modal"
        className="flex border border-blue-500"
        ariaHideApp={false}
        onRequestClose={() => setShowSubscribeModal(false)}
        shouldCloseOnOverlayClick>
          <div className=" bg-black br-25 text-white text-center py-6 ">
                    <div className="text-xl mb-4 mx-10">
              Subscribe to MayaFlicks!
            </div>
              <div className="text-sm mb-6 mx-10">
              Purchase one of our affordable packages and watch unlimited movies and series.
              </div>
              <div className="text-center my-2 grid grid-flow-row-dense">
                <button
                  className="px-4 py-2 br-25 bg-blue-500 text-sm mx-4 md:mx-40  cursor-pointer"
                  onClick={() => {
                    history.push("/account/manage/subscribe");
                  }}
                >
                  SUBSCRIBE
                </button>
                <div
                  className="px-4 py-2 mr-4 text-xs outline-none cursor-pointer"
                  onClick={() => {
                    setShowSubscribeModal(false)
                  }}
                >
                  Maybe later
                </div>
              </div>
            </div>
      </Modal>

      <Modal 
        isOpen={showProfileChoiceModal}
        style={customFullStyles}
        contentLabel="Profile Choose Modal"
        className="flex w-full"
        ariaHideApp={false}
        onRequestClose={() => setShowProfileChoiceModal(false)}
        >
          <div className="w-full">
            <AllSlidesWrapper
              className="px-10 py-16 min-h-screen"
              style={{
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundColor: "rgb(17, 24, 39)",
              }}
            >
              <div className="mx-auto container text-center max-w-3xl md:w-full py-20">
                <LogoLarge />
                <div className="text-3xl text-white mt-10">Who's Watching ?</div>

                <div className="my-6 flex md:flex-row flex-col justify-center ">
                  {state.profiles.map((x) => (
                    <AccountProfile
                      setProfile={() => {
                    //   mayaAlert.close();
                        selectCurrentProfile(x, state, dispatch, () => {});
                 
                      }}
                      name={x.name}
                      key={x.name}
                    />
                  ))}
                </div>
                <div className="my-6 flex justify-center mt-10">
                  <div
                    onClick={() => {
                    // mayaAlert.close();
                      history.push("account/profiles");
                    }}
                    className="text-gray-400 text-lg md:text-2xl mx-4 transform hover:scale-110  duration-1000 ease-out cursor-pointer border border-gray-400 w-48 md:w-64 p-3 md:p-4"
                  >
                    <div>Manage Profiles</div>
                  </div>
                </div>
              </div>
            </AllSlidesWrapper>
          </div>

      </Modal>

      <PlayerCompound history={history} />

      <FooterCompound />
    </>
  );
}

export default BrowsePage;
