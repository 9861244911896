/* eslint-disable react/jsx-key */
import React from "react";
import { FaCheck } from "react-icons/fa";
import DotSparator from "../components/Header/DotSparator";
import CardDescription from "../components/Movies/CardDescription";
import CardTag from "../components/Movies/CardTag";

function CardTagCompound({ movie, isSeries }) {
  return (
    <>

      <div className="my-6">
        <CardDescription>
          <span className="mr-2 ml-1 text-lg font-semibold">{movie.year}</span>
          <DotSparator />
        
          <span className="border border-gray-600 p-1 rounded mx-2 font-semibold">{movie.rating}</span>
          <DotSparator />
          {!isSeries && <span className="mx-1 font-semibold">{movie.duration}</span> }
          {isSeries && (
          <span className="mx-1 font-semibold">
            {movie.seasons?.length}
            {' '}
            Seasons
          </span>
          ) }
        
          {movie.finished && <span>  
             <DotSparator />
              <span className="mx-1 font-semibold"><FaCheck /> Watched </span>
            </span> 
            }
          

        </CardDescription>
      </div>
      <div className="my-2">
        <CardDescription>
          {movie.genres.filter((x) => !x.isCustom  && x.isActive).map((x, index) => 
          <span key={index}>
            <span className="mx-1 font-semibold capitalize text-sm">{x.name}</span>
              { index + 1 <  movie.genres.filter((x) => !x.isCustom  && x.isActive).length  ? <DotSparator /> : '' }
          </span>
            
          )}
        </CardDescription>
      </div>
      <div className="my-3">
        <CardDescription>
          <div className="text-xs">
            {movie.directors.length > 0 && (
            <div>
              <span className="p-1 rounded text-gray-500">Producer:</span>
              {movie.directors.map((x,index) => (<span key={index} className="p-1 rounded text-white">{x}</span>))}
            </div>
            )}
            {movie.writers.length > 0 && (
            <div>
              <span className="p-1 rounded text-gray-500">Writer:</span>
              {movie.writers.map((x,index) => (<span key={index} className="p-1 rounded text-white">{x}</span>))}
            </div>
            )}
            {movie.cast.length > 0 && (
            <div>
              <span className="p-1 rounded text-gray-500">Cast:</span>
              {movie.cast.map((x, index) => (
                 <span key={index}>
                  <span className="p-1 rounded text-white">{x}</span>
                  {movie.cast.length > index + 1 && <DotSparator /> }
                </span>
              ))}
            </div>
            )}
          </div>

        </CardDescription>
      </div>

    </>
  );
}

export default CardTagCompound;
