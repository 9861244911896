import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import HeaderWrapper from "../components/Header/HeaderWrapper";
import NavBar from "../components/Header/NavBar";
import Logo from "../components/Header/Logo";
import FooterCompound from "../compounds/FooterCompound";
import SignFormWrapper from "../components/SignForm/SignFormWrapper";
import SignFormBase from "../components/SignForm/SignFormBase";
import SignFormTitle from "../components/SignForm/SignFormTitle";
import SignFormInput from "../components/SignForm/SignFormInput";
import SignFormButton from "../components/SignForm/SignFormButton";
import SignFormText from "../components/SignForm/SignFormText";
import SignFormLink from "../components/SignForm/SignFormLink";
import SignFormError from "../components/SignForm/SignFormError";
import OverlayWrapper from "../components/Header/OverlayWrapper";
import { StoreContext } from "../context/Store";
import MayaDataService from "../services/MayaDataService";
import { checkEmailorPhone, mayaAlert, checkPassword } from "../constants";
import LoadingIcon from "../components/Common/LoadingIcon";
import PhoneNumberInput from "../components/SignForm/PhoneNumberInput";

function SignupPage() {
  const history = useHistory();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [state, dispatch] = useContext(StoreContext);
  const [loading, setLoading] = useState(false);


  const IsInvalid =
    password === "" ||
    username === "" ||
    firstName === "" ||
    confirmPassword === "" ||
    !checkEmailorPhone(username) ||
    loading;

  function handleSubmit(event) {
    setLoading(true);
    if (password !== confirmPassword) {
      setError("The two passwords do not match");
      event.preventDefault();
      setLoading(false);
      return;
    }
    if (!checkPassword(password)) {
      setError(
        "Password must be at least 8 characters long and contain a capital letter, a number and a special character."
      );
      event.preventDefault();
      setLoading(false);
      return;
    }

    setError("");
    event.preventDefault();
    let usrname = username;
    if (!username.includes("@") && !username.includes("+")) {
      usrname = `+${username}`;
    }
    MayaDataService.register(firstName, lastName, usrname, password)
      .then((result) => {
        if (result.responseStatus === 0) {
          setError(result.error.message);
          setLoading(false);
        } else {
          let timerInterval;
          mayaAlert.fire({
            title: "Welcome to Mayaflicks!",
            icon: "success",
            html: "You will be redirected to login in a second",
            timer: 2500,
            timerProgressBar: true,
            didOpen: () => {
              mayaAlert.showLoading();
            },
            willClose: () => {
              clearInterval(timerInterval);
              history.push("/signin");
            },
          });
        }
      })
      .catch((e) => {
        setLoading(false);
        setError("");
      });
  }

  const handlePhoneChange = (value) => {
    let countryCode = value.substring(0, 3);
    ///Remove zero from Ethiopian phone number after country code +251[0]911...
    if (countryCode === '251') {
      if (value[4] !== undefined && value[3] === '0') {
        var temp = value.substring(4, value.length);
        value = '251' + temp;
      }
    }
    setUsername(`+${value}`);
  }
  
  return (
    <>
      <HeaderWrapper className="header-wrapper-home">
        <OverlayWrapper>
          <NavBar className="navbar-signin">
            <Logo history={history} state={state} dispatch={dispatch} />
          </NavBar>
          <SignFormWrapper>
            <SignFormBase onSubmit={handleSubmit} method="POST">
              <SignFormTitle>Sign Up</SignFormTitle>
              {error ? <SignFormError>{error}</SignFormError> : null}
              <SignFormInput
                type="text"
                placeholder="First Name"
                value={firstName}
                onChange={({ target }) => setFirstName(target.value)}
                autoComplete="off"
                required

              />
              <SignFormInput
                type="text"
                placeholder="Last Name"
                value={lastName}
                onChange={({ target }) => setLastName(target.value)}
                autoComplete="off"
                required

              />
              <PhoneNumberInput
                username={username}
                onChange={handlePhoneChange}

              />
              <SignFormInput
                type="password"
                placeholder="Password"
                autoComplete="off"
                value={password}
                onChange={({ target }) => setPassword(target.value)}
                required

              />
              <SignFormInput
                type="password"
                placeholder="Confirm Password"
                autoComplete="off"
                value={confirmPassword}
                onChange={({ target }) => setConfirmPassword(target.value)}
                required
              />
              <SignFormButton disabled={IsInvalid}>
                {loading && (
                  <span className="inline-block align-middle">
                    <LoadingIcon />
                  </span>
                )}
                {!loading && (
                  <span className="inline-block align-middle"> Sign Up </span>
                )}
              </SignFormButton>
              <SignFormText>
                Already a user?
                <SignFormLink to="/signin">
                  Sign in now.
                </SignFormLink>
              </SignFormText>
            </SignFormBase>
          </SignFormWrapper>
        </OverlayWrapper>
      </HeaderWrapper>

      <FooterCompound />
    </>
  );
}

export default SignupPage;
