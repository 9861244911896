import React, { useEffect, useRef, useState } from "react";
import { FaChevronLeft, FaChevronRight, FaTimes } from "react-icons/fa";
import "./MoviesStyles.css";
import moment from "moment";
import VimeoPlayer from "@u-wave/react-vimeo";
import Vimeo from "./Vimeo";

function PlayerVideo({
  onBack,
  src,
  title,
  subTitle,
  onUpdateTime,
  start,
  trailer,
  isSeries,
  nextMovie,
  previousMovie,
  movie,
  ...restProps
}) {
  const updateIntervalInMillSeconds = 120000;
  const [visible, setVisible] = useState(false);
  const [visibleTimeoutId, setVisibleTimeoutId] = useState(0);
  const [playerTime, setPlayerTime] = useState(0);
  const [lastSent, setLastSent] = useState();
  const containerRef = useRef();

  const player = useRef();
  const updateTime = () => {
    if (
      playerTime
    ) {
      onUpdateTime(playerTime);
      setLastSent(moment().valueOf());
    }
  };

  const showControls = () => {
    if (!visible) {
      setVisible(true);
    }
    clearTimeout(visibleTimeoutId);

    const timeoutId = setTimeout(() => {
      setVisible(false);
    }, 3000);

    setVisibleTimeoutId(timeoutId);
  };
  useEffect(() => {
    if (
      playerTime &&
      (!lastSent ||
        lastSent + updateIntervalInMillSeconds < moment().valueOf()) &&
      !trailer
    ) { updateTime(); }
  }, [playerTime]);
  useEffect(() => {
    showControls();
  }, [movie]);

  return (
    <div onMouseMove={() => showControls()} className="player-overlay">
      <div
        className={
          visible
            ? "fixed mr-12 top-1 w-10  p-2 text-white hover:text-red-500 cursor-pointer rounded bg-transparent right-0  transform hover:scale-110  duration-200 ease-out"
            : "hidden"
        }
        style={{ zIndex: "10003" }}
        onClick={onBack}
      >
        <FaTimes className="inline-block text-2xl mx-auto ml-1" />
      </div>
      <div
        className={
          !visible
            ? "fixed top-0 w-full h-full  p-2 text-white hover:text-blue-500 cursor-pointer rounded bg-transparent transform hover:scale-110  duration-200 ease-out"
            : "hidden"
        }
        style={{ zIndex: "10006" }}
      />

      {isSeries && visible && !trailer && (
        <>
          {movie.nextMovieId && (
            <div
              className="fixed mr-12 top-40   p-2 text-white hover:text-blue-500 cursor-pointer rounded bg-transparent right-0  transform hover:scale-110  duration-200 ease-out bg-black opacity-75 px-4"
              style={{ zIndex: "10005", top: "40%" }}
              onClick={() => nextMovie()}
            >
              Next Episode
              <FaChevronRight className="inline-block text-2xl mx-auto ml-1" />
            </div>
          )}
          {movie.previousMovieId && (
            <div
              className="fixed mr-12 top-40   p-2 text-white hover:text-blue-500 cursor-pointer rounded bg-transparent left-0  transform hover:scale-110  duration-200 ease-out bg-black opacity-75 px-4"
              style={{ zIndex: "10005", top: "40%" }}
              onClick={() => previousMovie()}
            >
              <FaChevronLeft className="inline-block text-2xl mx-auto ml-1" />
              Previous Episode
            </div>
          )}
        </>
      )}
      {visible && (
        <div
          className="mr-6 inline-block text-xl font-semibold absolute top-0 left-4 mt-2"
          style={{ zIndex: "10003" }}
        >
          {title || ''}
          <br />
          {subTitle}
        </div>
      )}
      <div
        id="handstick"
        className="right-0 w-full bottom-0"
        style={{ zIndex: "10002" }}
        ref={containerRef}
      >
        <Vimeo
          ref={player}
          video={src}
          responsive
          autoplay
          onTimeUpdate={(e) => setPlayerTime(e)}
          onEnd={() => updateTime()}
          onSeeked={() => updateTime()}
          onPause={() => updateTime()}
          onPlay={() => updateTime()}
          start={start}
          showByline
          onMouseMove={() => showControls()}
        />
      </div>
    </div>
  );
}

export default PlayerVideo;
