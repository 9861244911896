/* eslint-disable camelcase */
/* eslint-disable import/named */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/button-has-type */
import React, {
  useContext, useEffect, useState, useRef
} from "react";

import { useHistory, useParams } from 'react-router-dom';
import { SET_PROFILE, SET_TOKEN } from "../context/ActionTypes";
import { StoreContext } from '../context/Store';
import MayaDataService from "../services/MayaDataService";
import { setCurrentProfile } from '../context/Actions';

function AuthPage() {
  const {
    resource, token, profileId, payment
  } = useParams();
  const history = useHistory();
  const [state, dispatch] = useContext(StoreContext);
  const resources = [{
    id: 'manage',
    resource: '/account/manage'
  },
  {
    id: 'giftcards',
    resource: '/account/giftcards'
  },
  {
    id: 'profiles',
    resource: '/account/profiles'
  },
  {
    id: 'billing',
    resource: '/account/billing'
  },
  {
    id: 'signup',
    resource: '/signup'
  },
  {
    id: 'forgot_password',
    resource: '/forgot_password'
  }];

  function useQueryParams() {
    const params = new URLSearchParams(
      window ? window.location.search : {}
    );

    return new Proxy(params, {
      get(target, prop) {
        return target.get(prop);
      },
    });
  }

  const { platform, selected_profile } = useQueryParams();

  useEffect(() => {
    localStorage.setItem('token', token);
    MayaDataService.renewToken(profileId).then((result) => {
      localStorage.setItem('token', result.data.token);
      localStorage.setItem('user', JSON.stringify(result.data.user));
      dispatch({ type: SET_TOKEN, payload: result.data });
      dispatch({
        type: SET_PROFILE,
        payload: result.data.user.profiles,
      });
      setCurrentProfile(result.data.user.profiles[0], dispatch);
      let extra = '?type=mobile';
      if (payment) {
        extra = '?payment=true';
      }
      history.push(`${resources.find((x) => x.id === resource).resource + extra}&platform=${platform}&selected_profile=${selected_profile}`);
    });
  }, []);

  return (
    <>
    </>
  );
}

export default AuthPage;
