import React from "react";
import "./JumbotronStyles.css";

function JumboTitle({ children, ...restProps }) {
  return (
    <div
      className="text-2xl md:text-6xl font-bold h-12 md:h-24 overflow-hidden flex"
      {...restProps}
    >
      {children}
    </div>
  );
}

export default JumboTitle;
