import React from "react";
import DotSparator from "../Header/DotSparator";
import InfoButton from "../Header/InfoButton";

import "./MoviesStyles.css";

function CardBottom({ title, year, genre, rating, ...restProps }) {
  return (
    <div
      className="w-full px-2 py-2 group-hover:text-blue-500 group-hover:text-sm group-hover:bg-gray-700 transition delay-200 duration-100"
      {...restProps}
    >
      <div className="w-full text-sm font-black group-hover:text-sm flex">
        <div className="flex-grow">{title}</div>
        <div className="flex-grow-0 w-2 flex justify-items-end opacity-0 group-hover:opacity-100  transition delay-200 duration-200">
          <InfoButton {...restProps} />
        </div>
      </div>
      <div className="text-sm text-gray-400 inline-block mr-1 opacity-0 group-hover:opacity-100  transition delay-200 duration-200">
        {year}
      </div>
      {rating && (
        <div className="inline-block opacity-0 group-hover:opacity-100 transition delay-200 duration-200 mr-1">
          <DotSparator className="inline-block" />{" "}
          <div className="text-xs inline-flex items-center bg-blue-500 text-white rounded-full px-2 py-1 font-black capitalize  mr-1">
            {rating}
          </div>
          <DotSparator className="inline-block" />{" "}
        </div>
      )}
      <div className="text-sm text-gray-400 capitalize inline-block opacity-0 group-hover:opacity-100 transition delay-200 duration-200">
        {genre}
      </div>
    </div>
  );
}

export default CardBottom;
