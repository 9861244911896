import React from "react";
import "./HeaderStyles.css";
import { FaCircle } from 'react-icons/fa';

function DotSparator({ children, ...restProps }) {
  return (
    <FaCircle className="text-blue-200 mx-1 mb-1 inline-block w-1" />
  );
}

export default DotSparator;
