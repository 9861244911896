/* eslint-disable import/named */
/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-key */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-no-undef */
import React from "react";
import { toast } from 'react-toastify';
import AccountProfile from "../components/ProfileForm/AccountProfile";
import { movieMapper } from "../lib/utils";
import MayaDataService from "../services/MayaDataService";
import LogoLarge from '../components/Header/LogoLarge';

import "animate.css";
import {
  ADD_TO_MY_LIST_REQUEST,
  FETCH_MOVIES_REQUEST,
  FETCH_MOVIES_SUCCESS,
  LOADING_MORE_MOVIES_SUCCESS,
  LOADING_MORE_MYLIST_SUCCESS,
  SELECT_PROFILE,
  SET_CURRENT_MOVIE,
  SET_MY_LIST,
  SET_PLAYER,
  FETCH_SERIES_REQUEST,
  FETCH_SERIES_SUCCESS,
  LOADING_MORE_SERIES_SUCCESS,
  SET_TOKEN,
  SET_PROFILE,
  RESET,
  LOADING_MORE_MY_SERIES_LIST_SUCCESS,
  SET_MY_SERIES_LIST,
  FETCH_FEATURED_REQUEST,
  FETCH_FEATURED_SUCCESS,
  FETCH_INPROGRESS_MOVIES_REQUEST,
  FETCH_INPROGRESS_MOVIES_SUCCESS,
} from "./ActionTypes";
import {
  mayaAlert,
  MY_MOVIES_LIST,
  MY_SERIES_LIST,
  MOVIES_CACHE_KEY,
  SERIES_CACHE_KEY,
} from "../constants";
import AllSlidesWrapper from "../components/Movies/AllSlidesWrapper";

export const removeFromList = (item, dispatch, state, isSeries, callBack) => {
  dispatch({ type: ADD_TO_MY_LIST_REQUEST });
  if (!isSeries) {
    MayaDataService.removeFromMyList(item.id).then((result) => {
      toast.success("Removed from your list");
      getMyList(
        state,
        dispatch,
        MY_MOVIES_LIST,
        { currentPage: 1, pageSize: 10 },
        true
      );
      setBookmark(item, state, dispatch, false);
      if (callBack) callBack();
    });
  } else {
    MayaDataService.removeFromMySeriesList(item.id).then((result) => {
      toast.success("Removed from your list");
      setBookmark(item, state, dispatch, false, SERIES_CACHE_KEY);

      getMySeriesList(
        state,
        dispatch,
        MY_SERIES_LIST,
        { currentPage: 1, pageSize: 10 },
        true
      );
      if (callBack) callBack();
    });
  }
};

export const addToMyList = (item, dispatch, state, isSeries) => {
  dispatch({ type: ADD_TO_MY_LIST_REQUEST });
  if (!isSeries) {
    MayaDataService.addToMyList(item.id).then((result) => {
      toast.success("Added to your list");
      getMyList(
        state,
        dispatch,
        MY_MOVIES_LIST,
        { currentPage: 1, pageSize: 10 },
        true
      );
      setBookmark(item, state, dispatch);
    }
    );
  } else {
    MayaDataService.addToMySeriesList(item.id).then((result) => {
      toast.success("Added to your list");
      getMySeriesList(
        state,
        dispatch,
        MY_SERIES_LIST,
        { currentPage: 1, pageSize: 10 },
        true
      );
      setBookmark(item, state, dispatch, true, SERIES_CACHE_KEY);
    });
  }
};

export const getMovies = (
  state,
  dispatch,
  cacheKey,
  filter,
  force = false,
  fetchingMore = false,
  callBack = null
) => {
  if (!fetchingMore) dispatch({ type: FETCH_MOVIES_REQUEST });

  if (state.cached[cacheKey] && !force && !fetchingMore) {
    dispatch({
      type: FETCH_MOVIES_SUCCESS,
      payload: {
        movies: state.cached[cacheKey],
        currentPage: state.cached[`${cacheKey}-paging`].currentPage,
        pageSize: state.cached[`${cacheKey}-paging`].pageSize,
        totalCount: state.cached[`${cacheKey}-paging`].totalCount,
        totalPages: state.cached[`${cacheKey}-paging`].totalPages,
        type: cacheKey,
      },
    });
  } else {
    MayaDataService.getAllMovies(filter).then((result) => {
      if (!fetchingMore) {
        dispatch({
          type: FETCH_MOVIES_SUCCESS,
          payload: {
            movies: movieMapper(result.data, state),
            type: cacheKey,
            currentPage: result.currentPage,
            pageSize: result.pageSize,
            totalCount: result.totalCount,
            totalPages: result.totalPages,
          },
        });
      } else {
        dispatch({
          type: LOADING_MORE_MOVIES_SUCCESS,
          payload: {
            movies: movieMapper(result.data, state),
            type: cacheKey,
            currentPage: result.currentPage,
            pageSize: result.pageSize,
            totalCount: result.totalCount,
            totalPages: result.totalPages,
          },
        });
      }
      if (callBack) callBack();
    });
  }
};

export const getSeries = (
  state,
  dispatch,
  cacheKey,
  filter,
  force = false,
  fetchingMore = false,
  callBack = null
) => {
  if (!fetchingMore) dispatch({ type: FETCH_SERIES_REQUEST });

  if (state.cached[cacheKey] && !force && !fetchingMore) {
    dispatch({
      type: FETCH_SERIES_SUCCESS,
      payload: {
        series: state.cached[cacheKey],
        currentPage: state.cached[`${cacheKey}-paging`].currentPage,
        pageSize: state.cached[`${cacheKey}-paging`].pageSize,
        totalCount: state.cached[`${cacheKey}-paging`].totalCount,
        totalPages: state.cached[`${cacheKey}-paging`].totalPages,
        type: cacheKey,
      },
    });
  } else {
    MayaDataService.getAllSeries(filter).then((result) => {
      if (!fetchingMore) {
        dispatch({
          type: FETCH_SERIES_SUCCESS,
          payload: {
            series: movieMapper(result.data, state),
            type: cacheKey,
            currentPage: result.currentPage,
            pageSize: result.pageSize,
            totalCount: result.totalCount,
            totalPages: result.totalPages,
          },
        });
      } else {
        dispatch({
          type: LOADING_MORE_SERIES_SUCCESS,
          payload: {
            series: movieMapper(result.data, state),
            type: cacheKey,
            currentPage: result.currentPage,
            pageSize: result.pageSize,
            totalCount: result.totalCount,
            totalPages: result.totalPages,
          },
        });
      }
      if (callBack) callBack();
    });
  }
};

export const getMyList = (
  state,
  dispatch,
  cacheKey,
  filter,
  force = false,
  fetchingMore = false,
  callBack = null
) => {
  if (!fetchingMore) dispatch({ type: ADD_TO_MY_LIST_REQUEST });

  if (state.cached && state.cached[cacheKey] && !force && !fetchingMore) {
    dispatch({
      type: SET_MY_LIST,
      payload: {
        movies: state.cached[cacheKey],
        currentPage: state.cached[`${cacheKey}-paging`].currentPage,
        pageSize: state.cached[`${cacheKey}-paging`].pageSize,
        totalCount: state.cached[`${cacheKey}-paging`].totalCount,
        totalPages: state.cached[`${cacheKey}-paging`].totalPages,
        type: cacheKey,
      },
    });
  } else {
    MayaDataService.getMyList(filter).then((result) => {
      const movies = movieMapper(result.data, state).map((x) => {
        x.isBookmarked = true;
        return x;
      });
      if (!fetchingMore) {
        dispatch({
          type: SET_MY_LIST,
          payload: {
            movies,
            type: cacheKey,
            currentPage: result.currentPage,
            pageSize: result.pageSize,
            totalCount: result.totalCount,
            totalPages: result.totalPages,
          },
        });
      } else {
        dispatch({
          type: LOADING_MORE_MYLIST_SUCCESS,
          payload: {
            movies,
            type: cacheKey,
            currentPage: result.currentPage,
            pageSize: result.pageSize,
            totalCount: result.totalCount,
            totalPages: result.totalPages,
          },
        });
      }
      if (callBack) callBack();
    });
  }
};

export const getMySeriesList = (
  state,
  dispatch,
  cacheKey,
  filter,
  force = false,
  fetchingMore = false,
  callBack = null
) => {
  if (!fetchingMore) dispatch({ type: ADD_TO_MY_LIST_REQUEST });

  if (state.cached && state.cached[cacheKey] && !force && !fetchingMore) {
    dispatch({
      type: SET_MY_SERIES_LIST,
      payload: {
        movies: state.cached[cacheKey],
        currentPage: state.cached[`${cacheKey}-paging`].currentPage,
        pageSize: state.cached[`${cacheKey}-paging`].pageSize,
        totalCount: state.cached[`${cacheKey}-paging`].totalCount,
        totalPages: state.cached[`${cacheKey}-paging`].totalPages,
        type: cacheKey,
      },
    });
  } else {
    MayaDataService.getMySeriesList(filter).then((result) => {
      const movies = movieMapper(result.data, state).map((x) => {
        x.isBookmarked = true;
        return x;
      });

      if (!fetchingMore) {
        dispatch({
          type: SET_MY_SERIES_LIST,
          payload: {
            movies,
            type: cacheKey,
            currentPage: result.currentPage,
            pageSize: result.pageSize,
            totalCount: result.totalCount,
            totalPages: result.totalPages,
          },
        });
      } else {
        dispatch({
          type: LOADING_MORE_MY_SERIES_LIST_SUCCESS,
          payload: {
            movies,
            type: cacheKey,
            currentPage: result.currentPage,
            pageSize: result.pageSize,
            totalCount: result.totalCount,
            totalPages: result.totalPages,
          },
        });
      }
      if (callBack) callBack();
    });
  }
};

export const getFeaturedMovie = (state, dispatch) => {
  dispatch({ type: FETCH_FEATURED_REQUEST });
  MayaDataService.getAllMovies({ isFeatured: true, IncludeEpisodes: true, }).then((result) => {
    if (result && result.data.length > 0) {
      dispatch({
        type: FETCH_FEATURED_SUCCESS,
        payload: movieMapper(result.data, state),
      });
    }
  });
};

export const getInprogressMovies = (state, dispatch) => {
  dispatch({ type: FETCH_INPROGRESS_MOVIES_REQUEST });
  MayaDataService.getInprogressMovie({ pageSize: 5 }).then((result) => {
    dispatch({
      type: FETCH_INPROGRESS_MOVIES_SUCCESS,
      payload: movieMapper(result.data, state),
    });
  });
};

export const showPlayer = (state, dispatch, activeItem, isTrailer) => {
  dispatch({
    type: SET_CURRENT_MOVIE,
    payload: activeItem || state.headerMovie,
  });
  dispatch({
    type: SET_PLAYER,
    payload: {
      playerOn: true,
      isPlayerTrailer: isTrailer,
    },
  });
};

export const setCurrentProfile = (profile, dispatch) => {
  dispatch({ type: SELECT_PROFILE, payload: profile });
  localStorage.setItem("profile", JSON.stringify(profile));
};

export const selectCurrentProfile = (
  profile,
  state,
  dispatch,
  loadingCallback,
  history
) => {
  if (loadingCallback) loadingCallback(true);
  MayaDataService.renewToken(profile.id).then((result) => {
    localStorage.setItem("token", result.data.token);
    localStorage.setItem("user", JSON.stringify(result.data.user));
    dispatch({ type: SET_TOKEN, payload: result.data });
    dispatch({
      type: SET_PROFILE,
      payload: result.data.user.profiles,
    });
    setCurrentProfile(profile, dispatch);
    dispatch({ type: RESET, payload: {} });
    getMyList(state, dispatch, MY_MOVIES_LIST, {}, true);
    getMySeriesList(state, dispatch, MY_SERIES_LIST, {}, true);
    getInprogressMovies(state, dispatch);
    if (loadingCallback) loadingCallback(false);
    if (history) history.push("/browse");
  });
};

export const chooseProfile = (state, dispatch, history, playOn) => {
  mayaAlert.fire({
    showConfirmButton: false,
    showClass: {
      popup: "animate__animated animate__fadeIn animate__faster",
    },
    hideClass: {
      popup: "animate__animated animate__fadeOut animate__faster",
    },
    width: "100%",
    background: 'transparent',
    html: (
      <div className="-mx-20 -my-10">
        <AllSlidesWrapper
          className="px-10 py-16 min-h-screen"
          style={{
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundColor: "rgb(17, 24, 39)",
          }}
        >
          <div className="mx-auto container text-center max-w-3xl md:w-full py-20">
            <LogoLarge />
            <div className="text-3xl text-white mt-10">Who's Watching ?</div>

            <div className="my-6 flex md:flex-row flex-col justify-center ">
              {state.profiles.map((x) => (
                <AccountProfile
                  setProfile={() => {
                    mayaAlert.close();
                    selectCurrentProfile(x, state, dispatch, () => {});
                  }}
                  name={x.name}
                  key={x.name}
                />
              ))}
            </div>
            <div className="my-6 flex justify-center mt-10">
              <div
                onClick={() => {
                  mayaAlert.close();
                  history.push("account/profiles");
                }}
                className="text-gray-400 text-lg md:text-2xl mx-4 transform hover:scale-110  duration-1000 ease-out cursor-pointer border border-gray-400 w-48 md:w-64 p-3 md:p-4"
              >
                <div>Manage Profiles</div>
              </div>
            </div>
          </div>
        </AllSlidesWrapper>
      </div>
    ),
  });
};

const setBookmark = (
  movie,
  state,
  dispatch,
  bookmark = true,
  type = MOVIES_CACHE_KEY
) => {
  const cacheKey = `${type}${movie.genre}`.toLowerCase();
  if (state.cached[cacheKey]) {
    state.cached[cacheKey].find(
      (x) => x.id === movie.id
    ).isBookmarked = bookmark;

    dispatch({
      type:
        type === MOVIES_CACHE_KEY ? FETCH_MOVIES_SUCCESS : FETCH_SERIES_SUCCESS,
      payload: {
        movies: state.cached[cacheKey],
        currentPage: state.cached[`${cacheKey}-paging`].currentPage,
        pageSize: state.cached[`${cacheKey}-paging`].pageSize,
        totalCount: state.cached[`${cacheKey}-paging`].totalCount,
        totalPages: state.cached[`${cacheKey}-paging`].totalPages,
        type: cacheKey,
      },
    });
  }
};

export const cacheReset = (state, dispatch) => {
  dispatch({ type: RESET, payload: {} });
  getMyList(state, dispatch, MY_MOVIES_LIST, {}, true);
  getMySeriesList(state, dispatch, MY_SERIES_LIST, {}, true);
  getInprogressMovies(state, dispatch);
  getFeaturedMovie(state, dispatch);
};
