/* eslint-disable react/no-children-prop */
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { FaCheckCircle, FaHome } from "react-icons/fa";
import MayaDataService from "../services/MayaDataService";
import { StoreContext } from "../context/Store";
import { logout, setSession } from "../lib/utils";
import { SET_TOKEN } from "../context/ActionTypes";
import LoadingIcon from "../components/Common/LoadingIcon";
import SignFormError from "../components/SignForm/SignFormError";

function VerifyPage() {
  const history = useHistory();
  const [state, dispatch] = useContext(StoreContext);
  const { email, code } = useParams();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);

  const verify = () => {
    setLoading(true);
    MayaDataService.verifyEmail(email, code).then((result) => {
      if (result.responseStatus === 1) {
        if (localStorage.getItem('token')) logout();
      } else setError(`${result.error.message}: Link expired/used before`);

      setLoading(false);
    }).catch((e) => {
      setError(e.error.message);
      setLoading(false);
    });
  };

  useEffect(() => {
    verify();
  }, []);
  return (
    <>

      {loading && (
      <div className="h-screen flex items-center justify-center">
        Verifying...
        <span className="inline-block align-middle ml-2">
          <LoadingIcon />
        </span>
      </div>
      )}
      {!loading && (
      <div className="h-screen flex flex-col items-center justify-center">
        <div>
          {error ? <SignFormError>{error}</SignFormError> : (
            <div className="text-lg my-4 text-white">
              <FaCheckCircle className="inline-block -mt-1 mr-1 text-green-500" />
              Email Verified
            </div>
          )}
        </div>

        <div>
          <span
            className="text-gray-500 text-lg capitalize cursor-pointer hover:text-white mb-1"
            onClick={() => history.push("/")}
          >
            <FaHome className="inline-block -mt-1 mr-1" />
            Back to Home
          </span>
        </div>
      </div>
      )}
    </>
  );
}

export default VerifyPage;
