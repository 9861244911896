import React from "react";


function ProfileIcon({ letters, ...restProps }) {
  return (
    <div className="bg-blue-500 items-center  justify-center rounded p-8 md:p-16">
      <span className="text-white text-3xl md:text-6xl uppercase">{letters}</span>
    </div>
  );
}

export default ProfileIcon;
