import React from "react";
import "./HeaderStyles.css";
import { FaCut, FaPlay } from 'react-icons/fa';

function TrailerPlayButton({ children, ...restProps }) {
  return (
    <button className="hover:text-blue-500 hover:border-blue-500 md:mx-2 px-2 focus:outline-none" type="button" {...restProps}>
      <FaCut className="inline-block mr-3 text-xl -mt-1"/>
      {children}
    </button>
  );
}

export default TrailerPlayButton;
