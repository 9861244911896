import React from "react";

function ProfileFormButton({ children, ...restProps }) {
  return (
    <button type="button" className="text-white w-full  rounded-md text-center bg-blue-500 py-2 px-4  items-center focus:outline-none my-2 md:my-0" {...restProps}>
      {children}
    </button>
);
}

export default ProfileFormButton;
