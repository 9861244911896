/* eslint-disable react/button-has-type */
import React, { useContext, useEffect, useState } from "react";
import {
  FaChevronLeft, FaSync, FaTrashAlt, FaExclamationTriangle, FaCheckCircle, FaClipboard, FaQuestion, FaCcPaypal, FaStripe
} from "react-icons/fa";
import { useHistory, useParams } from 'react-router-dom';
import CurrencyFormat from 'react-currency-format';
import moment from 'moment';
import FooterCompound from "../compounds/FooterCompound";
import Logo from "../components/Header/Logo";
import NavBar from "../components/Header/NavBar";
import GiftCardForm from "../components/ProfileForm/GiftCardForm";
import ProfileCompound from "../compounds/ProfileCompound";
import { StoreContext } from '../context/Store';
import AllSlidesWrapper from "../components/Movies/AllSlidesWrapper";
import {CopyToClipboard} from 'react-copy-to-clipboard'
import LoadingIcon from "../components/Common/LoadingIcon";
import NoContent from "../components/Common/NoContent";
import TableCompounded from "../compounds/TableCompounded";
import ProfileFormButton from "../components/ProfileForm/ProfileFormButton";
import MayaDataService from "../services/MayaDataService";
import { mayaAlert } from "../constants";
import { SET_PLAYER } from "../context/ActionTypes";

function BillingPage() {
  const history = useHistory();
  const [state, dispatch] = useContext(StoreContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [giftCards, setGiftCards] = useState([]);
  const [loading, setLoading] = useState(false);
  function useQueryParams() {
    const params = new URLSearchParams(
      window ? window.location.search : {}
    );

    return new Proxy(params, {
        get(target, prop) {
            return target.get(prop)
        },
    });
}

  const {type} = useQueryParams();

  const get = () => {
    setLoading(true);
    MayaDataService.subscriptions({ currentPage }).then((result) => {
      setGiftCards(result.data);
      setLoading(false);
      });
  };

  const payment = (payment) => {
    if (payment.toLowerCase() === "paypal")  return <FaCcPaypal className="text-white text-2xl  md:text-5xl ml-6" />
    else if (payment.toLowerCase() === "stripe")  return <FaStripe className="text-white text-2xl  md:text-5xl ml-6" />
    else return <span>{payment}</span>
  }

 
  
  useEffect(() => {
    get();
    dispatch({
      type: SET_PLAYER,
      payload: {
        playerOn: false,
      }});
    window.scrollTo(0,0);
  }, []);

  return (
    <>
       {type !== 'mobile'  && 
       <NavBar className="navbar-browse w-full">
        <Logo history={history}  state={state} dispatch={dispatch} />
        <ProfileCompound history={history} />
      </NavBar>
      }
      <AllSlidesWrapper className={["mb-10 mx-4 md:mx-10 min-h-screen", type === 'mobile' ? '' : ' py-16'].join(' ')}>

        <div className="mx-auto container max-w-6xl md:w-full pt-10">
        {type !== 'mobile'  &&   <div className="-ml-2">
            <button
              onClick={() => history.push('/browse')}
              className="inline-flex items-center focus:outline-none mr-3 text-gray-500 mt-1 hover:text-gray-400  text-lg"
            >
              <FaChevronLeft className="text-center   text-xs mx-1" />
              Back
            </button>
          </div> }

          <div className="text-2xl font-medium my-2 w-1/2 md:w-5/6 inline-block">

           Billing Details
          </div>
          <hr />
        </div>
        {loading && (

        <NoContent>
          <div className="flex justify-center">
            <LoadingIcon />
            Fetching
          </div>
        </NoContent>

        )}
        {!loading && (
        <TableCompounded
          // actionButtons
            // deleteCallback={(id) => onDelete(id, deleteUser)}
            // editCallback={(id) => onEdit(id)}
          ids={giftCards.map((x) => x.id)}
          headers={['Date', 'Package', 'Payment method','Service Period', 'Amount']}
          data={giftCards.filter((x) => x.payment).map((x) =>
            [
              moment(x.createdOn).format("MM/DD/YYYY"),
              state.lookups.subscriptionPackages.find((y) => y.id === x.payment.package)?.description,
              payment(x.payment.paymentMethodName),
              `${ moment(x.dateFrom).format("MM/DD/YYYY")}—${ moment(x.dateTo).format("MM/DD/YYYY")}`,
              x.payment.amount + `${x.payment.currency === 1 ? ' ETB' : ' USD'}`,
              

            ]
          )}
        />
        )}
      </AllSlidesWrapper>

      <FooterCompound />
    </>
  );
}

export default BillingPage;
