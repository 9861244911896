/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-key */
import React from 'react';
import TableActionButtonGroup from './TableActionButtonGroupCompounded';

const TableCompounded = ({
  detailCallback, headers, data, actionButtons, ids, deleteCallback, editCallback
}) => (
  <table className="mx-auto container max-w-6xl md:w-full">
    <thead>
      <tr className="text-xs md:text-sm  flex-col flex-no border-b border-gray-300 wrap table-row rounded-l-lg sm:rounded-none mb-2 sm:mb-0">
        {headers.map((item) => (
          <th key={item} className="text-left border-b px-4 py-2 font-medium">{item}</th>
        ))}
      </tr>
    </thead>
    <tbody className="flex-1 sm:flex-none text-xs md:text-base">

      {data.map((item, index) => (
        <tr key={ids[index]} className=" flex-col flex-no wrap table-row mb-2 sm:mb-0">
          {item.map((i, idx) => (
            <td key={`${idx}-item`} className="text-left border-b p-4">{i}</td>
          ))}

          {actionButtons && (
            <td className="text-left border-b p-4">

              <TableActionButtonGroup
                deleteAction={() => (deleteCallback ? deleteCallback(ids[index]) : {})}
              />
              {detailCallback && (
              <TableActionButtonGroup
                detailAction={() => (detailCallback ? detailCallback(ids[index]) : {})}
              />
              )}
              {editCallback && (
              <TableActionButtonGroup
                editAction={() => (editCallback ? editCallback(ids[index]) : {})}
              />
              )}

            </td>
          )}

        </tr>
      ))}

    </tbody>
  </table>

);

export default TableCompounded;
