import React from "react";

function ProfileFormSection({ children, ...restProps }) {
  return (
    <div className="flex flex-col md:flex-row gap-10 md:gap-24 w-full  p-4 " {...restProps}>
      {children}
    </div>
  );
}

export default ProfileFormSection;
