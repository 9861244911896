/* eslint-disable react/button-has-type */
import React, { useContext, useEffect, useState } from "react";
import { FaTimesCircle } from "react-icons/fa";
import PlayerOverlay from "../components/Movies/PlayerOverlay";
import PlayerVideo from "../components/Movies/PlayerVideo";
import { StoreContext } from "../context/Store";
import { SET_CURRENT_MOVIE, SET_PLAYER } from "../context/ActionTypes";
import { getUUID, mayaAlert } from "../constants";
import moment from "moment";
import MayaDataService from "../services/MayaDataService";
import { movieMapper } from "../lib/utils";
import LoadingIcon from "../components/Common/LoadingIcon";

function PlayerCompound({ history }) {
  const [state, dispatch] = useContext(StoreContext);
  const [start, setStart] = useState(0);
  const [isReady, setIsReady] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [movie, setMovie] = useState();

  const updateTime = (time) => {
    MayaDataService.updateMovieStatus({
      movieId: state.currentMovie.id,
      sessionId: state.currentMovie.sessionId
        ? state.currentMovie.sessionId
        : state.sessionId,
      finished: time.percent >= 0.95 ? true : false,
      currentTime: moment()
        .startOf("day")
        .seconds(time.seconds)
        .format("H:mm:ss"),
    }).then((result) => {
      if (result.responseStatus !== 1) {
        mayaAlert.fire({
          showConfirmButton: false,
          html: (
            <div className="bg-black text-white text-center py-16 -mx-20 -my-10">
              <div className="text-lg mb-4">{result.error.message}</div>
              <div className="text-center my-2"></div>
            </div>
          ),
        });

        dispatch({
          type: SET_PLAYER,
          payload: {
            playerOn: false,
          },
        });
        dispatch({
          type: SET_CURRENT_MOVIE,
          payload: null,
        });
      }
    });
  };

  const setCurrentMovie = (id) => {
    console.log(id);
    //setIsReady(false);
    dispatch({
      type: SET_CURRENT_MOVIE,
      payload: { id: id },
    });
    setIsReady(false);
  };
  const getMovie = (id) => {
    MayaDataService.getMovie(id, state.sessionId).then((result) => {
      if (result.responseStatus === 1) {
        setMovie(movieMapper([result.data], state)[0]);
        if (result.data.currentTime) {
          setStart(
            moment.duration(result.data.currentTime)._milliseconds / 1000
          );
        }

        // dispatch({
        //   type: SET_CURRENT_MOVIE,
        //   payload: movieMapper([result.data], state)[0],
        // });

        setIsReady(true);
      } else {
        mayaAlert.fire({
          showConfirmButton: false,
          html: (
            <div className="bg-black text-white text-center py-16 -mx-20 -my-10">
              <div className="text-lg mb-4">{result.error.message}</div>
              <div className="text-center my-2"></div>
            </div>
          ),
        });
      }
    });
  };


  useEffect(() => {
    setIsReady(false);
  }, []);
  useEffect(() => {
    if (state.currentMovie && !state.isPlayerTrailer && state.playerOn && !isReady ) {
      if (
        state.account?.subscriptions.length === 0 ||
        state.account?.subscriptions[0].status !== 1 ||
        moment(state.account?.subscriptions[0].dateTo) < moment()
      ) {
        mayaAlert.fire({
          showConfirmButton: false,
          showClass: {
            popup: "animate__animated animate__fadeIn animate__faster",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOut animate__faster",
          },
          width: "auto",
          background: 'transparent',
          html: (
            <div className="bg-black br-25 text-white text-center py-6 -my-10 border-2xl">
                <div className="text-xl mb-4 mx-10">
                  Subscribe to MayaFlicks!
                </div>
                <div className="text-sm mb-6 mx-10">
                Purchase one of our affordable packages and watch unlimited movies and series.
                </div>
                <div className="text-center my-2 grid grid-flow-row-dense">
                  <button
                    className="px-4 py-2 bg-blue-500  br-25 text-sm mx-4 md:mx-40"
                    onClick={() => {
                      history.push("/account/manage/subscribe");
                      mayaAlert.close();
                    }}
                  >
                    SUBSCRIBE
                  </button>
                  <button
                    className="px-4 py-2 mr-4 text-xs"
                    onClick={() => {
                      mayaAlert.close();
                    }}
                  >
                    Maybe later
                  </button>
                </div>
              </div>
          ),
        });
        dispatch({
          type: SET_CURRENT_MOVIE,
          payload: null,
        });
        dispatch({
          type: SET_PLAYER,
          payload: {
            playerOn: false,
          },
        });
        setIsReady(false);
        setHasError(true);
      }
      else if(state.currentMovie.isForOnlyMobile)
      {
        mayaAlert.fire({
          showConfirmButton: false,
          showClass: {
            popup: "animate__animated animate__fadeIn animate__faster",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOut animate__faster",
          },
          width: "auto",
          background: 'transparent',
          html: (
            <div className="bg-black text-white text-center py-6 -my-10">
                <div className="text-xl mb-4 mx-10">
                This movie is only available on mobile apps
                </div>
                <div className="text-sm mb-6 mx-10">
                  Please install from Google Play or Appstore.
                </div>
           
              </div>
          ),
        });
        dispatch({
          type: SET_CURRENT_MOVIE,
          payload: null,
        });
        dispatch({
          type: SET_PLAYER,
          payload: {
            playerOn: false,
          },
        });
        setIsReady(false);
        setHasError(true);
      }

      else {


        getMovie(state.currentMovie.id);
        setHasError(false);
      }
    } else if (state.currentMovie && state.currentMovie.trailer) {
      


      setIsReady(true);
      getMovie(state.currentMovie.id);
      setHasError(false);
      // setMovie(state.currentMovie);
    }
  }, [state.currentMovie,state.isPlayerTrailer]);

  return (
    <>
      {state.playerOn && !hasError && (
        <PlayerOverlay>
          {!isReady && (
            <div className="flex justify-center my-40">
              <LoadingIcon />
              Loading
            </div>
          )}
          {isReady && movie && (
            <div className="px-2">
              <PlayerVideo
                src={state.isPlayerTrailer ? movie.trailer : movie.mainMovie}
                start={start}
                trailer={state.isPlayerTrailer}
                isSeries={movie.isSeries}
                movie={movie}
                nextMovie={() =>
                  movie.nextMovieId
                    ? setCurrentMovie(movie.nextMovieId)
                    : null
                }
                previousMovie={() =>
                  movie.previousMovieId
                    ? setCurrentMovie(movie.previousMovieId)
                    : null
                }
                title={
                  state.isPlayerTrailer
                    ? "Trailer: " +movie.title
                    : movie.title
                }
                subTitle={movie.subtitle}
                onUpdateTime={updateTime}
                onBack={() => {
                  dispatch({
                    type: SET_PLAYER,
                    payload: {
                      playerOn: false,
                    },
                  });
                  dispatch({
                    type: SET_CURRENT_MOVIE,
                    payload: null,
                  });
                  setIsReady(false);
                }}
              />
            </div>
          )}
        </PlayerOverlay>
      )}
    </>
  );
}

export default PlayerCompound;
