import React from "react";
import "./HeaderStyles.css";
import { FaCut, FaPlay } from 'react-icons/fa';

function InfoButton({ children, ...restProps }) {
  return (
    <button className="focus:outline-none text-blue-500 hover:text-white " type="button" {...restProps}>
      <svg xmlns="http://www.w3.org/2000/svg" className="inline-block h-6 w-6 md:mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
        <path strokeLinecap="round" strokeLinejoin="round" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
      </svg>
      {children}
    </button>
  );
}

export default InfoButton;
