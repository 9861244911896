import Firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/auth';

const config = {
  apiKey: "AIzaSyBU7wh9B8OzfcRhC5r5rwFtG4b9Z5SI3VQ",
  authDomain: "mayaflix-f2b92.firebaseapp.com",
  projectId: "mayaflix-f2b92",
  storageBucket: "mayaflix-f2b92.appspot.com",
  messagingSenderId: "955696745067",
  appId: "1:955696745067:web:9db7df9c8d074a71713f2e",
  measurementId: "G-YTMCMF9WZ9"
};

const firebase = Firebase.initializeApp(config);

Firebase.analytics();

export { firebase };
