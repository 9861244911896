import moment from "moment";
import { getUUID } from "../constants";
import { SET_SESSION } from "../context/ActionTypes";
import "moment-duration-format";
export const movieMapper = (data, state) => {
  const mapped = data.map((x) => ({
    id: x.id,
    title: x.title,
    description: x.description,
    episodeNumber: x.episodeNumber,
    category: x.category,
    genres: x.genres,
    rating: state.lookups.ratings?.find((y) => x.rating === y.id)
      ? state.lookups.ratings.find((y) => x.rating === y.id).description
      : "Not rated",
    year: moment(x.releaseDate).year().toString(),
    duration: x.duration
      ? moment.duration(x.duration, "minutes").format("h[h] m[m]")
      : "",
    thumbnail:
      x.files?.length > 0 && x.files.some((x) => x.fileType === 4)
        ? x.files.reverse().find((x) => x.fileType === 4)?.url
        : "/images/misc/default.png",
    mainPic:
      x.files?.length > 0 && x.files.some((x) => x.fileType === 3)
        ? x.files.find((x) => x.fileType === 3)?.url
        : "/images/misc/default.png",
    banner:
      x.files?.length > 0 && x.files.some((x) => x.fileType === 2)
        ? x.files.find((x) => x.fileType === 2)?.url
        : "/images/misc/default.png",
    trailer: x.trailerUrl,
    mainMovie: x.movieUrl,
    seasons: x.seasons ? x.seasons : [],
    directors: x.artists?.some((f) => f.role === 3)?
    x.artists.filter((f) => f.role === 2)
      .map((a) => `${a.artist?.firstName} ${a.artist?.lastName}`): [],
    writers: x.artists?.some((f) => f.role === 3)?
      x.artists.filter((f) => f.role === 3)
      .map((a) => `${a.artist?.firstName} ${a.artist?.lastName}`): [],
    cast: x.artists?.some((f) => f.role === 1) ?
    x.artists.filter((f) => f.role === 1)
      .map((a) => `${a.artist?.firstName} ${a.artist?.lastName}`): [],
    isSeries: x.seasonId ? true : false,
    subtitle: x.seasonId
      ? `${
          state.series
            .find((f) => f.seasons.some((s) => s.id === x.seasonId))
            ?.seasons.find((s) => s.id === x.seasonId).title
        } : E${x.episodeNumber}`
      : "",
    isBookmarked: x.isBookmarked,
    isFeatured: x.isFeatured,
    isPublished: x.isPublished,
    currentTime: x.currentTime,
    totalTime: x.duration * 60 * 1000,
    sessionId: x.sessionId,
    nextMovieId: x.nextMovieId,
    previousMovieId: x.previousMovieId,
    isForOnlyMobile: x.isForOnlyMobile
  }));

  return mapped;
};

// LOGOUT
export const logout = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("user");
  localStorage.removeItem("profile");
  localStorage.removeItem("sessionId");

  window.location.replace("/");
};

// LOGIN STATUS
export const isLogin = () => {
  if (localStorage.getItem("token")) return true;
  return false;
};

export const setSession = (dispatch) => {
  if (localStorage.getItem("sessionId")) {
    dispatch({
      type: SET_SESSION,
      payload: localStorage.getItem("sessionId"),
    });
  } else {
    var sessionId = getUUID();
    localStorage.setItem("sessionId", sessionId);
    dispatch({
      type: SET_SESSION,
      payload: getUUID(),
    });
  }
};
