/* eslint-disable react/jsx-key */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */
import React, { useContext, useState, useEffect } from "react";
import { useHistory, useParams } from 'react-router-dom';
import { FaHome, FaRegTimesCircle, FaVideoSlash } from "react-icons/fa";
import { FiChevronDown } from "react-icons/fi";
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import Modal from 'react-modal';
import NavBar from "../components/Header/NavBar";
import Logo from "../components/Header/Logo";
import ProfileLink from "../components/Header/ProfileLink";
import AllSlidesWrapper from "../components/Movies/AllSlidesWrapper";
import FooterCompound from "../compounds/FooterCompound";
import NoContent from "../components/Common/NoContent";
import { StoreContext } from '../context/Store';
import AllCardsWrapper from "../components/Movies/AllCardsWrapper";
import CardImage from "../components/Movies/CardImage";
import CardWrapper from "../components/Movies/CardWrapper";
import PlayerCompound from "../compounds/PlayerCompound";
import {
  getMovies, getSeries, showPlayer
} from "../context/Actions";
import SearchButton from "../components/Header/SearchButton";
import LoadingIcon from "../components/Common/LoadingIcon";
import ProfileCompound from "../compounds/ProfileCompound";
import CardBottom from "../components/Movies/CardBottom";
import { MOVIES, SERIES, customStyles } from "../constants";
import { SET_PLAYER } from "../context/ActionTypes";
import GenreWrapper from "../components/Header/GenreWrapper";
import MovieCardCompounded from "../compounds/MovieCardCompounded";
import MayaDataService from "../services/MayaDataService";
import { movieMapper } from "../lib/utils";

function SearchPage() {
  const history = useHistory();
  const { term } = useParams();
  const [searchTerm, setSearchTerm] = useState(term);
  const [sortByView, setSortByView] = useState();
  const [selectedMovie, setSelectedMovie] = useState();
  const [sortBy, setSortBy] = useState();
  const [category, setCategory] = useState(MOVIES);
  const [state, dispatch] = useContext(StoreContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [loadingMode, setLoadingMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  useBottomScrollListener(() => {
    if (!isLoading && currentPage <= state.cached[`search-${category}-${searchTerm.toLowerCase()}`].totalPages && loadingMode) {
      setCurrentPage(currentPage + 1);
    }
  });

  const onSearchChange = (key) => {
    setLoadingMode(false);
    setSearchTerm(key);
    setCurrentPage(1);
  };

  useEffect(() => {
    if (category === MOVIES) {
      getMovies(state, dispatch, `search-${MOVIES}-${searchTerm.toLowerCase()}`, { searchTerm: searchTerm.toLowerCase(), currentPage, pageSize }, false, loadingMode, () => {
        setIsLoading(false);
        setLoadingMode(false);
      });
    } else if (category === SERIES) {
      getSeries(state, dispatch, `search-${SERIES}-${searchTerm.toLowerCase()}`, { searchTerm: searchTerm.toLowerCase(), currentPage, pageSize }, false, loadingMode, () => {
        setIsLoading(false);
        setLoadingMode(false);
      });
    }
  }, [searchTerm, currentPage, category]);

  
  const onSeasonSelect = (seasonId, withPlayBack) => {
    MayaDataService.getAllMovies({ seasonId, pageSize: 10 }).then((result) => {
      var data =  movieMapper(result.data, state).sort(function (a, b) {
        return a.episodeNumber - b.episodeNumber;
      });
      if(withPlayBack)
        showPlayer(state, dispatch, data[0]);
    });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch({
      type: SET_PLAYER,
      payload: {
        playerOn: false,
      }
    });
  }, []);

  useEffect(() => {
    if (!state.selectedProfile) {
      history.push('/browse')
    }
  }, [state.selectedProfile]);

  return (
    <>
      <NavBar className="navbar-browse w-full">
        <Logo history={history} state={state} dispatch={dispatch} />
        <ProfileCompound history={history} />
      </NavBar>
      {sortByView && (
      <GenreWrapper>
        <FaRegTimesCircle onClick={() => setSortByView(false)} className="inline-block w-12 text-2xl float-right text-white hover:text-gray-300 cursor-pointer" />
        <div className="text-center w-full mt-10 overflow-y-auto  overflow-x-hidden" style={{ height: "80vh" }}>
          {[{
            id: 1,
            name: 'Latest'
          },
          {
            id: 1,
            name: 'Oldest'
          },
          {
            id: 2,
            name: 'Longest'
          },
          {
            id: 2,
            name: 'Shortest'
          }
          ].map((x) => (
            <div
              className="text-2xl text-gray-300 my-6 hover:text-white cursor-pointer capitalize"
            >
              <p
                className="   transform hover:scale-150  duration-1000 ease-out capitalize inline-block"
                onClick={() => setSortBy(x.id)}
              >
                {x.name}
              </p>
            </div>
          ))}

        </div>

      </GenreWrapper>
      )}
      <AllSlidesWrapper className="mt-0 mx-10 pt-16 min-h-screen">
        <div className="text-3xl text-gray-300 my-6 hover:text-white cursor-pointer capitalize md:mx-40 mx-2">
          <SearchButton term={term} callBack={(e) => ((!state.isFetching && e !== '') ? onSearchChange(e) : {})} />
        </div>
        {state.isFetching && (

        <NoContent>
          <div className="flex justify-center">
            <LoadingIcon />
            Searching
          </div>
        </NoContent>

        )}
        {!state.isFetching && (
        <div className="w-full">
          <div className="w-1/3 inline-block md:px-10 px-1 mb-4">
            <div className="">
              <span
                className="text-gray-500 text-lg capitalize cursor-pointer hover:text-white mb-1"
                onClick={() => history.goBack()}
              >
                <FaHome className="inline-block -mt-1" />
                {' '}
                Home
              </span>
            </div>

            <div className="">
              <span className="capitalize font-semibold text-lg">
                Search Result
              </span>
            </div>
          </div>

          <div className="w-1/3 inline-block text-center">
            <span className={`${category === MOVIES ? "text-white border-b border-white" : "text-gray-400 hover:text-white hover:border-white border-b border-transparent transition ease-in-out duration-700"} capitalize font-semibold text-lg cursor-pointer pb-1`} onClick={() => setCategory(MOVIES)}>
              Movies
            </span>
            <span className={`${category === SERIES ? "text-white border-b border-white" : "text-gray-400 hover:text-white hover:border-white border-b border-transparent transition ease-in-out duration-700"} capitalize font-semibold text-lg cursor-pointer ml-4  pb-1`} onClick={() => setCategory(SERIES)}>
              Series
            </span>
          </div>

          <div className="w-1/3 inline-block align-top text-right  md:px-10 px-1 mb-4 hidden">
            <div className="">
              {!state.isFetching && <div className="mt-1 text-right text-gray-500">Sort By</div> }

            </div>
            <div className="transform hover:scale-105  duration-1000 ease-out cursor-pointer w-48 float-right" onClick={() => setSortByView(true)}>

              <span className="capitalize font-semibold text-lg">
                Latest
                {' '}
                <FiChevronDown className="inline-block ml-1  text-lg " />
              </span>
            </div>
          </div>

        </div>
        )}

        {category === MOVIES && (
        <>
          {state.movies.length === 0 && !state.isFetching && (

          <NoContent>
            <div>
              <span className="text-4xl font-semibold">No movies found</span>
            </div>
            <div>
              <span className="text-sm text-gray-500">Try other search terms.</span>
            </div>
            <br />
          </NoContent>

          )}
          {state.movies.length > 0 && !state.isFetching && (

          <div className="w-full">

            <AllCardsWrapper>
              {state.movies.map((item) => (
                <CardWrapper key={item.id}>
                  <CardImage
                    onClick={() => {
                      showPlayer(state, dispatch, item);
                    }}
                    src={item.thumbnail}
                  />
                  <CardBottom
                    title={item.title}
                    year={item.year}
                    genre={item.genres.filter(gen => !gen.isCustom).slice(0, 3).map(gen => gen.name).join(', ')}
                    rating={item.rating}
                    onClick={() => {
                      setSelectedMovie(item);
                    }}
                  />
                  {' '}

                </CardWrapper>
              ))}
              {isLoading && (
              <div className="flex justify-center my-6">
                <LoadingIcon />
                Loading
              </div>
              )}
            </AllCardsWrapper>

          </div>

          )}
        </>
        )}

        {category === SERIES && (
        <>
          {state.series.length === 0 && !state.isFetching && (

          <NoContent>
            <div>
              <span className="text-4xl font-semibold">No series found</span>
            </div>
            <div>
              <span className="text-sm text-gray-500">Try other search terms.</span>
            </div>
            <br />
          </NoContent>

          )}
          {state.series.length > 0 && !state.isFetching && (

          <div className="w-full">

            <AllCardsWrapper>
              {state.series.map((item) => (
                <CardWrapper key={item.id}>
                  <CardImage
                    onClick={() => {
          
                      if(item.category === 2)
                      showPlayer(state, dispatch, item);

                    if (item.seasons.length > 0)
                      onSeasonSelect(item.seasons[0]?.id, true);
                    }}
                    src={item.thumbnail}
                  />
                  <CardBottom
                    title={item.title}
                    year={item.year}
                    genre={item.genres.filter(gen => !gen.isCustom).slice(0, 3).map(gen => gen.name).join(', ')}
                    rating={item.rating}
                    onClick={() => {
                      // selectSeries(state, dispatch, item);
                      setSelectedMovie(item);
                    }}
                  />
                </CardWrapper>
              ))}
              {isLoading && (
              <div className="flex justify-center my-6">
                <LoadingIcon />
                Loading
              </div>
              )}
            </AllCardsWrapper>

          </div>

          )}
        </>
        )}
      </AllSlidesWrapper>
      <Modal
        isOpen={selectedMovie !== undefined}
        style={customStyles}
        contentLabel="Movie Modal"
        className={category === MOVIES ? "flex" : "h-full"}
        ariaHideApp={false}
        onRequestClose={() => setSelectedMovie()}
        shouldCloseOnOverlayClick
      >
        <MovieCardCompounded
          currentItem={selectedMovie}
          state={state}
          dispatch={dispatch}
          onClose={() => setSelectedMovie()}
          isSeries={category === SERIES}
        />

      </Modal>
      <PlayerCompound history={history} />
      <FooterCompound />
    </>
  );
}

export default SearchPage;
