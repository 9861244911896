import React from "react";

function PackageFormSection({ children, ...restProps }) {
  return (
    <div className="flex flex-col md:flex-row gap-4 md:gap-12 w-full  p-4 " {...restProps}>
      {children}
    </div>
  );
}

export default PackageFormSection;
