/* eslint-disable import/no-named-as-default-member */
import React from "react";
import {
  BrowserRouter as Router, Switch, Route, Redirect
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import HomePage from "./pages/HomePage";
import SigninPage from "./pages/SigninPage";
import SignupPage from "./pages/SignupPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import VerifyPage from "./pages/VerifyPage";
import MyListPage from "./pages/MyListPage";
import MoviesPage from "./pages/MoviesPage";
import ListPage from "./pages/ListPage";
import AccountPage from "./pages/AccountPage";
import ProfilePage from "./pages/ProfilePage";
import SearchPage from "./pages/SearchPage";
import Store from './context/Store';
import { isLogin } from './lib/utils';
import GiftCardPage from "./pages/GiftCardPage";
import BillingPage from "./pages/BillingPage";
import PlayerPage from "./pages/PlayerPage";
import AuthPage from "./pages/AuthPage";
import SeriesPage from "./pages/SeriesPage";
import "react-toastify/dist/ReactToastify.css";
import Privacy from "./pages/Privacy";

function App() {
  return (
    <Store>
      <ToastContainer
        position="bottom-left"
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="mx-auto" style={{ maxWidth: '2000px' }}>
        <Router>
          <Switch>

            <PublicRoute restricted component={HomePage} path="/" exact />
            <PublicRoute restricted component={SigninPage} path="/signin" exact />
            <PublicRoute restricted component={SignupPage} path="/signup" exact />
            <PublicRoute component={Privacy} path="/privacy" exact />
            <PublicRoute component={VerifyPage} path="/email_verify/:email/:code" exact />
            <PublicRoute restricted component={ForgotPasswordPage} path="/forgot_password" exact />
            <PublicRoute restricted component={ForgotPasswordPage} path="/forgot_password/:email/:code" exact />

            <PublicRoute component={PlayerPage} path="/player/:id/:session/:token/:type" exact />
            <PublicRoute component={AuthPage} path="/auth/:token/:resource/:profileId/:payment" exact />
            <PublicRoute component={AuthPage} path="/auth/:token/:resource/:profileId" exact />

            <PrivateRoute component={MoviesPage} path="/browse" exact />
            <PrivateRoute component={SeriesPage} path="/series" exact />
            <PrivateRoute component={MyListPage} path="/mylist" exact />
            <PrivateRoute component={AccountPage} path="/account/manage" exact />
            <PrivateRoute component={AccountPage} path="/account/manage/:session" exact />
            <PrivateRoute component={ProfilePage} path="/account/profiles" exact />
            <PrivateRoute component={GiftCardPage} path="/account/giftcards" exact />
            <PrivateRoute component={GiftCardPage} path="/account/giftcards/:session" exact />
            <PrivateRoute component={BillingPage} path="/account/billing" exact />
            <PrivateRoute component={HomePage} path="/account/signout" exact />
            <PrivateRoute component={SearchPage} path="/search/:term" exact />
            <PrivateRoute component={ListPage} path="/:category/:genre" exact />

          </Switch>
        </Router>
      </div>
    </Store>
  );
}

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => (isLogin() ? <Component {...props} /> :
    <Redirect to="/" />)}
  />
);

const PublicRoute = ({ component: Component, restricted, ...rest }) => (
  <Route
    {...rest}
    render={props => (isLogin() && restricted ?
      <Redirect to="/browse" /> : <Component {...props} />)}
  />
);

export default App;
