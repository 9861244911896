import React from "react";
import "./HeaderStyles.css";
import { FaPlay } from "react-icons/fa";

function PlayButton({ children, ...restProps }) {
  return (
    <button
      className="play-button hover:bg-white-500 hover:text-white"
      type="button"
      {...restProps}
    >
      <FaPlay className="inline-block md:mr-2 text-xl -mt-1" />
      {children}
    </button>
  );
}

export default PlayButton;
