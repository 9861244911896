/* eslint-disable react/jsx-key */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable import/no-named-as-default-member */
import React, { useState, useEffect, useContext } from "react";
import { useHistory, useParams } from 'react-router-dom';
import {
  FaChevronLeft, FaHome, FaRegTimesCircle, FaVideoSlash
} from "react-icons/fa";
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import NavBar from "../components/Header/NavBar";
import Logo from "../components/Header/Logo";
import AllSlidesWrapper from "../components/Movies/AllSlidesWrapper";
import SlideWrapper from "../components/Movies/SlideWrapper";
import SlideTitle from "../components/Movies/SlideTitle";
import AllCardsWrapper from "../components/Movies/AllCardsWrapper";
import CardWrapper from "../components/Movies/CardWrapper";
import CardImage from "../components/Movies/CardImage";
import FooterCompound from "../compounds/FooterCompound";

import { StoreContext } from '../context/Store';
import CardLoading from "../components/Common/CardLoading";
import PlayerCompound from "../compounds/PlayerCompound";
import {
  getMovies, getSeries, showPlayer
} from "../context/Actions";
import LoadingIcon from "../components/Common/LoadingIcon";
import ProfileCompound from "../compounds/ProfileCompound";
import CardBottom from "../components/Movies/CardBottom";
import NoContent from "../components/Common/NoContent";
import { MOVIES, SERIES, customStyles } from "../constants";
import { SET_PLAYER } from "../context/ActionTypes";
import GenreWrapper from "../components/Header/GenreWrapper";
import Modal from 'react-modal';
import MovieCardCompounded from "../compounds/MovieCardCompounded";


function ListPage() {
  const history = useHistory();
  const [viewGenre, setViewGenre] = useState(false);
  const [selectedMovie,setSelectedMovie] = useState();
  const [state, dispatch] = useContext(StoreContext);
  const { genre, category } = useParams();
  const [loadingMode, setLoadingMode] = useState(false);
  const [forceLoad, setForceLoad] = useState(false);
  const [season, setSeason] = useState();
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [currentGenre, setCurrentGenre] = useState(genre);

 useBottomScrollListener(() => {
    if (!isLoading && state.cached[`${category}-${currentGenre}-paging`] && currentPage <= state.cached[`${category}-${currentGenre}-paging`].totalPages) {
      setIsLoading(true);
      setCurrentPage(currentPage + 1);
    }
  });

  const fetch = () => {
    setIsLoading(true);
    if (category === MOVIES.toLowerCase()) {
      getMovies(state, dispatch, `${category}-${currentGenre}`, { genreId: currentGenre, currentPage: 1, pageSize }, true, loadingMode, () => {
        setIsLoading(false);
        setLoadingMode(true);
        setForceLoad(false);
      });
    } else if (category === SERIES.toLowerCase()) {
      getSeries(state, dispatch, `${category}-${currentGenre}`, { genreId: currentGenre, currentPage: 1, pageSize }, true, loadingMode, () => {
        setIsLoading(false);
        setLoadingMode(true);
        setForceLoad(false);
      });
    }
  };

  useEffect(() => {
    if (!state.cached[`${category}-${currentGenre}-paging`] || currentPage <= state.cached[`${category}-${currentGenre}-paging`].totalPages) {
      fetch();
    } else setIsLoading(false);
  }, [currentPage]);

  useEffect(() => {
    setCurrentPage(1);
    fetch();
  }, [currentGenre]);

  useEffect(() => {
    dispatch({
      type: SET_PLAYER,
      payload: {
        playerOn: false,
      }
    });
    setCurrentPage(state.cached[`${category}-${currentGenre}-paging`] ? state.cached[`${category}-${currentGenre}-paging`].currentPage + 1 : 1);
    if (state.cached[`${category}-${currentGenre}-paging`] && state.cached[`${category}-${currentGenre}-paging`].currentPage > 1) {
      setLoadingMode(true);
    }
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <NavBar className="navbar-browse w-full">
        <Logo history={history} state={state} dispatch={dispatch} />
        <ProfileCompound history={history} />
      </NavBar>

      {viewGenre && false && (
      <GenreWrapper>
        <FaRegTimesCircle onClick={() => setViewGenre(false)} className="inline-block w-12 text-2xl float-right text-white hover:text-gray-300 cursor-pointer" />
        <div className="text-center w-full mt-10 overflow-y-auto  overflow-x-hidden" style={{ height: "80vh" }}>
          {state.lookups.genres.map((x) => (
            <div
              className="text-2xl text-gray-300 my-6 hover:text-white cursor-pointer capitalize"
            >
              <p
                className="   transform hover:scale-150  duration-1000 ease-out capitalize inline-block"
                onClick={() => {
                  setLoadingMode(false);
                  setCurrentGenre(x.toLowerCase());
                  setViewGenre(false);
                }}
              >
                {x}
              </p>
            </div>
          ))}

        </div>

      </GenreWrapper>
      )}
      <AllSlidesWrapper className="mt-0 mx-10 pt-20 min-h-screen">

        {category === MOVIES.toLowerCase() && (

        <SlideWrapper key={`${currentGenre}`}>
          <div className="">
            <span
              className="text-gray-500 text-lg capitalize cursor-pointer hover:text-white"
              onClick={() => history.push('/browse')}
            >
              <FaHome className="inline-block -mt-1" />
              {' '}
              Home
            </span>
          </div>

          <SlideTitle onClick={() => setViewGenre(true)}>
            <span className="capitalize text-3xl">
              {state.lookups.allGenres.find((x) => x.id === currentGenre)?.name}
            </span>
          </SlideTitle>
          {state.isFetching && (
          <div className="pt-1">
            <CardLoading />
          </div>
          )}
          {state.movies.length === 0 && !state.isFetching && (

          <NoContent>
            <div>
              <span className="text-4xl font-semibold">No movies found</span>
            </div>
            <div>
              <span className="text-sm text-gray-500">Try another genre.</span>
            </div>
            <br />
            <div
              className="text-gray-500 text-lg capitalize cursor-pointer hover:text-white mt-4"
              onClick={() => history.push('/browse')}
            >
              <FaChevronLeft className="-mt-1 inline-block mx-auto" />
              Back to Home
            </div>
          </NoContent>

          )}
          {!state.isFetching && (
          <AllCardsWrapper>
            {state.movies.map((item) => (

              <CardWrapper key={item.id}>
                <CardImage
                  onClick={() => {
                    showPlayer(state, dispatch, item);
                  }}
                  src={item.thumbnail}
                />
                 <CardBottom title={item.title} year={item.year} rating={item.rating} 
                  genre={item.genres.filter(gen => !gen.isCustom).slice(0, 3).map(gen => gen.name).join(', ')}
                  onClick={() => {
                    setSelectedMovie(item);
                  }}
                         />
              </CardWrapper>

            ))}
            {isLoading && (
            <div className="flex justify-center my-6">
              <LoadingIcon />
              Loading
            </div>
            )}
          </AllCardsWrapper>

          )}

        </SlideWrapper>

        ) }
        {category === SERIES.toLowerCase() && (

        <SlideWrapper key={`${currentGenre}`}>
          <div className="">
            <span
              className="text-gray-500 text-lg capitalize cursor-pointer hover:text-white"
              onClick={() => history.goBack()}
            >
              <FaHome className="inline-block -mt-1" />
              {' '}
              Home
            </span>
          </div>

          <SlideTitle onClick={() => setViewGenre(true)}>
            <span className="capitalize text-3xl">
              {state.lookups.allGenres.find((x) => x.id === currentGenre)?.name}
            </span>
          </SlideTitle>
          {state.isFetching && (
          <div className="pt-1">
            <CardLoading />
          </div>
          )}
          {state.series.length === 0 && !state.isFetching && (
            <NoContent>

              <div>
                <span className="text-4xl font-semibold">No series found</span>
              </div>
              <div>
                <span className="text-sm text-gray-500">Try another genre.</span>
              </div>
              <br />
              <div
                className="text-gray-500 text-lg capitalize cursor-pointer hover:text-white mt-4"
                onClick={() => history.goBack()}
              >
                <FaChevronLeft className="-mt-1 inline-block mx-auto" />
                Back to Home
              </div>
            </NoContent>
          )}
          {!state.isFetching && (
          <AllCardsWrapper>
            {state.series.map((item) => (

              <CardWrapper key={item.id}>
                <CardImage
                  onClick={() => {
                    // selectSeries(state, dispatch, item, setSeason);
                    setSelectedMovie(item);
                  }}
                  src={item.thumbnail}
                />
                <CardBottom title={item.title} year={item.year} rating={item.rating} />
              </CardWrapper>

            ))}
            {isLoading && (
            <div className="flex justify-center my-6">
              <LoadingIcon />
              Loading
            </div>
            )}
          </AllCardsWrapper>

          )}

        </SlideWrapper>

        ) }
      </AllSlidesWrapper>

        <Modal
          isOpen={selectedMovie !== undefined}
          style={customStyles}
          contentLabel="Movie Modal"
          className={category === MOVIES ? "flex" : "h-full"}
          ariaHideApp={false}
          onRequestClose={() => setSelectedMovie()}
          shouldCloseOnOverlayClick
        >
          <MovieCardCompounded
            currentItem={selectedMovie}
            state={state}
            dispatch={dispatch}
            onClose={() => setSelectedMovie()}
            isSeries={category === SERIES}
          />

        </Modal>
  
      
      <PlayerCompound history={history} />
      <FooterCompound />

    </>
  );
}

export default ListPage;
