/* eslint-disable react/jsx-props-no-multi-spaces */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect, useRef } from "react";
import { FaVolumeMute, FaVolumeUp } from "react-icons/fa";
import { showPlayer } from "../../context/Actions";
import FeatureSubTitle from "./FeatureSubTitle";
import FeatureTitle from "./FeatureTitle";
import FeatureWrapper from "./FeatureWrapper";
import "./HeaderStyles.css";
import PlayButton from "./PlayButton";
import TrailerPlayButton from "./TrailerPlayButton";
import Player from "@vimeo/player";
import { SET_HEADER_MOVIE_PLAY } from "../../context/ActionTypes";
import { customStyles, MOVIES, SERIES } from "../../constants";
import useOnScreen from "../../hooks/useOnScreen";
import InfoButton from "./InfoButton";
import Modal from 'react-modal';
import MovieCardCompounded from "../../compounds/MovieCardCompounded";

function HeaderWrapper({
  video,
  state,
  children,
  dispatch,
  category,
  ...restProps
}) {
  const [muted, setMuted] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [videoMode, setVideoMode] = useState(false);
  const vidRef = useRef(null);
  const muteOn = useRef(null);
  const playControl = useRef(null);
  const pauseControl = useRef(null);
  const muteOff = useRef(null);
  const [headerMovie, setHeaderMovie] = useState();
  const isVisible = useOnScreen(vidRef)

  const triggerPlay = () => {
    if (!state.playHeaderMovie) setVideoMode(false);
    else if (!videoMode) {
      setTimeout(() => {
       setVideoMode(true);
      }, 4000);
    }
  };

  useEffect(() => {
    if (
      headerMovie &&
      headerMovie.trailer &&
      videoMode &&
      muteOn.current &&
      muteOff.current
    ) {
      const player = new Player("handstick", {
        url: headerMovie.trailer,
        autoplay: true,
        fullscreen: true,
        responsive: true,
        title: false,
        background: true,
        muted: true,
        controls: false,
        loop: false
      });

      muteOn.current.addEventListener("click", function () {
        player.setMuted(true);
        player.setVolume(0);
        setMuted(true);
      });

      playControl.current.addEventListener("click", function () {
        player.play()

      });
      pauseControl.current.addEventListener("click", function () {
        player.pause()

      });

      

 
      muteOff.current.addEventListener("click", function () {
        player.setMuted(false);
        player.setVolume(1);
        setMuted(false);
      });

      player.on("play", function () {});
      player.on("ended", function () {
        setVideoMode(false);
        dispatch({
          type: SET_HEADER_MOVIE_PLAY,
          payload: false,
        });
      });
    }
  }, [headerMovie, videoMode]);

  useEffect(() => {
   
    if (state?.headerMovie && category === MOVIES) {
      setVideoMode(false);
      setHeaderMovie(state.headerMovie);
      triggerPlay();
    }
  }, [state?.headerMovie, category]);

  useEffect(() => {
    if (state?.headerSeries && category === SERIES) {
      setVideoMode(false);
      setHeaderMovie(state.headerSeries);
      triggerPlay();
    }
  }, [state?.headerSeries, category]);

  useEffect(() => {
    if (state?.playerOn) {
      setMuted(true);
      if (muteOff.current) muteOff.current.click();
      setVideoMode(false);
    }
  }, [state?.playerOn]);


  /**
   * Effect detecting scrolling out of focus
   */
  useEffect(() => {
    if(!isVisible &&  pauseControl.current)
    {
      pauseControl.current.click();
    }
    else if(isVisible &&  playControl.current)
    {
      playControl.current.click();
    }
  }, [isVisible]);

  /**
   * Effect to detect browser tab change
   */

  useEffect(() => {
    const handleActivityFalse = () => {
      if(pauseControl.current)   pauseControl.current.click();
    };

    const handleActivityTrue = () => {
      if(playControl.current)   playControl.current.click();

    };

    window.addEventListener('focus', handleActivityTrue);
    window.addEventListener('blur', handleActivityFalse);

    return () => {
        window.removeEventListener('focus', handleActivityTrue);
        window.removeEventListener('blur', handleActivityFalse);
    };
}, []);
  

  return (
    <header {...restProps}>
      {video && (
        <>
          {videoMode && (
            <>
              <div
                id="handstick"
                ref={vidRef}
                className="top-0 right-0 w-full  mt-20 md:-mt-40 absolute z-10 md:block "
              ></div>
              <div
                ref={muteOff}
                onClick={() => setMuted(false)}
                className={`${!muted ? "hidden" : ""}`}
              >
                <FaVolumeMute className="muteOn z-30 text-gray-300 absolute right-0 mr-4 md:mr-24 text-2xl  block cursor-pointer md:mt-0 -mt-10 border-2 h-10 w-10 md:h-14 md:w-14 p-3 rounded-full border-white bg-gray-900 opacity-50" />{" "}
              </div>
              <div
                ref={muteOn}
                onClick={() => setMuted(true)}
                className={`${muted ? "hidden" : ""}`}
              >
                <FaVolumeUp className="muteOff z-30 text-gray-300 absolute right-0 mr-4 md:mr-24 text-2xl block cursor-pointer md:mt-0 -mt-10 border-2 h-10 w-10 md:h-14 md:w-14 p-3 rounded-full border-white bg-gray-900 opacity-50" />{" "}
              </div>
            </>
          )}

          {!videoMode && (
            <div
            className="top-0 right-0 w-full   absolute z-10 md:block overlay-header"
              style={{
                backgroundImage: `linear-gradient(to right, rgb(17, 24, 39) 30%, rgba(0,0,0,0)), url(${headerMovie?.banner})`,
                backgroundPosition: "left",
                backgroundSize: "cover",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                height: "800px",
              }}
            />
          )}

          {headerMovie && (
            <div
              className="content  z-20 -mt-20  md:mt-0 absolute pt-44 "
              style={{
                background:
                  " linear-gradient(to right, rgb(17, 24, 39) 10%, rgba(0, 0, 0, 0))",
                height: "100vh",
              }}
            >
              <FeatureWrapper
                className={
                  "mx-2 md:mx-6 transform scale-100   duration-1000 ease-out"
                }
              >
                <div className="bg-blue-500 text-white px-3 rounded w-max mb-3">Featured</div>
                <FeatureTitle className="feature-title-browse text-3xl md:text-5xl font-semibold">
                  {headerMovie?.title}
                </FeatureTitle>
                <FeatureSubTitle
                  className={
                    "feature-subtitle-browse text-sm md:text-xl hidden md:block"
                  }
                  onMouseEnter={() => {
                    triggerPlay();
                  }}
                >
                  {headerMovie?.description}
                </FeatureSubTitle>
                <PlayButton
                  onClick={() =>
                    showPlayer(state, dispatch, headerMovie, false)
                  }
                >
                  <span className="hidden md:inline-block">Play</span>
                </PlayButton>
                 <InfoButton 
                  className="info-button bg-gray-500 hover:bg-blue-500 text-white focus:outline-none ml-2"
                  onClick={() => setShowModal(true)}>
                    <span className="hidden md:inline-block">More Info</span>
                  </InfoButton>
                <TrailerPlayButton className="hidden md:inline-block hover:text-blue-500 md:mx-2 px-2 focus:outline-none"
                  onClick={() =>{
          
                    showPlayer(state, dispatch, headerMovie, true)
                  }}
                >
                  Watch Trailer
                </TrailerPlayButton>
               
              </FeatureWrapper>
              <div
                ref={playControl}
                className="hidden"
              ></div>
               <div
                ref={pauseControl}
                className="hidden"
              ></div>
            </div>
          )}
        </>
      )}

      {!video && <div className="h-4/5 header-wrapper-home">{children}</div>}

     {headerMovie && <Modal
        isOpen={showModal && headerMovie}
        style={customStyles}
        contentLabel="Movie Modal"
        onRequestClose={() => setShowModal(false)}
        shouldCloseOnOverlayClick
      >
        <MovieCardCompounded
          currentItem={headerMovie}
          state={state}
          dispatch={dispatch}
          onClose={() => setShowModal(false)}
          showAddToList={!headerMovie.isSeries}
        />

      </Modal> }

    </header>
  );
}

export default HeaderWrapper;
