import React from "react";

function VerifyButton({ children, ...restProps }) {
  return (
    <button type="button" className="text-white w-full  rounded-md text-center bg-yellow-500 py-2 px-4  items-center focus:outline-none my-2 md:my-0 disabled:opacity-50" {...restProps}>
      {children}
    </button>
);
}

export default VerifyButton;
