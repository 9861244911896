import React from "react";
import "./SignFormStyles.css";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

function PhoneNumberInput({ username, onChange: handlePhoneChange, tabIndex }) {
    return <>
        <PhoneInput
            country={'et'}
            countryCodeEditable={false}
            value={username}
            onChange={handlePhoneChange}
            inputProps={{ required: true }}
            masks={{et: '... ..-..-..'}}
        />
    </>

}

export default PhoneNumberInput;