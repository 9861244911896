/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { FaPlay } from 'react-icons/fa';

function CardSeries({ item, callback, ...restProps }) {
  return (
    <div className="group w-full pb-2 border-b border-gray-500 hover:bg-gray-700 py-4">
      <div className="w-full md:w-1/5 inline-block md:px-1 px-1 mb-4 text-center">
        <div className="relative h-36 w-36 overflow-hidden rounded-lg shadow-2xl cursor-pointer group  inline-block align-middle">
          <div className="absolute flex items-center justify-center w-full h-full bg-black bg-opacity-25">
            <span className="opacity-0 group-hover:opacity-100 duration-200 flex items-center justify-center w-10 h-10 bg-blue-500 bg-opacity-80 hover:bg-opacity-100 rounded-full border-2 border-white" onClick={() => callback()}>
              <FaPlay className="text-white" />
            </span>
          </div>
          <img src={item.image} className="object-contain w-full h-full" />
        </div>
      </div>
      <div className="w-full md:w-3/5 inline-block align-middle text-center md:text-left px-1 mb-4">
        <div className="text-lg font-medium">
          {item.season}
          {' '}
          -
          {' '}
          {item.title}
        </div>
        <div className="text-sm mt-2 text-gray-400">
          {item.description}
        </div>
      </div>

      <div className="w-full md:w-1/5 inline-block align-middle text-center md:text-left   md:px-10 px-1 mb-4">
        <div className="text-base font-semibold">
          {item.duration}
        </div>
      </div>

    </div>
  );
}

export default CardSeries;
