import React from "react";
import "./HeaderStyles.css";
import { FaPlus } from 'react-icons/fa';

function AddToListButton({ children, ...restProps }) {
  return (
    <button className="hover:text-blue-500 hover:border-blue-500 px-2 focus:outline-none border border-1 py-3 px-4 rounded" type="button" {...restProps}>
      <FaPlus className="inline-block mr-1 text-xl -mt-1" />
      {children}
    </button>
  );
}

export default AddToListButton;
