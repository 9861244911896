import { config, TELEBIRR_ICON_URL } from '../../constants';
import React, { useState } from "react";
import MayaDataService from "../../services/MayaDataService";
import LoadingIcon from '../Common/LoadingIcon';

 
function TelebirrForm({ selectedPackage, lookups, quantity, callback, path }) {
  const [loading, setLoading] = useState(false);
  const pay = () => {
    if(!loading)
      {
        setLoading(true);
        MayaDataService.telebirrCheckout({
          amount:  selectedPackage.price,
          priceSettingId: selectedPackage.id,
          quantity: quantity
        }, path).then((result) => {
      
          if(result.responseStatus === 1)
          {
            window.location = result.data.checkoutUrl;
            
          }
          else{
            setLoading(false);
          }
      
        callback();

        }).catch((e) => {
          setLoading(false);
        });
      }
  }



  return (
    <div className="mx-2 md:mx-20 pb-20">
        <h2 className="px-12 text-base font-bold text-center md:text-2xl text-white">
        {lookups.subscriptionPackages.find((x) => x.id === selectedPackage.package)?.description} Plan
      </h2>
      <img src={TELEBIRR_ICON_URL} className="w-32 mx-auto ml-1 inline-block my-4" />
      <button
          onClick={() => pay()}
          className="text-white w-full mt-4  br-25 text-center bg-blue-500 py-2 px-4  items-center focus:outline-none"
        >
   
         {!loading && <span>Pay {selectedPackage.price * quantity} ETB</span>}
         {loading && <span className="inline-block align-middle"> <LoadingIcon /> </span> }
        </button>
     
    </div>
  );
}

export default TelebirrForm;
