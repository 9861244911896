import React from "react";
import "./MoviesStyles.css";

function SlideTitle({ children, ...restProps }) {
  return (
    <div className="group font-semibold text-gray-300 z-50 transform hover:scale-100 delay-200 duration-200 ease-out cursor-pointer text-left w-full" {...restProps}>
      {children}
    </div>
  );
}

export default SlideTitle;
