import React from "react";
import DevInfo from "./DevInfo";
import homeData from "../data/home.json";
import { mayaAlert } from "../constants";
import TermsCompunded from "./TermsCompounded";

function FooterCompound() {
  const showTermsAndPrivacy = () => {
    mayaAlert.fire({
      showConfirmButton: false,
      width: 650,
      allowOutsideClick: true,
      background: "rgba(17, 24, 39, 1)",
      html: <TermsCompunded />,
    });
  };

  return (
    <footer className="w-full text-gray-700 body-font my-2 border-t border-gray-600 text-xs">
      <div className="container flex flex-col flex-wrap px-5 py-4 mx-auto md:items-center lg:items-start md:flex-row md:flex-no-wrap">
        <div className="flex flex-wrap flex-grow text-center">
          <div className="w-full px-4  lg:w-1/6 md:w-1/6">
            <DevInfo>
              <a className="text-gray-500 cursor-pointer hover:text-white">
                MayaFlicks©2022
              </a>
            </DevInfo>
          </div>
        </div>
        <div className="flex flex-wrap flex-grow text-center">
          <div className="w-full px-4 ">
            <a
              className="text-gray-500 cursor-pointer hover:text-white md:float-right"
              onClick={() => showTermsAndPrivacy()}
            >
              Terms & Privacy
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default FooterCompound;
