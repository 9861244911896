import React, { useContext, useEffect, useState } from "react";
import { FaRegTimesCircle } from "react-icons/fa";
import GenreWrapper from "../components/Header/GenreWrapper";
import ProfileLink from "../components/Header/ProfileLink";
import ProfileIconSmall from "../components/ProfileForm/ProfileIconSmall";
import { StoreContext } from '../context/Store';
import { logout } from "../lib/utils";

function ProfileCompound({ history }) {
  const [state, dispatch] = useContext(StoreContext);
  const [viewUser, setViewUser] = useState(false);
  const [account, setAccount] = useState();
  
  const signout = () =>{
    logout();
  }

  useEffect(() => {
    setAccount(state.account);
   }, [state.account]);
  return (
    <>
      {viewUser && (
      <GenreWrapper>
        <FaRegTimesCircle onClick={() => setViewUser(false)} className="inline-block w-12 text-2xl float-right text-white hover:text-gray-300 cursor-pointer" />
        <div className="text-center w-full mt-10 overflow-hidden">
          {["manage","profiles","gift cards","billing","sign out"].map((x) => (
            <div className="text-3xl text-gray-300 my-6 hover:text-white cursor-pointer capitalize" key={x}>
              <a
                className="   transform hover:scale-150  duration-1000 ease-out capitalize inline-block"
                onClick={() => ((x === 'sign out') ? signout() : history.push(`/account/${x === 'gift cards' ? 'giftcards' :  x.toLowerCase()}`))}
              >
                {x}
              </a>
            </div>
            ))}

        </div>

      </GenreWrapper>
      )}
   
      <ProfileLink onClick={() => setViewUser(!viewUser)} profile={state.selectedProfile?.name.substring(0, 2)}>{account?.username}</ProfileLink>
    </>
  );
}

export default ProfileCompound;
