import React from "react";
import "./HeaderStyles.css";

function Logo({
  history, state, dispatch, children, ...restProps
}) {
  return (
    <>
      <div className="p-1 py-3 hidden md:block">
        <a
          onClick={() => {
            if (history) history.push('/browse');
          }}
          className="flex items-center"
          {...restProps}
        >
          {children}
          <img className="float-left logo-img pr-2 cursor-pointer" src="/images/misc/logo-small.png" alt="MayaFlicks" />
          <span className="logo-main text-blue-500 cursor-pointer mr-1 text-2xl">MAYA</span>
          <span className="logo-sm cursor-pointer text-2xl">FLICKS</span>
        </a>
      </div>
      <div className="py-2 md:hidden">
        <a onClick={() => history.push('/browse')} {...restProps}>
          {children}
          <img className="float-left logo-img w-9 h-8" src="/images/misc/logo-small.png" alt="MayaFlicks" />
        </a>
      </div>
    </>
  );
}

export default Logo;
