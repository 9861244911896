/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";

const TermsCompunded = ({ setTermsAndCondtionModal }) => (
  <div className=" p-4 h-full">
    <div
      className="p4 text-gray-200 text-sm overflow-y-auto "
      style={{ height: "85%" }}
    >
      <section>
        <div className="text-left md:text-center py-6 px-4 mb-5">
          <h1 className="text-2xl md:text-3xl text-gray-100 leading-tight mb-2 font-bold">
            Terms & Privacy
          </h1>
          <p className="text-base md:text-lg text-gray-200">
            Last updated: 14th May 2021
          </p>
        </div>
        <div className="container text-left mx-auto pb-20 px-4 prose">
          <p>
            This Privacy Statement explains our practices, including your
            choices, regarding the collection, use, and disclosure of certain
            information, including your personal information in connection with
            the Mayaflicks service.
          </p>

          <h2 className="font-bold my-2 mt-6">Information you provide to us</h2>

          <p>
            we collect information you provide to us which includes: your name,
            email address, address or postal code, payment method(s), and
            telephone number. We collect this information in a number of ways,
            including when you enter it while using our service, interact with
            our customer service, or participate in surveys or marketing
            promotions; information when you choose to provide ratings, taste
            preferences, account settings (including preferences set in the
            "Account" section of our website), or otherwise provide information
            to us through our service or elsewhere.
          </p>

          <h2 className="font-bold my-2 mt-6">
            Information we collect automatically
          </h2>

          <p>
            we collect information about you and your use of our service, your
            interactions with us and our advertising, as well as information
            regarding your network, network devices, and your computer or other
            Mayaflicks capable devices you might use to access our service (such
            as gaming systems, smart TVs, mobile devices, set top boxes, and
            other streaming media devices). This information includes: your
            activity on the Mayaflicks service, such as title selections, shows
            you have watched, and search queries; your interactions with our
            emails and texts, and with our messages through push and online
            messaging channels; details of your interactions with our customer
            service, such as the date, time and reason for contacting us,
            transcripts of any chat conversations, and if you call us, your
            phone number and call recordings; device IDs or other unique
            identifiers, including for your network devices, and devices that
            are Mayaflicks capable on your Wi-Fi network; resettable device
            identifiers (also known as advertising identifiers), such as those
            on mobile devices, tablets, and streaming media devices that include
            such identifiers (see the "Cookies and Internet Advertising" section
            below for more information); device and software characteristics
            (such as type and configuration), connection information including
            type (wifi, cellular), statistics on page views, referring source
            (for example, referral URLs), IP address (which may tell us your
            general location), browser and standard web server log information;
            information collected via the use of cookies, web beacons and other
            technologies, including ad data (such as information on the
            availability and delivery of ads, the site URL, as well as the date
            and time). (See our "Cookies and Internet Advertising" section for
            more details.)
          </p>

          <h2 className="font-bold my-2 mt-6">Use of Information</h2>

          <p>
            We use information to provide, analyze, administer, enhance and
            personalize our services and marketing efforts, to manage member
            referrals, to process your registration, your orders and your
            payments, and to communicate with you on these and other topics
          </p>
        </div>
      </section>
    </div>
  </div>
);

export default TermsCompunded;
