import React, { useState } from "react";
import { FaCcPaypal } from "react-icons/fa";
import { PayPalButton } from "react-paypal-button-v2";
import { mayaAlert } from "../../constants";
import MayaDataService from "../../services/MayaDataService";
import LoadingIcon from "../Common/LoadingIcon";
 
function PayPalForm({ selectedPackage, lookups, callback, quantity }) {

  const [options, setOptions] = useState({
    clientId:`${process.env.REACT_APP_API_PAYPAL_CLIENT_ID}`,
  })
  const [loading, setLoading] = useState(false);
  var orderId;


  const checkoutComplete = (id) => {

    var message = '<div class="text-white text-4xl my-4">Payment Completed</div><div class="text-white tex-sm">Your subscription will be updated now</div>';
    if(selectedPackage.plan === 2)
      message = '<div class="text-white text-4xl my-4">Purchase Completed</div><div class="text-white tex-sm"></div>';
    setLoading(true);
    MayaDataService.paypalCheckoutComplete(id).then((result) => {
      mayaAlert.fire({
        icon: 'success',
        background: 'rgba(17, 24, 39, 1)',
        html: message
      });

       
      setLoading(false);
      callback();
    }).catch((e) => {

    });
  }

  return (
    <div className="mx-2 md:mx-20 pb-20">
        <h2 className="px-12 text-base font-bold text-center md:text-2xl text-white">
          {lookups.subscriptionPackages.find((x) => x.id === selectedPackage.package)?.description} Plan
        </h2>
        <h2 className="px-12 text-base font-bold text-center md:text-xl text-white">
         Pay ${selectedPackage.usdPrice * quantity}
        </h2>
        <FaCcPaypal className="text-white  my-2 mx-auto" style={{fontSize: '120px'}} />
   
        {loading && <div><span className="inline-block align-middle my-4"> 
       <LoadingIcon className="inline-block" /> </span>
        <span className="inline-block">Processing ...</span> </div>}
       
      <PayPalButton
        
        amount={selectedPackage.usdPrice}
        currency="USD"
        shippingPreference="NO_SHIPPING"
        onError={(e) =>  setLoading(false)}
        onCancel={(e) => setLoading(false)}
       
        createOrder={(data, actions) => {

          if(!loading)
          {
            orderId = data.orderID;
            return MayaDataService.paypalCheckout({
              quantity: quantity,
              amount:  selectedPackage.usdPrice,
              priceSettingId: selectedPackage.id
            }).then((response) => {
              return response.json()
            })
            .then((result) => {
              setLoading(true);
              return result.orderID;
              
        
            }).catch((e) => {

              console.log(e);
        
            });
          }
        }}
        onSuccess={(details, data) => {
         
          checkoutComplete( data.orderID);
          
        }}
        onButtonReady={() => setOptions({
          clientId:`${process.env.REACT_APP_API_PAYPAL_CLIENT_ID}`,
          currency: 'USD',
          disableFunding: 'card',
        })}
        options={options}
      />
     
    </div>
  );
}

export default PayPalForm;
