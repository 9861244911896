import React, { useContext, useEffect, useState } from "react";
import {
  FaChevronLeft,
  FaSync,
  FaTrashAlt,
  FaExclamationTriangle,
  FaCheckCircle,
} from "react-icons/fa";
import { useHistory, useParams } from "react-router-dom";
import FooterCompound from "../compounds/FooterCompound";
import Logo from "../components/Header/Logo";
import NavBar from "../components/Header/NavBar";
import PackageForm from "../components/ProfileForm/PackageForm";
import ProfileCompound from "../compounds/ProfileCompound";
import { StoreContext } from "../context/Store";
import AllSlidesWrapper from "../components/Movies/AllSlidesWrapper";
import ProfileFormSection from "../components/ProfileForm/ProfileFormSection";
import MayaDataService from "../services/MayaDataService";
import { logout, setSession } from "../lib/utils";
import SignFormError from "../components/SignForm/SignFormError";
import CurrencyFormat from "react-currency-format";
import ProfileFormButton from "../components/ProfileForm/ProfileFormButton";
import VerifyButton from "../components/ProfileForm/VerifyButton";
import ProfileFormInput from "../components/ProfileForm/ProfileFormInput";
import { checkPassword, customPaymentStyles, customStyles } from "../constants";
import Separator from "../components/Common/Separator";
import ProfileFormLabel from "../components/ProfileForm/ProfileFormLabel";
import moment from "moment";
import { SET_PLAYER, SET_TOKEN } from "../context/ActionTypes";
import LoadingIcon from "../components/Common/LoadingIcon";

import Modal from 'react-modal';

import OtpFormCompund from "../compounds/OtpFormCompund";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";


const mayaAlert = withReactContent(Swal);

function AccountPage() {
  const iOS = '_iOS';
  const iOS2 = '_iOS';
  const history = useHistory();
  const { session } = useParams();

  function useQueryParams() {
    const params = new URLSearchParams(
      window ? window.location.search : {}
    );

    return new Proxy(params, {
        get(target, prop) {
            return target.get(prop)
        },
    });
}
const [showModal, setShowModal] = useState(false);
  const {type, payment, platform} = useQueryParams();
 
  const [state, dispatch] = useContext(StoreContext);
  const [account, setAccount] = useState();
  const [isChanged, setIsChanged] = useState(false);
  const [loadingProfileChange, setLoadingProfileChange] = useState(false);
  const [loadingVerify, setLoadingVerify] = useState(false);
  const [isChangePasswordEnabled, setIsChangePasswordEnabled] = useState(false);
  const [loadingChangePasswordChange, setLoadingChangePasswordChange] =
    useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [errorRedeem, setErrorRedeem] = useState("");
  const [errorVerify, setErrorVerify] = useState("");
  const [selectedPackage, setSelectedPackage] = useState();
  const [code, setCode] = useState();
  const [loadingRedeem, setLoadingRedeem] = useState(false);
  const [previousToken, setPreviousToken] = useState("");
  const [verifying, setVerifying] = useState(false);
  const [verificationData, setVerificationData] = useState();
  const [originalPhone, setOriginalPhone] = useState();
  const [isPhoneVerified, setIsPhoneVerified] = useState();

  const subscribe = () => {
    MayaDataService.payWithYenePay({
      amount: selectedPackage.price,
      priceSettingId: selectedPackage.id,
    })
      .then((result) => {
        window.location = result;
      })
      .catch((e) => {});
  };

  // const addCredit = () => {
  //   mayaAlert.fire({
  //     showConfirmButton: false,
  //     width: 1000,
  //     background: "rgba(41, 47, 59, 1)",
  //     backdrop:"rgba(0, 0, 0, 0.7)",
  //     html: (
  //       <PackageForm
  //         dispatch={dispatch}
  //         callBack={setSelectedPackage}
  //         validDate={
  //           state.account?.subscriptions &&
  //           state.account?.subscriptions.length > 0
  //             ? moment(state.account?.subscriptions[0].dateTo).format(
  //                 "MMM DD YYYY"
  //               )
  //             : null
  //         }
  //         lookups={state.lookups}
  //         profileId={state.selectedProfile.id}
  //         account={account}
  //         setAccount={setAccount}
  //         state={state}
  //         errorVerify={errorVerify}
  //         setErrorVerify={setErrorVerify}
  //         loadingVerify={loadingVerify} 
  //         setLoadingVerify={setLoadingVerify}
  //         verifying={verifying} 
  //         setVerifying={setVerifying}
  //         verificationData={verificationData} 
  //         setVerificationData={setVerificationData}
  //         originalPhone={originalPhone}
  //         setOriginalPhone={setOriginalPhone}
  //         isPhoneVerified={isPhoneVerified}
  //         setIsPhoneVerified={setIsPhoneVerified}
  //         verifyAccount={verifyAccount}
  //         verify={verify}
  //         verifyPhoneAccount={verifyPhoneAccount}
  //         setPreviousToken={setPreviousToken}
  //         previousToken={previousToken}
  //       />
  //     ),
      
  //   });
  // };

  const changePassword = () => {
    if (oldPassword === password) {
      setError("Password should be different");
      return;
    }
    if (!checkPassword(password)) {
      setError(
        "Password must be at least 8 characters long and contain a capital letter, a number and a special character."
      );
      return;
    }

    setLoadingChangePasswordChange(true);
    MayaDataService.changePassword(oldPassword, password)
      .then((result) => {
        if (result.responseStatus === 0) {
          setError(result.error.message);
        } else {
          mayaAlert.fire({
            icon: "success",
            background: "rgba(17, 24, 39, 1)",
            html: '<div class="text-white text-4xl my-4">Your password is successfully changed</div>',
          });
          setError();
          setIsChangePasswordEnabled(false);
        }
        setLoadingChangePasswordChange(false);
      })
      .catch((e) => {});
  };

  const verify = (codeSent, pToken) => {
    MayaDataService.verifyPhone(account.username.replace("+", "%2B"), codeSent)
      .then((result) => {
        localStorage.setItem("token", pToken);
        setPreviousToken(pToken);
        setTimeout(() => {
          renewToken();
          setVerificationData();
          setVerifying(false);
        }, 300);
      })
      .catch((e) => {});
  };

  const verifyAccount = () => {
    setLoadingVerify(true);
    setErrorVerify("");
    setPreviousToken(localStorage.getItem("token"));

    if (!account.username.includes("@")) {
      MayaDataService.sendPhoneVerification()
        .then((result) => {
          if (result.responseStatus === 1) {
            setVerificationData(result.data);
            setVerifying(true);
          } else {
            setErrorVerify(result.error.message);
          }
          setLoadingVerify(false);
        })
        .catch((e) => {
          setLoadingVerify(true);
          setErrorVerify(e.error.message);
        });
    } else {
      //Send email verification
      MayaDataService.sendEmailVerification()
        .then((result) => {
          if (result.responseStatus === 1) {
            mayaAlert.fire({
              icon: "success",
              background: "rgba(17, 24, 39, 1)",
              html: '<div class="text-white text-4xl my-4">Email Sent</div><div class="text-white tex-sm">Check your email for verification</div>',
            });
          } else {
            setErrorVerify(result.error.message);
          }
          setLoadingVerify(false);
        })
        .catch((e) => {
          setLoadingVerify(true);
          setErrorVerify(e.error.message);
        });
    }
  };

  const verifyPhoneAccount = () => {
    
    setLoadingVerify(true);
    setErrorVerify("");
    setPreviousToken(localStorage.getItem("token"));

    MayaDataService.sendPhoneVerification()
    .then((result) => {
      if (result.responseStatus === 1) {
        setVerificationData(result.data);
        setVerifying(true);
        
      } else {
        setErrorVerify(result.error.message);
      }
      setLoadingVerify(false);
    })
    .catch((e) => {
      setLoadingVerify(true);
      setErrorVerify(e.error.message);
    });
  };

  const updateProfile = () => {
    setLoadingProfileChange(true);
    account.subscriptions = null;
    MayaDataService.updateProfile(account)
      .then((result) => {
        localStorage.setItem("user", JSON.stringify(result.data));
        dispatch({
          type: SET_TOKEN,
          payload: {
            token: localStorage.getItem("token"),
            user: result.data,
          },
        });
        setIsChanged(false);
        setLoadingProfileChange(false);
      
      })
      .catch((e) => {});
  };

  const renewToken = (callback) => {
    MayaDataService.renewToken(state.selectedProfile.id).then((result) => {
      localStorage.setItem("token", result.data.token);
      localStorage.setItem("user", JSON.stringify(result.data.user));
      dispatch({ type: SET_TOKEN, payload: result.data });
      setSession(dispatch);
      setLoadingRedeem(false);
      setLoadingVerify(false);
      setErrorRedeem("");
      setErrorVerify("");
      if(callback)
      { 
        callback();
      
      }
      // else
      //   mayaAlert.close();
    });
  };

 const redeem = () => {
    if (!code || code === "") {
      setErrorRedeem("Please enter a valid code");
      return;
    }
    setLoadingRedeem(true);
    MayaDataService.redeem(code)
      .then((result) => {
        if (result.responseStatus === 1) {
          renewToken();
          mayaAlert.fire({
            icon: "success",
            background: "rgba(17, 24, 39, 1)",
            html: '<div class="text-white text-4xl my-4">Redeem Successful</div><div class="text-white tex-sm">Your subscription will be updated now</div>',
          });
          setCode("");
        } else {
          setErrorRedeem(result.error.message);
        }

        setLoadingRedeem(false);
      })
      .catch((result) => {
        setLoadingRedeem(true);
        setErrorRedeem(result.error.message);
      });
  };

  const deleteAccount = () => {
    MayaDataService.deleteAccount().then((result) => {
      mayaAlert.fire(
        "Account Deleted!",
        "Your account has been deleted.",
        "success"
      );
      logout();
    });
  };
  const onDeleteAccount = () => {
    mayaAlert
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#ececece",
        confirmButtonText: "Yes, delete it!",
      })
      .then((result) => {
        if (result.isConfirmed) {
          deleteAccount();
        }
      });
  };

  useEffect(() => {
    if (selectedPackage) subscribe();
  }, [selectedPackage]);

  useEffect(() => {

  if(state.account)
   {
    setAccount(state.account);
    setOriginalPhone(state.account.address?.telephoneMob);
    setIsPhoneVerified(state.account.isPhoneVerified);
   }
  }, [state.account]);
  // useEffect(() => {
  //   if(state.selectedProfile )
  //   {
   
  //     renewToken()
  //   }
  // }, [state.selectedProfile]);

  useEffect(() => {
    if(state.selectedProfile)
    {
      
      dispatch({
        type: SET_PLAYER,
        payload: {
          playerOn: false,
        },
      });
      window.scrollTo(0,0);
      if (session) {
        if (session === "subscribe") {
        //  addCredit();
          setShowModal(true);
        } 
        else {
          mayaAlert.fire({
            icon: "success",
            background: "rgba(17, 24, 39, 1)",
            html: '<div class="text-white text-4xl my-4">Payment Completed</div><div class="text-white tex-sm">Your subscription will be updated now</div>',
          }).then(() =>  renewToken(() =>   history.push("/account/manage")));

     
          // renewToken(() =>  
          // {
          //   mayaAlert.close();
          //   setTimeout(() => {
          //     history.push("/account/manage")
          //   }, 4000);
          // });
        } 
      }
      else{
        renewToken()
      }
    }
  }, [state.selectedProfile]);

  useEffect(() => {
   if(payment && (account?.isEmailVerified || account?.isPhoneVerified) && state.lookups.subscriptionPackages.length > 0)
   {
    setShowModal(true);
   }
  }, [payment, account,state.lookups]);
  
  return (
    <>
    {type !== 'mobile' && 
      <NavBar className="navbar-browse w-full">
      <Logo history={history} state={state} dispatch={dispatch} />
      <ProfileCompound history={history} />
    </NavBar>
    }

      <AllSlidesWrapper className={["mb-10 mx-4 md:mx-10 min-h-screen", type === 'mobile' ? '' : ' py-16'].join(' ')}>
        {!verifying && (
          <div className="mx-auto container max-w-3xl md:w-3/4">
            <div className="text-2xl font-medium my-2">
              {" "}
              Account Information{" "}
            </div>
            <hr />
            <div className="text-white space-y-6 pt-4">
            
              <ProfileFormSection>
              <div className="text-xl mt-2 w-48">
                  Subscription
                </div>

                <div className="flex-1 ">
                  {state.account?.subscriptions &&
                    state.account?.subscriptions.length > 0 && (
                      <div className="w-full inline-block align-top">
                        <ProfileFormLabel>
                          {moment(
                            state.account?.subscriptions.find(
                              (x) => x.status === 1
                            ).dateTo
                          ).diff(moment({ hours: 0 }), "days") < 5 &&
                            moment(
                              state.account?.subscriptions.find(
                                (x) => x.status === 1
                              ).dateTo
                            ).diff(moment({ hours: 0 }), "days") > 0 && (
                              <>
                                <FaExclamationTriangle className="inline-block text-yellow-500 mr-1 -mt-1" />
                                Valid until
                              </>
                            )}
                          {moment(
                            state.account?.subscriptions.find(
                              (x) => x.status === 1
                            ).dateTo
                          ).diff(moment({ hours: 0 }), "days") >= 5 &&
                            moment(
                              state.account?.subscriptions.find(
                                (x) => x.status === 1
                              ).dateTo
                            ).diff(moment({ hours: 0 }), "days") > 0 && (
                              <>
                                Valid until
                              </>
                            )}
                          {moment(
                            state.account?.subscriptions.find(
                              (x) => x.status === 1
                            ).dateTo
                          ).diff(moment({ hours: 0 }), "days") <= 0 && (
                            <>
                              <FaExclamationTriangle className="inline-block text-red-500 mr-1 -mt-1" />
                              Expired On
                            </>
                          )}
                        </ProfileFormLabel>

                        <div className="text-sm">
                          {moment(
                            state.account?.subscriptions.find(
                              (x) => x.status === 1
                            ).dateTo
                          ).format("MMM DD YYYY")}
                        </div>
                      </div>
                    )}
                  {!state.account?.subscriptions ||
                    state.account?.subscriptions.length === 0 && (
                      <span className="text-sm">
                        You have not subscribed
                      </span>
                    )
                  }  
                  <div className="w-1/2 inline-block align-top hidden">
                    <ProfileFormLabel>Balance</ProfileFormLabel>
                    <div className="text-lg">
                      <CurrencyFormat
                        value={state.account?.balance}
                        decimalScale={2}
                        fixedDecimalScale
                        displayType={"text"}
                        thousandSeparator={true}
                        suffix={" ETB"}
                      />
                    </div>
                  </div>
                </div>
                {platform !== iOS && 
                <div className="md:w-3/12 text-center md:pl-6">
                  <ProfileFormButton onClick={() =>    setShowModal(true)} id="subscription">
                      Subscribe
                  </ProfileFormButton>
                </div>}
                {platform === iOS2 && 
                <div className="md:w-3/12 text-center md:pl-6">
                  
                    <p className="text-gray-400 text-sm">
                      Please visit your account on mayaflicks.com to purchase subscription packages.
                    </p>
                </div>}
              </ProfileFormSection>
              <Separator />
              <ProfileFormSection>
                <div className="text-xl mt-2 w-48">Username</div>
                <div className="flex-1 ">
                  <div className="w-full inline-flex">
                    <ProfileFormInput
                      type="text"
                      value={account?.username || ''}
                      placeholder="email or phone number"
                      disabled
                    />
                  </div>
                  
                  <div className="w-full">
                    {" "}
                    {errorVerify ? (
                      <SignFormError>{errorVerify}</SignFormError>
                    ) : null}
                  </div>
                  {((account?.username.includes("@") &&
                    account?.isEmailVerified) ||
                    (!account?.username.includes("@") &&
                      account?.isPhoneVerified)) && (
                    <div className="w-full text-center mt-4">
                      <FaCheckCircle className="inline-block text-green-500 mr-1 -mt-1" />
                      Verified
                    </div>
                  )}
                  {((account?.username.includes("@") &&
                    !account?.isEmailVerified) ||
                    (!account?.username.includes("@") &&
                      !account?.isPhoneVerified)) && (
                    <div className="w-full text-center mt-6">
                      <VerifyButton
                        onClick={() => verifyAccount()}
                        disabled={loadingVerify}
                      >
                        {!loadingVerify && (
                          <FaSync className="text-center  text-sm mx-2 inline-block" />
                        )}
                        {loadingVerify && (
                          <span className="inline-block align-middle">
                            {" "}
                            <LoadingIcon />{" "}
                          </span>
                        )}
                        Verify
                      </VerifyButton>
                    </div>
                  )}
                </div>
              </ProfileFormSection>
              <Separator />
             {account?.username.includes("@") &&
             <> 
              <ProfileFormSection>
                <div className="text-xl mt-2 w-48">Phone Number</div>
                <div className="flex-1">
                  <div className="w-full flex flex-col gap-2">
                    <ProfileFormInput
                      type="tel"
                      value={account?.address.telephoneMob || ''}
                      placeholder="Enter Phone number"
                      pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                      onChange={(e) => {
                          account.address.telephoneMob = e.target.value;
                          if(originalPhone !== e.target.value)
                          account.isPhoneVerified = false;
                          else
                          account.isPhoneVerified = isPhoneVerified;

                          setAccount({ ...account });
                        }}
                      
                    />
                    <div className="text-xs text-gray-400">e.g +251911000000</div>
                  </div>
                  <div className="w-full">
                    {" "}
                    {errorVerify ? (
                      <SignFormError>{errorVerify}</SignFormError>
                    ) : null}
                  </div>
                  { account?.isPhoneVerified ? (
                    <div className="w-full text-center mt-4">
                      <FaCheckCircle className="inline-block text-green-500 mr-1 -mt-1" />
                      Verified
                    </div>
                  ): (
                    <div className="w-full text-center mt-6">
                      <VerifyButton
                        onClick={() => verifyPhoneAccount()}
                        disabled={loadingVerify}
                      >
                        {!loadingVerify && (
                          <FaSync className="text-center  text-sm mx-2 inline-block" />
                        )}
                        {loadingVerify && (
                          <span className="inline-block align-middle">
                            {" "}
                            <LoadingIcon />{" "}
                          </span>
                        )}
                        Verify
                      </VerifyButton>
                    </div>
                  )}
                </div>
              </ProfileFormSection> 
               <Separator />
              </>
              }

             
              <ProfileFormSection>
               <div className="text-xl mt-8 w-48">Personal info</div>
                <div className="flex-1 flex flex-col md:flex-row gap-2">
                  <div className="md:w-1/2 px-1 inline-block">
                    <ProfileFormLabel>First name</ProfileFormLabel>
                    <div className="w-full inline-flex">
                      <ProfileFormInput
                        type="text"
                        value={account?.firstName || ''}
                        placeholder="First Name"
                        onChange={(e) => {
                          setIsChanged(true);
                          account.firstName = e.target.value;
                          setAccount({ ...account });
                        }}
                      />
                    </div>
                  </div>
                  <div className="md:w-1/2 px-1 inline-block">
                    <ProfileFormLabel>Last name</ProfileFormLabel>
                    <div className="w-full inline-flex">
                      <ProfileFormInput
                        type="text"
                        value={account?.lastName || ''}
                        placeholder="Last Name"
                        onChange={(e) => {
                          setIsChanged(true);
                          account.lastName = e.target.value;
                          setAccount({ ...account });
                        }}
                      />
                    </div>
                  </div>
                  <div>
                    {isChanged && (
                      <div className="w-full text-center mt-6">
                        <ProfileFormButton
                          onClick={() => updateProfile()}
                          disabled={loadingProfileChange}
                        >
                          {!loadingProfileChange && (
                            <FaSync className="text-center  text-sm mx-2 inline-block" />
                          )}
                          {loadingProfileChange && (
                            <span className="inline-block align-middle">
                              {" "}
                              <LoadingIcon />{" "}
                            </span>
                          )}
                          Update
                        </ProfileFormButton>
                      </div>
                    )}
                  </div>
                </div>
              </ProfileFormSection>

              <Separator />
              <ProfileFormSection>
                <div className="text-xl mt-2 w-48">Change password</div>
                <div className="flex-1">
                  {error ? <SignFormError>{error}</SignFormError> : null}
                  {isChangePasswordEnabled && (
                    <>
                      <div className="md:w-1/2 px-1 inline-block">
                        <ProfileFormLabel>Old Password</ProfileFormLabel>
                        <div className="w-full inline-flex">
                          <ProfileFormInput
                            type="password"
                            placeholder="Old Password"
                            onChange={(e) => setOldPassword(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="md:w-1/2 px-1 inline-block">
                        <ProfileFormLabel>New Password</ProfileFormLabel>
                        <div className="w-full inline-flex">
                          <ProfileFormInput
                            type="password"
                            placeholder="New Password"
                            onChange={(e) => setPassword(e.target.value)}
                          />
                        </div>
                      </div>
                      <div>
                        {" "}
                        <div className="w-full inline-flex mt-6">
                          <ProfileFormButton
                            onClick={() => changePassword()}
                            disabled={loadingChangePasswordChange}
                          >
                            {!loadingChangePasswordChange && (
                              <FaSync className="text-center  text-sm mx-2 inline-block" />
                            )}
                            {loadingChangePasswordChange && (
                              <span className="inline-block align-middle">
                                {" "}
                                <LoadingIcon />{" "}
                              </span>
                            )}
                            Update
                          </ProfileFormButton>
                        </div>
                      </div>
                    </>
                  )}

                  {!isChangePasswordEnabled && (
                    <div>
                      <div className="w-full inline-flex ">
                        <ProfileFormButton
                          onClick={() => setIsChangePasswordEnabled(true)}
                        >
                          Change Password
                        </ProfileFormButton>
                      </div>
                    </div>
                  )}
                </div>
              </ProfileFormSection>
             
              <Separator />
              {platform !== iOS && <ProfileFormSection id="redeem_gift_card">
                <div className="text-xl mt-2 w-48">
                  Redeem Gift Code
                </div>
                <div className="flex-1 flex flex-col md:flex-row gap-6">
                  <div className="w-full">
                    <ProfileFormInput
                      type="text"
                      value={code}
                      placeholder="MAYA-XXXXXXXX"
                      onChange={(e) => setCode(e.target.value)}
                    />
                  </div>
                  <div className="">
                  <ProfileFormButton
                    onClick={() => redeem()}
                    disabled={loadingRedeem}
                  >
                    {loadingRedeem && (
                      <span className="inline-block align-middle">
                        {" "}
                        <LoadingIcon />{" "}
                      </span>
                    )}
                    Redeem
                  </ProfileFormButton>
                </div>
                </div>

               
              </ProfileFormSection>
              }
              <div className="w-full">
                {" "}
                {errorRedeem ? (
                  <SignFormError>{errorRedeem}</SignFormError>
                ) : null}
              </div>
              {platform !== iOS && <Separator />}
              <div className="w-full p-2 ">
                <button
                  onClick={() => history.push("/browse")}
                  className="inline-flex items-center focus:outline-none mr-4 hover:text-gray-400 float-left text-lg"
                >
                  <FaChevronLeft className="text-center   text-sm mx-2" />
                  Back
                </button>
                <button
                  className="inline-flex items-center focus:outline-none mr-4 hover:text-gray-400 float-right text-lg cursor-pointer"
                  onClick={() => onDeleteAccount()}
                >
                  <FaTrashAlt className="text-center text-red-500  text-sm mx-2" />
                  Delete account
                </button>
              </div>
            </div>
          </div>
        )}
        {verifying && (
          <div className="mx-auto container max-w-3xl md:w-3/4 md:px-40">
            <OtpFormCompund
              verify={() => verify(verificationData, previousToken)}
              username={account?.username.includes("@") ? account?.address.telephoneMob
              : account?.username}
            />
            <div className="w-full my-4 text-center flex justify-center">
              <button
                onClick={() => {
                  setVerificationData();
                  setVerifying(false);
                }}
                className="inline-flex items-center focus:outline-none mr-4 hover:text-gray-400 float-left text-lg"
              >
                <FaChevronLeft className="text-center   text-sm mx-2" />
                Back
              </button>
            </div>
          </div>
        )}
      </AllSlidesWrapper>

      <Modal
        isOpen={showModal}
        style={customPaymentStyles}
        contentLabel="Movie Modal"
        className="flex text-center"
        ariaHideApp={false}
        onRequestClose={() => setShowModal(false)}
        shouldCloseOnOverlayClick
      >
       { 
        state.account && <PackageForm
          dispatch={dispatch}
          callBack={setSelectedPackage}
          validDate={
            state.account?.subscriptions &&
            state.account?.subscriptions.length > 0
              ? moment(state.account?.subscriptions[0].dateTo).format(
                  "MMM DD YYYY"
                )
              : null
          }
          close={() => setShowModal(false)}
          lookups={state.lookups}
          profileId={state.selectedProfile.id}
          account={account}
          setAccount={setAccount}
          state={state}
          errorVerify={errorVerify}
          loadingVerify={loadingVerify}     
          verifying={verifying} 
          setVerifying={setVerifying}
          verificationData={verificationData} 
          setVerificationData={setVerificationData}
          originalPhone={originalPhone}
          isPhoneVerified={isPhoneVerified} 
          previousToken={previousToken}   
          verifyAccount={verifyAccount}
          verify={verify}
          verifyPhoneAccount={verifyPhoneAccount}
       
        /> 
        }
     </Modal>

      <FooterCompound />
    </>
  );
}

export default AccountPage;
