import React, { useEffect, useRef, useState } from "react";
import useSound from "use-sound";
import intro from "./intro.wav";
import "./HeaderStyles.css";

function LogoLarge({ history, children, ...restProps }) {
  const sound = useRef();
  const [playOn] = useSound(intro, { volume: 0.1 });
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      if (sound.current) sound.current.click();
    }, 600);
    setTimeout(() => {
      setLoaded(true);
    }, 800);
  }, []);

  return (
    <>
      <div
        className={
          loaded
            ? "p-1 hidden md:block  transform scale-150  duration-1000 ease-out"
            : "p-1 hidden md:block"
        }
      >
        <a onClick={() => {}} {...restProps}>
          {children}
          <span className="logo-main-large text-blue-500 cursor-pointer">
            Maya
          </span>
          <span className="logo-sm-large cursor-pointer text-black cutout-text black__text uppercase">Flicks</span>
        </a>
      </div>
      <div className="p-1 md:hidden">
        <a onClick={() => {}} {...restProps}>
          {children}
          <span className="text-3xl text-blue-500 cursor-pointer">MAYA</span>
          <span className="text-3xl text-black cursor-pointer black__text cutout-text">FLICKS</span>
        </a>
        <a ref={sound} onClick={() => playOn()} />
      </div>
    </>
  );
}

export default LogoLarge;
