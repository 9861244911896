/* eslint-disable react/jsx-key */
/* eslint-disable react/no-array-index-key */
import React from "react";
import CardNoTitle from './CardNoTitle';
import CardNoMovie from './CardNoMovie';

function CardLoading({ hasHeader, ...restProps }) {
  return (
    <>
      {[...Array(4)].map((x, i) => (
        <>
          {hasHeader && (
            <CardNoTitle key={`${i}child`} />
          )}

          <div className="my-2 w-full z-50 grid grid-cols-2 md:grid-cols-5 gap-4 sm:grid-cols-3 justify-items-center sm:justify-items-start">
            {[...Array(5)].map((y, a) => (
              <CardNoMovie width="w-full" key={y} />
            ))}

          </div>
        </>
      ))}

    </>
  );
}

export default CardLoading;
