import React, { useState, useRef, useEffect } from "react";
import "./HeaderStyles.css";

function NavBar({ children, ...restProps }) {
  const [navBackground, setNavBackground] = useState(false);
  const navRef = useRef();
  navRef.current = navBackground;

  useEffect(() => {
    const handleScroll = () => {
      const show = window.scrollY > 50;
      if (navRef.current !== show) {
        setNavBackground(show);
      }
    };
    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return <nav style={{ zIndex: 1000, transition: '1s ease', backgroundColor: navBackground ? 'rgba(17, 24, 39, 1)' : 'transparent' }} {...restProps}>{children}</nav>;
}

export default NavBar;
