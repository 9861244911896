import React from "react";
import "./AccordionStyles.css";

function AccordionWrapper({ children, ...restProps }) {
  return (
    <div
      className="accordion-wrapper  flex-1 px-1 md:px-10 mt-4"
      {...restProps}
    >
      {children}
    </div>
  );
}

export default AccordionWrapper;
