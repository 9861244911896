import React from "react";

function Separator({ children, ...restProps }) {
  return (
    <hr className="border border-gray-700" {...restProps} />

  );
}

export default Separator;
