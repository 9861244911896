import React from "react";
import "./HeaderStyles.css";
import { FiUser, FiChevronDown } from 'react-icons/fi';
import ProfileIconSmall from "../ProfileForm/ProfileIconSmall";

function ProfileLink({ children, profile, ...restProps }) {
  return (
    <div className="header-profile right-0 absolute mr-1 md:mr-2  top-1 md:top-6 text-gray-300 transform hover:text-white  duration-200 ease-out cursor-pointer " {...restProps}>
      {profile && (
      <div className=" border-r border-gray-400 pr-1 md:pr-3 inline-block md:mx-2">
        <ProfileIconSmall letters={profile} />
        {' '}
      </div>
      )}
      <FiUser className="inline-block mr-1 -mt-1 text-lg ml-1 md:ml-0" />
      <span className="hidden lg:inline-block">{children}</span>
      <FiChevronDown className=" ml-1 -mt-1 text-lg cursor-pointer hidden md:inline-block" />

    </div>
  );
}

export default ProfileLink;
