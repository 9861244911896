/* eslint-disable no-mixed-operators */
import React from "react";

function MovieProgress({ total, current, ...restProps }) {

  return (
    <div className="h-1 rounded bg-gray-400 mx-8 my-1">
      <div className="h-1 bg-blue-500 rounded" style={{ width: `${current / total * 100}%` }} />
    </div>
  );
}

export default MovieProgress;
