/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable react/no-children-prop */
import React from "react";
import { Link } from "react-router-dom";
import HeaderWrapper from "../components/Header/HeaderWrapper";
import NavBar from "../components/Header/NavBar";
import Logo from "../components/Header/Logo";
import SigninButton from "../components/Header/SigninButton";
import FeatureWrapper from "../components/Header/FeatureWrapper";
import FeatureTitle from "../components/Header/FeatureTitle";
import OverlayWrapper from "../components/Header/OverlayWrapper";
import LogoLarge from "../components/Header/LogoLarge";
import LogoImageLarge from "../components/Header/LogoImageLarge";
import OptFormCompound from "./OptFormCompound";
import OptFormButton from "../components/OptForm/OptFormButton";

function HeaderCompound({ history, children }) {
  return (
    <HeaderWrapper
      className=""
      children={
        <OverlayWrapper className="overlay-wrapper">
          <NavBar className="navbar-home ">
            <div className="w-full p-4">
              <Link
                className="float-right hover:text-gray-300 font-bold uppercase"
                to="/signin"
              >
                <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-xl shadow-md transition duration-300">
                  Sign In
                </button>
              </Link>
            </div>
          </NavBar>
          <FeatureWrapper className="feature-wrapper-home md:mx-48 md:py-32">
            {/* <div className="w-full md:px-40 p-4">
              <LogoImageLarge history={history} />
            </div> */}
            <div className="w-full md:px-40 p-4">
              <LogoLarge history={history} />
            </div>
            <FeatureTitle className="feature-title-home font-normal text-xl md:px-32 px-4">
              <h1 className="font-xl text-2xl  cutout-text2">
                Taking A Step Ahead towards Ethiopia's Cinematic Brilliance!
              </h1>
              Watch your favorite shows and movies, with promotional plans
              starting at 40 ETB/month or 15 ETB/day.
            </FeatureTitle>
            <OptFormCompound />
          </FeatureWrapper>
          {children}
        </OverlayWrapper>
      }
    />
  );
}

export default HeaderCompound;
