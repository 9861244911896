import React from "react";
import "./SignFormStyles.css";

function SignFormInput({ ...restProps }) {
  return (
    <input
      className="sign-form-input form-input px-4 py-2 border-2 focus:outline-none focus:border-blue-500 transition-colors duration-200 ease-in-out"
      {...restProps}
    />
  );
}

export default SignFormInput;
