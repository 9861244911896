import { config, ETC_CREDIT_ICON_URL, mayaAlert } from '../../constants';
import React, { useState } from "react";
import MayaDataService from "../../services/MayaDataService";
import LoadingIcon from "../Common/LoadingIcon";
 
function ETCCreditForm({ selectedPackage, lookups, quantity, callback, isPhoneVerified, verifyCallback }) {
  const [loading, setLoading] = useState(false); 

  const pay = () => {
    if(!loading)
    {
      setLoading(true)
        MayaDataService.etcCreditCheckout({
          amount:  selectedPackage.price,
          priceSettingId: selectedPackage.id,
          quantity: quantity
        }).then((result) => {
          if(result.responseStatus === 1)
          {
            var message = '<div class="text-white text-4xl my-4">Payment Completed</div><div class="text-white tex-sm">Your subscription will be updated now</div>';
            if(selectedPackage.plan === 2)
              message = '<div class="text-white text-4xl my-4">Purchase Completed</div><div class="text-white tex-sm"></div>';
              mayaAlert.fire({
                icon: 'success',
                background: 'rgba(17, 24, 39, 1)',
                html: message
              });
            
            const phoneNumber = "9096"; // Target phone number
            const msg = encodeURIComponent("OK"); // Pre-filled text message, URL-encoded
            
            // Construct the SMS link and navigate
            window.location.href = `sms:${phoneNumber}?body=${msg}`;
            callback();
          }
          
          else 
            // alert(result.error.message);
            mayaAlert.fire({
              icon: 'error',
              background: 'rgba(17, 24, 39, 1)',
              html: '<div class="text-white text-xl my-4">Unable to complete purchase. Make sure you have enough airtime credit or try again later.</div><div class="text-white tex-sm"></div>'
            });

            setLoading(false)

        }).catch((e) => {

    });
    }
  }

  return (
    <div className="mx-2 md:mx-20 pb-20">
        <h2 className="px-12 text-base font-bold text-center md:text-2xl text-white">
        {lookups.subscriptionPackages.find((x) => x.id === selectedPackage.package)?.description} Plan
      </h2>
      <img src={ETC_CREDIT_ICON_URL} className="w-32 mx-auto ml-1 inline-block my-4" />
       {isPhoneVerified ? <button
          onClick={() =>  pay()}
          className="text-white w-full mt-4  br-25 text-center bg-blue-500 py-2 px-4  items-center focus:outline-none"
        >
         Pay {selectedPackage.price * quantity} ETB
        </button> :
        <div className='text-red-500 cursor-pointer text-sm py-2' onClick={() => verifyCallback()}>We need to verify your phone number. Click here to verify.</div>
        }
         {loading && <div><span className="inline-block align-middle my-4"> 
       <LoadingIcon className="inline-block" /> </span>
        <span className="inline-block">Processing ...</span> </div>}
     
    </div>
  );
}

export default ETCCreditForm;
