import React, { useEffect, useState } from "react";
import Swal from 'sweetalert2';

function DevInfo({ children }) {
  const [counter, setCounter] = useState(0);
  const devInfo = () => {
      Swal.fire({
        showConfirmButton: false,
        showCloseButton: true,
        customClass: 'bg-transparent',
        allowOutsideClick: false,
        width: 0,
        backdrop: `
          rgba(0,0,123,0.4)
          url("https://bit.ly/2LKuyYC")
          center 30% 
          no-repeat
        `
      });
  };
  useEffect(() => {
    if (counter > 6) {
      devInfo();
      setCounter(0);
    }
   }, [counter]);
  return (
    <div onClick={() => setCounter(counter + 1)}>
      {children}
    </div>
  );
}

export default DevInfo;
