import React from "react";

function CardNoMovie({ children, width, ...restProps }) {
  return (
    <div className={`${width} inline-block mx-1 z-50`}>
      <div className="shadow rounded-lg   w-full mx-auto">
        <div className="animate-pulse flex space-x-4">
          <div className="flex-1 space-y-4">
            <div className="space-y-2">
              <div className="bg-gray-300 rounded-lg w-full h-52" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardNoMovie;
