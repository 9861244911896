import React from "react";
import "./MoviesStyles.css";

function CardWrapper({ children, ...restProps }) {
  return (
    <div className="group transform z-0 hover:scale-105 hover:z-50 duration-200 delay-200 ease-in-out w-full inline-block my-4 mx-1 " {...restProps}>
      {children}
    </div>
  );
}

export default CardWrapper;
